import React from 'react';
import { Alert } from '@client/utils/reach-alert';
import { useDispatch, useSelector } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import InnerNotification from '@client/components/generic/InnerNotification';
import theme from '@client/css-modules/NotificationModalWithLink.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { View } from '@client/routes/constants';
import { clearActiveNotification } from '@client/store/actions/modals.actions';
import { getIsNotificationActive } from '@client/store/selectors/modals.selectors';
import GreenCheckMarkIconCobranded from '@client/components/GreenCheckMarkIcon/GreenCheckMarkIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const NOTIFICATION_KEY = 'saved-property-email-setting-notification';

function SavedPropertyEmailSettingNotification() {
  const isModalActive = useSelector(getIsNotificationActive(NOTIFICATION_KEY));
  const dispatch = useDispatch();
  function handleCloseModal() {
    dispatch(clearActiveNotification());
  }
  function handleLinkAction() {
    dispatch(routeChange({ view: View.NOTIFICATIONS }));
  }
  const { linkColor } = useCobrandStyles();

  return (
    <InnerNotification
      isActive={isModalActive}
      theme={theme}
      autoDismissDuration={5000}
      handleClose={handleCloseModal}
    >
      <div
        className={theme.NotificationModalWithLink}
        data-hc-name={NOTIFICATION_KEY}
      >
        <div className={theme.TitleContainer}>
          <div className={theme.IconWrapper}>
            <GreenCheckMarkIconCobranded className={theme.GreenCheckIcon} />
          </div>
          <div className={theme.Title}>Save successful</div>
        </div>
        <div className={theme.Message}>
          <Alert type="assertive">
            You've successfully saved a property, and your notifications have
            been turned on. To update your preferences, go to your&nbsp;
            <button
              className={theme.Link}
              style={{ color: linkColor }}
              onClick={handleLinkAction}
              onKeyDown={onEnterOrSpaceKey(handleLinkAction)}
            >
              settings
            </button>
            .
          </Alert>
        </div>
      </div>
    </InnerNotification>
  );
}

export default SavedPropertyEmailSettingNotification;
