import { TextInputProps } from '@client/components/generic/TextInput';
import TextInputWithFormStyle from '@client/components/generic/TextInputWithFormStyle';
import ValidatorInput, {
  ValidatorProps,
} from '@client/components/generic/ValidatorInput';
import {
  emailValidator,
  emailRequiredValidator,
} from '@client/services/validator';

const EmailInput = (props: TextInputProps) => (
  <TextInputWithFormStyle type="text" {...props} />
);

const ValidatedEmailInput = ValidatorInput<string>(EmailInput);

/* This component differs from <EmailField> in that it renders <TextInputWithFormStyle>, which applies
 * standard styling for inputs inside of forms in our app. <EmailField> uses legacy styling, which
 * shouldn't be used going forward. */
const EmailFieldWithFormStyle = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate' | 'theme'>
) => (
  <ValidatedEmailInput
    dataHcName={'email-control'}
    revalidateTrigger={!!props?.required}
    validate={props?.required ? [emailRequiredValidator] : [emailValidator]}
    {...props}
  />
);

export default EmailFieldWithFormStyle;
