import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@friendsofreactjs/react-css-themr';

import {
  ReferralFormValues,
  BuyingOrSellingOption,
  BUYING_OR_SELLING,
  ReferralPropertyData,
} from '@client/store/types/property-contact-form';
import { valueToNumberFormatter } from '@client/utils/formatter.utils';
import { FIND_AGENT_PROMPT_OPTIONS } from '@client/components/PropertyContactForm';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import PropertyContactFormContainer from '@client/containers/property-contact-form.container';
import defaultTheme from '@client/css-modules/FindAgentForm.css';
import { postFindAgentForm } from '@client/store/slices/your-team.slice';
import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';

interface FindAgentFormProps {
  handleAgentFormSubmitted?: () => void;
  addressSlug?: string | undefined;
  avm?: number | undefined;
  dataEventName: string;
  formContentHeaderText?: string;
  formHeaderText?: string;
  pageHeaderText?: string;
  selectedProperty?: ReferralPropertyData | undefined;
  shouldDisplayBuyingOrSellingSection: boolean;
  submitBtnText?: string;
  theme?: Theme;
}

export const getFormatFormValueKeyForReferBuyerApi = (
  hasAgentSelectedOptionStr: string | undefined,
  hasPreApproveSelectedOptionStr: string | undefined,
  isReferralServicesEnabled,
  values: ReferralFormValues,
  addressSlug?: string,
  selectedProperty?: ReferralPropertyData
): ReferralFormValues => {
  const { firstName, lastName, email, phone, message } = values;

  return {
    firstName,
    lastName,
    email,
    phone,
    message,
    entrySlug: addressSlug,
    propertyDetailSlugs: [addressSlug as string],
    /* If the referral_services flag is disabled, we descope this option on the find agent buyer form. To be able to make a request we need to set it to false.
    It will send "mortgage_status":"none" to Referral Exchange proper */
    preApproved: isReferralServicesEnabled
      ? hasPreApproveSelectedOptionStr === FIND_AGENT_PROMPT_OPTIONS[0]
      : false,
    selectedProperty: {
      address: selectedProperty?.address,
      baths: selectedProperty?.baths,
      beds: selectedProperty?.beds,
      city: selectedProperty?.city,
      state: selectedProperty?.state,
      maxPrice: selectedProperty?.maxPrice,
      unit: selectedProperty?.unit,
      zip: selectedProperty?.zip,
    },
    hasAgent: hasAgentSelectedOptionStr === FIND_AGENT_PROMPT_OPTIONS[0],
    buyingOrSelling: BUYING_OR_SELLING.BUYING,
  };
};

export const getFormatFormValueKeyForReferSellerApi = (
  avm: number | undefined,
  hasAgentSelectedOptionStr: string | undefined,
  preferredSellingPrice: string | number | null | undefined,
  values: ReferralFormValues,
  addressSlug?: string,
  selectedProperty?: ReferralPropertyData
): ReferralFormValues => {
  const { firstName, lastName, email, phone, message } = values;

  const preferredSellingPriceInNumberFormat =
    preferredSellingPrice && valueToNumberFormatter(preferredSellingPrice);

  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    message: message,
    entrySlug: addressSlug,
    selectedProperty: {
      address: selectedProperty?.address,
      unit: selectedProperty?.unit,
      city: selectedProperty?.city,
      homeType: selectedProperty?.homeType,
      minPrice: preferredSellingPriceInNumberFormat || 0,
      avm: avm,
      state: selectedProperty?.state,
      zip: selectedProperty?.zip,
    },
    hasAgent: hasAgentSelectedOptionStr === FIND_AGENT_PROMPT_OPTIONS[0],
    buyingOrSelling: BUYING_OR_SELLING.SELLING,
  };
};

const FindAgentForm: React.FC<FindAgentFormProps> = ({
  addressSlug,
  avm,
  dataEventName,
  pageHeaderText,
  formContentHeaderText,
  formHeaderText,
  selectedProperty,
  shouldDisplayBuyingOrSellingSection,
  submitBtnText,
  theme,
  handleAgentFormSubmitted,
}) => {
  const dispatch = useDispatch();
  const reportYourTeamEvent = useReportYourTeamEvent();
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const handleOnSubmitFindAgentBuyer = (
    values: ReferralFormValues,
    hasAgentSelectedOptionStr: string | undefined,
    hasPreApproveSelectedOptionStr: string | undefined
  ) => {
    const formattedFormValues = getFormatFormValueKeyForReferBuyerApi(
      hasAgentSelectedOptionStr,
      hasPreApproveSelectedOptionStr,
      isReferralServicesEnabled,
      values,
      addressSlug,
      selectedProperty
    );
    dispatch(postFindAgentForm({ formValues: formattedFormValues }));
  };

  const handleOnSubmitFindAgentSeller = (
    values: ReferralFormValues,
    hasAgentSelectedOptionStr: string | undefined,
    preferredSellingPrice: string | number | null | undefined
  ) => {
    const formattedFormValues = getFormatFormValueKeyForReferSellerApi(
      avm,
      hasAgentSelectedOptionStr,
      preferredSellingPrice,
      values,
      addressSlug,
      selectedProperty
    );
    dispatch(postFindAgentForm({ formValues: formattedFormValues }));
  };

  const handleOnSubmit = (
    values: ReferralFormValues,
    isBuyingOrSellingSelectedOptionStr: BuyingOrSellingOption | undefined,
    hasAgentSelectedOptionStr: string | undefined,
    hasPreApproveSelectedOptionStr: string | undefined,
    preferredSellingPrice: string | number | null | undefined
  ) => {
    if (handleAgentFormSubmitted) {
      handleAgentFormSubmitted();
    }
    reportYourTeamEvent('click_your_team_find_an_agent_form_submit', {
      buttonCopy: submitBtnText,
      formValues: values,
      hasAgentSelectedOptionStr,
      hasPreApproveSelectedOptionStr,
    });
    if (isBuyingOrSellingSelectedOptionStr === BUYING_OR_SELLING.BUYING) {
      handleOnSubmitFindAgentBuyer(
        values,
        hasAgentSelectedOptionStr,
        hasPreApproveSelectedOptionStr
      );
    } else if (
      isBuyingOrSellingSelectedOptionStr === BUYING_OR_SELLING.SELLING
    ) {
      handleOnSubmitFindAgentSeller(
        values,
        hasAgentSelectedOptionStr,
        preferredSellingPrice
      );
    }
  };

  const activeTheme = theme ? theme : defaultTheme;

  return (
    <div className={activeTheme.FormWrapper}>
      <div role="heading" aria-level={1} className={activeTheme.HeroText}>
        {pageHeaderText}
      </div>

      <PropertyContactFormContainer
        avm={avm}
        dataEventName={dataEventName}
        dataParentEventName={PARENT_EVENTS.CLICK_FIND_AGENT}
        formContentHeaderText={formContentHeaderText}
        formHeaderText={formHeaderText}
        horizontal
        selectedProperty={selectedProperty}
        shouldDisplayBuyingOrSellingSection={
          shouldDisplayBuyingOrSellingSection
        }
        submitBtnText={submitBtnText}
        onSubmit={handleOnSubmit}
        theme={activeTheme}
        isShowingMobileCloseButton={false}
      />
    </div>
  );
};

export default FindAgentForm;
