import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserTermsAcceptedDate,
  getCurrentUser,
} from '@client/store/selectors/auth.selectors';
import { getCobrandDisplayName } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { acceptTermsAndConditions } from '@client/store/actions/auth.actions';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import RouterLink from '@client/components/RouterLink';
import SmallModal from '@client/components/generic/SmallModal';
import ComeHomeLogoPoweredByImg from '@client/components/ComeHomeLogoPoweredByImg';
import PillButton from '@client/components/generic/PillButton';

import { View } from '@client/routes/constants';
import theme from '@client/css-modules/TermsAndConditionsModal.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

/* Primarily for SAML auth clients, where users can have an account but have not accepted our Terms and Conditions.
 * This modal checks terms status, and forces users to accept our terms and conditions prior to using the rest of the site. */
const TermsAndConditionsModal: React.FC = () => {
  /* User info */
  const user = useSelector(getCurrentUser);
  const terms = useSelector(getUserTermsAcceptedDate);

  const currentView = useSelector(getCurrentView);
  const isHiddenOnCurrentView =
    currentView === View.TERMS_OF_USE || currentView === View.PRIVACY_POLICY;
  const isTermsFeatureEnabled = useSelector(
    getIsFeatureEnabled('force_terms_acceptance')
  );
  const shouldDisplayModal =
    isTermsFeatureEnabled && !isHiddenOnCurrentView && !!user && !terms;

  const [isActive, setIsActive] = useState(shouldDisplayModal);

  const dispatch = useDispatch();
  const acceptTerms = () => dispatch(acceptTermsAndConditions());
  const reportModalDisplayed = () =>
    dispatch(reportEvent('generic_terms_modal_confirmed'));
  useEffect(() => {
    isActive && reportModalDisplayed();
  }, [isActive]);

  /* Lender info */
  const lenderName = useSelector(getCobrandDisplayName);

  useEffect(() => {
    setIsActive(shouldDisplayModal);
  }, [shouldDisplayModal]);

  const { linkColor } = useCobrandStyles();

  return (
    <SmallModal
      dataHcName="terms-modal"
      isActive={isActive}
      /* We do not want users to be able to exit the modal unless they've clicked "Continue": */
      handleClose={() => null}
      className={theme.TermsAndConditionsModal}
      isHidingCloseIcon
      theme={theme}
    >
      <>
        <div className={theme.LogoWrapper}>
          <ComeHomeLogoPoweredByImg className={theme.CHLogo} shouldUseAltText />
        </div>
        <div className={theme.TextContent}>
          {`HouseCanary, Inc. is a nationwide real estate brokerage partnering with ${lenderName} to provide a more seamless home buying experience.`}
        </div>
        <div className={theme.TextContent}>
          <span>By clicking "Continue" you agree to ComeHome's </span>
          <RouterLink
            className={theme.Link}
            style={{ color: linkColor }}
            data-ignore-intercept
            view={View.TERMS_OF_USE}
            target="_blank"
            ariaLabel="terms of use link"
          >
            Terms of Use
          </RouterLink>
          <span> and </span>
          <RouterLink
            className={theme.Link}
            style={{ color: linkColor }}
            data-ignore-intercept
            view={View.PRIVACY_POLICY}
            target="_blank"
            ariaLabel="privacy policy link"
          >
            Privacy Policy
          </RouterLink>
        </div>
        <div className={theme.ButtonRow}>
          <PillButton
            theme={theme}
            onClick={acceptTerms}
            ariaLabel="Click continue to agree to Privacy Policy and Terms and Conditions"
          >
            Continue
          </PillButton>
        </div>
      </>
    </SmallModal>
  );
};

export default TermsAndConditionsModal;
