import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InnerNotification from '@client/components/generic/InnerNotification';
import ErrorXIcon from '@client/inline-svgs/error-x-icon';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';
import {
  clearLoDirectNotification,
  selectLoDirectNotification,
} from '@client/store/slices/lo-direct.slice';
import { Alert } from '@client/utils/reach-alert';

const StyledNotificationGlobal = styled.div<{ $customMargin?: string }>`
  & div > div {
    margin: ${(props) => props.$customMargin || 'auto auto 40px auto'};
    background: #ffd7d7;
    min-height: 40px;
    min-width: 300px;
    width: auto;
    padding: 10px 20px;
  }
`;
const StyledNotificationInline = styled.div`
  margin: auto auto 20px auto;
  background: #ffd7d7;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledMessage = styled.span`
  color: #b10234;
  font-size: 12px;
  font-weight: 700;
`;
const StyledErrorIcon = styled(ErrorXIcon)`
  margin-right: 10px;
  display: flex;
  height: 100%;
  & svg {
    width: 15px !important;
    height: auto !important;
  }
`;

function LODirectNotificationErrorContent({ text }: { text: string }) {
  return (
    <>
      <StyledErrorIcon />
      <StyledMessage>
        <Alert type="assertive">{text}</Alert>
      </StyledMessage>
    </>
  );
}

export function LODirectNotificationErrorInline({ text }: { text: string }) {
  return (
    <StyledNotificationInline data-hc-name={'lo-direct-notification-error'}>
      <LODirectNotificationErrorContent text={text} />
    </StyledNotificationInline>
  );
}

export default function LODirectNotificationError() {
  const dispatch = useDispatch();
  const notification = useSelector(selectLoDirectNotification);
  const isTabletOrSmallerSize = useSelector(getIsTabletOrSmallerSize);

  return (
    <StyledNotificationGlobal
      $customMargin={isTabletOrSmallerSize ? '40px auto auto auto' : undefined}
      data-hc-name={'lo-direct-notification-error'}
    >
      <InnerNotification
        isActive={notification.type === 'error'}
        autoDismissDuration={5000}
        dataHcName="toast-message"
        handleClose={function () {
          dispatch(clearLoDirectNotification());
        }}
      >
        <LODirectNotificationErrorContent
          text={notification.message ?? 'Oops! Something went wrong.'}
        />
      </InnerNotification>
    </StyledNotificationGlobal>
  );
}
