import { motion } from 'framer-motion';

import CHONearbySalesPropertyCard from '@client/components/CHONearbySalesPropertyCard';
import { useHomeSalesPropertyData } from '@client/hooks/home-sales-data.hooks';
import theme from '@client/css-modules/HOHomeSalesPropertyCard.css';
import { parseCSSAmount } from '@client/utils/string.utils';

type Props = { slug: string | null; isActiveListing: boolean };

export default function HOHomeSalesPropertyCard({
  slug,
  isActiveListing,
}: Props) {
  const { currentPropertyLocation, soldHomesMap, activeListingHomesMap } =
    useHomeSalesPropertyData();
  const homesMap = isActiveListing ? activeListingHomesMap : soldHomesMap;
  const propertyDetails = slug && homesMap ? homesMap[slug] : null;

  return slug && propertyDetails ? (
    <motion.div
      initial={{
        y: parseCSSAmount(theme['sold-property-card-height']),
      }}
      animate={{
        y: 0,
        transition: {
          duration: 0.15,
          easing: 'easeIn',
          damping: 0,
        },
      }}
      exit={{
        y: parseCSSAmount(theme['sold-property-card-height']),
        transition: {
          damping: 0,
          duration: 0.1,
        },
      }}
    >
      <CHONearbySalesPropertyCard
        isActiveListing={isActiveListing}
        key="sold-property-card"
        slug={slug}
        propertyDetails={propertyDetails}
        activePropertyLocation={currentPropertyLocation}
        rel="nofollow"
        theme={theme}
      />
    </motion.div>
  ) : null;
}
