import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import {
  goBackOrDispatch,
  hideCantFindAddressModal,
} from '@client/store/actions/modals.actions';
import { getIsShowingCantFindAddressModal } from '@client/store/selectors/modals.selectors';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import ClickableLogo from '@client/components/ClickableLogo';
import CantFindAddressText from '@client/components/CantFindAddressText';
import theme from '@client/css-modules/CantFindAddressModal.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import ComeHomeHeaderLogoCobranded from '@client/components/ComeHomeHeaderLogo/ComeHomeHeaderLogoCobranded';

export const CantFindAddressModal: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const isActive = useSelector(getIsShowingCantFindAddressModal);
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const handleClose = () => {
    dispatch(goBackOrDispatch(hideCantFindAddressModal()));
  };
  const { cantFindAddressModalClickableLogoStyle } = useCobrandStyles();

  return (
    <SlideInModalContainer
      isFullScreen
      className={theme.CantFindAddressModal}
      dataHcName={'cant-find-address-modal'}
      theme={{
        ...theme,
        Modal: classNames(theme.Modal),
      }}
      modalAriaLabel={'Manual address entry'}
      isActive={isActive}
      handleClose={handleClose}
    >
      <div className={theme.CantFindAddressModalClickableLogo}>
        <ClickableLogo
          dataHcName={'comehome-logo'}
          theme={theme}
          Logo={ComeHomeHeaderLogoCobranded}
          alwaysUseRouteChange={isInsideNativeApp}
          style={cantFindAddressModalClickableLogoStyle}
        />
      </div>
      <div className={theme.ModalBody}>
        <CantFindAddressText />
      </div>
    </SlideInModalContainer>
  );
};

export default CantFindAddressModal;
