import InnerNotification from '@client/components/generic/InnerNotification';
import CloseIcon from '@client/inline-svgs/close';
import GreenCheckMarkHollow from '@client/inline-svgs/green-check-mark-hollow';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';
import {
  clearLoDirectNotification,
  selectLoDirectNotification,
} from '@client/store/slices/lo-direct.slice';
import { Alert } from '@client/utils/reach-alert';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledNotificationGlobal = styled.div<{
  $customHeight?: number;
  $customWidth?: number;
  $customMargin?: string;
}>`
  & div > div {
    background: #bcfae0;
    height: 40px;
    min-width: 300px;
    width: auto;
    padding: 0 20px;
    margin: ${(props) => props.$customMargin || 'auto auto 40px auto'};
    ${(props) => props.$customHeight && `height: ${props.$customHeight}px;`}
    ${(props) => props.$customWidth && `width: ${props.$customWidth}px;`}
  }
`;
const StyledNotificationInline = styled.div`
  margin: auto auto 20px auto;
  background: #bcfae0;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledMessage = styled.span`
  color: #17935e;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  width: 100%;
`;
const StyledSuccessIcon = styled(GreenCheckMarkHollow)`
  margin-right: 10px;
  width: 12px;
  height: 100%;
  display: flex;
`;
const StyledCloseIconButton = styled.button`
  border: none;
  background: none;
  padding: 0 0 0 10px;
  margin-left: auto;
  cursor: pointer;
`;
const StyledCloseIcon = styled(CloseIcon)`
  display: flex;
  & svg {
    width: 15px !important;
    height: auto !important;
  }
`;

function LODirectNotificationSuccessContent({
  text,
  hideDefaultIcon,
}: {
  text: string;
  hideDefaultIcon?: boolean;
}) {
  return (
    <>
      {!hideDefaultIcon && <StyledSuccessIcon />}
      <StyledMessage>
        <Alert type="assertive">{text}</Alert>
      </StyledMessage>
    </>
  );
}

export function LODirectNotificationSuccessInline({
  text,
  style,
}: {
  text: string;
  style?: React.CSSProperties;
}) {
  return (
    <StyledNotificationInline
      data-hc-name={'lo-direct-notification-success'}
      style={style}
    >
      <LODirectNotificationSuccessContent text={text} />
    </StyledNotificationInline>
  );
}

export default function LODirectNotificationSuccess() {
  const dispatch = useDispatch();
  const notification = useSelector(selectLoDirectNotification);
  const isTabletOrSmallerSize = useSelector(getIsTabletOrSmallerSize);

  function handleClose() {
    dispatch(clearLoDirectNotification());
  }

  return (
    <StyledNotificationGlobal
      data-hc-name={'lo-direct-notification-success'}
      $customHeight={notification.customHeight}
      $customWidth={notification.customWidth}
      $customMargin={
        /* Put the notification at the top of the page on mobile to avoid virtual keyboard */
        isTabletOrSmallerSize || notification.isTopPosition
          ? '40px auto auto auto'
          : undefined
      }
    >
      <InnerNotification
        isActive={notification.type === 'success'}
        autoDismissDuration={3000}
        dataHcName="toast-message"
        handleClose={handleClose}
      >
        <LODirectNotificationSuccessContent
          hideDefaultIcon={notification.hideDefaultIcon}
          text={notification.message ?? 'Success!'}
        />
        {notification.showCloseIcon && (
          <StyledCloseIconButton
            type="button"
            aria-label="Notification close"
            onClick={handleClose}
          >
            <StyledCloseIcon
    
            />
          </StyledCloseIconButton>
        )}
      </InnerNotification>
    </StyledNotificationGlobal>
  );
}
