import React from 'react';
import theme from '@client/css-modules/HoverTooltip.css';

const HomeownerTooltipComehome = () => {
  const elementToFocus = document.querySelector(
    '[data-tooltip-anchor-id="my-home"]'
  );
  const rectangle =
    elementToFocus !== null ? elementToFocus.getBoundingClientRect() : null;
  let style = rectangle
    ? {
        top: rectangle.top + rectangle.top * 1.3,
        left: rectangle.left,
      }
    : {};
  return (
    rectangle && (
      <>
        <div className={theme.TooltipPortalContentWrapper} style={style}>
          <div
            className={theme.TooltipArrow}
            style={{
              left: 'calc(50% - 10px)',
            }}
          >
            {'▲'}
          </div>
          <div className={theme.TooltipContent}>Dashboard</div>
        </div>
      </>
    )
  );
};

export default HomeownerTooltipComehome;
