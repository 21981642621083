import { AnimatePresence, motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';
import { useSelector } from 'react-redux';

import {
  useIsLOTabDisabled,
  useYourTeamAgentData,
  useYourTeamAgentDataFetchEffect,
  useYourTeamModalState,
} from '@client/hooks/your-team.hooks';
import CloseIcon from '@client/inline-svgs/close';
import CloseIconWhite from '@client/inline-svgs/close-white';

import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { selectYourTeamModalType } from '@client/store/slices/your-team.slice';

import PillButton from '@client/components/generic/PillButton';
import AgentCard from '@client/components/YourTeam/AgentCard';
import LOCard from '@client/components/YourTeam/LOCard';
import YourTeamTabs from '@client/components/YourTeam/YourTeamTabs';

import theme from '@client/css-modules/YourTeam/YourTeamModal.css';
import iOSModalScrollingFix from '@client/hocs/ios-modal-scrolling-fix';
import { selectConstrainedToPlaceHasNoMLSCoverage } from '@client/store/selectors/search.selectors';
import { reportToSentry } from '@client/utils/error.utils';
import classNames from 'classnames';

const ScrollableDialogBody = iOSModalScrollingFix('div');

function useAnimationConfig() {
  const isSmallScreen = useSelector(getIsSmallSize);
  const desktopDistanceFromBottomPage = isSmallScreen ? '12px' : '105px';
  const transition = { duration: 0.1 };

  return {
    ICON_ANIMATION: {
      initial: {
        x: -100,
      },
      animate: { x: 0 },
      transition,
    },
    MODAL_ANIMATION: isSmallScreen
      ? // animate the modal from bottom on mobile
        {
          initial: {
            bottom: 'initial',
            /* Account for the part of the modal extending slightly above the top of the modal body */
            top: 'calc(100% + 30px)',
          },
          animate: {
            bottom: 0,
            top: 'initial',
          },
          transition,
        }
      : // animate the modal from the right on desktop
        {
          initial: {
            x: 82,
            bottom: desktopDistanceFromBottomPage,
          },
          animate: { x: 0 },
          transition,
        },
  };
}

export default function YourTeamModal() {
  const isLOTabDisabled = useIsLOTabDisabled();
  const isLOTabEnabled = !isLOTabDisabled;
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const yourTeamModalType = useSelector(selectYourTeamModalType);
  const { isAgentTabEnabled, isYourTeamModalOpen, closeYourTeamModal } =
    useYourTeamModalState();
  const {
    introducedAgentData,
    pendingAgentData,
    lenderAgentData,
    previousAgentData,
  } = useYourTeamAgentData();
  const { MODAL_ANIMATION, ICON_ANIMATION } = useAnimationConfig();
  const isShowingAgentCard = isAgentTabEnabled && yourTeamModalType === 'agent';
  const isShowingLOCard = isLOTabEnabled && !isShowingAgentCard;
  const shouldAdjustElementPlacement = useSelector(
    selectConstrainedToPlaceHasNoMLSCoverage
  );

  /* Report to Sentry, but still (hopefully) render something in the modal */
  if (
    isYourTeamModalOpen &&
    !isAgentTabEnabled &&
    yourTeamModalType === 'agent'
  ) {
    reportToSentry(
      'Attempting to show Your Team agent card when it is disallowed, falling back to LO card if allowed',
      {
        introducedAgentData,
        pendingAgentData,
        lenderAgentData,
        previousAgentData,
        isReferralServicesEnabled,
      }
    );
  }
  /* Report to Sentry, but still (hopefully) render something in the modal */
  if (isYourTeamModalOpen && !isLOTabEnabled && yourTeamModalType === 'lo') {
    reportToSentry(
      'Attempting to show Your Team LO card when it is disallowed, falling back to agent card if allowed'
    );
  }

  /* Ensure that one and only one of the 2 booleans are true, since otherwise the modal UX will
   * be corrupted. Check needed since it's possible to configure the feature flags in an incorrect way. */
  if (
    isYourTeamModalOpen &&
    ((isShowingAgentCard && isShowingLOCard) ||
      (!isShowingAgentCard && !isShowingLOCard))
  ) {
    reportToSentry(
      'Attempting to render Your Team modal with an invalid card type - broken UX',
      {
        isShowingAgentCard,
        isShowingLOCard,
        isAgentTabEnabled,
        yourTeamModalType,
        isLOTabEnabled,
      }
    );
  }

  useYourTeamAgentDataFetchEffect();

  return (
    <>
      {isYourTeamModalOpen && (
        <div className={theme.YourTeamModalContainer}>
          <FocusOn scrollLock={false}>
            <div className={theme.BgScreen} onClick={closeYourTeamModal} />
            <button
              aria-label="Close modal"
              data-hc-name="your-team-x-button"
              className={theme.MobileCloseButton}
              onClick={closeYourTeamModal}
            >
              <CloseIconWhite role="img" />
            </button>
            <motion.div
              className={theme.CloseButtonDesktopWrapper}
              initial={ICON_ANIMATION.initial}
              animate={ICON_ANIMATION.animate}
              transition={ICON_ANIMATION.transition}
            >
              <PillButton
                className={classNames(theme.CloseButtonDesktop, {
                  [theme.CloseButtonDesktopAdjusted]:
                    shouldAdjustElementPlacement,
                })}
                customBackgroundColor="#ffffff"
                customBorderRadius="50%"
                ariaLabel={
                  isYourTeamModalOpen ? 'Close modal' : 'Trigger modal'
                }
                onClick={closeYourTeamModal}
              >
                <CloseIcon
                  aria-label="Close Icon Desktop"
                  role="img"
                  className={theme.CloseIconDesktop}
                  data-hc-name="your-team-x-button"
                />
              </PillButton>
            </motion.div>

            <AnimatePresence>
              <motion.div
                className={classNames(theme.YourTeamModal, {
                  [theme.YourTeamModalAdjusted]: shouldAdjustElementPlacement,
                })}
                data-hc-name="your-team-modal"
                initial={MODAL_ANIMATION.initial}
                animate={MODAL_ANIMATION.animate}
                transition={MODAL_ANIMATION.transition}
              >
                <div className={theme.Body}>
                  <YourTeamTabs />
                  <ScrollableDialogBody
                    shouldAlwaysPreventBodyScrolling
                    className={theme.LOAndAgentCardContainer}
                    style={{
                      maxHeight: `calc(${window.innerHeight}px - 260px)`,
                    }}
                  >
                    {/* Logic exists above to ensure that one and only one of these booleans are true */}
                    {isShowingAgentCard && <AgentCard />}
                    {isShowingLOCard && <LOCard />}
                  </ScrollableDialogBody>
                </div>
              </motion.div>
            </AnimatePresence>
          </FocusOn>
        </div>
      )}
    </>
  );
}
