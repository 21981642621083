import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chaseCTAClick } from '@client/store/actions/cobranding.actions';
import ConditionalFeature from '@client/components/ConditionalFeature';
import { searchDismissMlsCoverageModal } from '@client/store/actions/search.actions';
import {
  getSearchConstrainedPlace,
  getIsShowingNoMLSModal,
} from '@client/store/selectors/search.selectors';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/NoMLSModalChase.css';
import modalGraphic from '@client/assets/images/cobrand/chase/incomplete-mls-coverage-chase.png';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import PillButton from '@client/components/generic/PillButton';

/**
 * A static modal displaying a message to the user when we have no MLS coverage in an area
 */
const NoMLSModalChase: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(getIsShowingNoMLSModal);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const searchConstrainedToPlace = useSelector(getSearchConstrainedPlace);
  const handleSetModalAsDismissed = () => {
    dispatch(searchDismissMlsCoverageModal());
  };
  const handleCTAAction = () => {
    const { city, state, zipcode } = searchConstrainedToPlace || {
      city: null,
      state: null,
      zipcode: null,
    };
    if (!city || !state) {
      throw new Error(
        `City or state not defined for CTA action.  City: ${city}, State: ${state}`
      );
    }
    /* Dismiss MLS coverage modal when
     * 1. CTA is clicked
     * 2. user logged out
     * since auth modal will open
     */
    if (!isLoggedIn) {
      handleSetModalAsDismissed();
    }
    dispatch(
      chaseCTAClick({
        event_type: 'area',
        destination: 'LOAN_OPTIONS',
        city,
        state,
        ...(zipcode ? { zipcode } : {}),
      })
    );
  };

  return (
    <SmallModal
      className={theme.NoMLSModalChase}
      isActive={isActive}
      handleClose={handleSetModalAsDismissed}
      showHideDuration={0.5}
      theme={theme}
    >
      <>
        <ConditionalFeature renderIfFeaturesEnabled={['jsbridge_event_type']}>
          <img
            src={modalGraphic}
            className={theme.ModalGraphic}
            alt="Unsupported map area"
          />
          <div>
            <p>ComeHome doesn't support active listings here yet.</p>
            <p>
              You can still explore <strong>loan options</strong> in this area.
            </p>
          </div>
          <PillButton onClick={handleCTAAction} ariaLabel={'See loan options'}>
            See loan options
          </PillButton>
        </ConditionalFeature>
        <ConditionalFeature
          renderIfFeaturesNotEnabled={['jsbridge_event_type']}
        >
          <img
            src={modalGraphic}
            className={theme.ModalGraphic}
            alt="Unsupported map area"
          />
          <div>
            <p>ComeHome doesn't support active listings here yet.</p>
            <p>
              You can still explore loan options in this area by going back to
              the <strong>Dashboard</strong> and choosing{' '}
              <strong>Loan Options</strong>.
            </p>
          </div>
          <PillButton
            onClick={handleSetModalAsDismissed}
            ariaLabel={'Close'}
            theme={theme}
          >
            Close
          </PillButton>
        </ConditionalFeature>
      </>
    </SmallModal>
  );
};

export default NoMLSModalChase;
