import { connect } from 'react-redux';
import BrokerageModal from '@client/components/BrokerageModal';
import { getIsShowingBrokerageModal } from '@client/store/selectors/modals.selectors';
import { closeBrokeragerAttributionModal } from '@client/store/actions/modals.actions';
import { getCobrandDisplayName } from '@client/store/selectors/cobranding.selectors';

const mapStateToProps = (state) => ({
  isActive: getIsShowingBrokerageModal(state),
  cobrandName: getCobrandDisplayName(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleClose: () => dispatch(closeBrokeragerAttributionModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokerageModal);
