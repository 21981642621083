import theme from '@client/css-modules/TextInputWithFormStyle.css';
import TextInput from '@client/components/generic/TextInput';

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : any;
type Props = Omit<PropsOf<typeof TextInput>, 'theme'>;

/**
 * Wrapper for TextInput that provides standard styling for text inputs inside of forms in our app
 *
 * This component is not themeable since it's intended to provide the exact appearance of a text
 * input and label used in forms throughout the app. If you need different styling, consult with
 * design or use <TextInput> by itself.
 */
const TextInputWithFormStyle: React.FC<Props> = (props) => (
  <TextInput {...props} theme={theme} />
);

export default TextInputWithFormStyle;
