import React, { Component } from 'react';

import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import EmailField from '@client/components/generic/EmailField';
import { STATUSES } from '@client/store/constants';
import theme from '@client/css-modules/UpdateEmailModal.css';
import { onEnterKey } from '@client/utils/accessibility.utils';
import PillButton from '@client/components/generic/PillButton';
import EmailIconCobranded from '@client/components/EmailIcon/EmailIconCobranded';
import EmailUpdateSuccessCheckMarkIconCobranded from '@client/components/EmailUpdateSuccessCheckMarkIcon/EmailUpdateSuccessCheckMarkIconCobranded';
import CobrandedStyles from '@client/components/CobrandedStyles';

type UpdateEmailModalProps = {
  isUpdateEmailModalOpen: boolean;
  handleUpdateEmailAddress: (userInfo) => void;
  handleUpdateEmailModalClose: () => void;
  emailUpdateStatus: string | null;
  userEmailAddress?: string | null;
};

type State = {
  newUserEmail: string;
};

class UpdateEmailModal extends Component<UpdateEmailModalProps, State> {
  state: State = {
    newUserEmail: '',
  };

  handleModalClose = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    this.props.handleUpdateEmailModalClose();
  };

  handleUpdateEmail = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    const { newUserEmail } = this.state;
    if (newUserEmail) {
      this.props.handleUpdateEmailAddress({ email: newUserEmail });
      this.setState({ newUserEmail: '' });
    }
  };

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      newUserEmail: e.target.value,
    });
  };

  render() {
    const { isUpdateEmailModalOpen, emailUpdateStatus, userEmailAddress } =
      this.props;

    return (
      <SlideInModalContainer
        className={theme.UpdateEmailModal}
        dataHcName={'update-email-modal'}
        theme={theme}
        modalAriaLabel={'Update email address'}
        isActive={isUpdateEmailModalOpen}
        handleClose={this.handleModalClose}
      >
        <CobrandedStyles>
          {({ dismissableBannerBackground, dismissableBannerTextColor }) => (
            <>
              <div
                className={theme.Header}
                style={{
                  background: dismissableBannerBackground,
                  color: dismissableBannerTextColor,
                }}
              >
                <div className={theme.Title}>
                  <EmailIconCobranded className={theme.EmailIcon} />
                  {emailUpdateStatus === STATUSES.SUCCESS ? (
                    <div className={theme.TitleText}>Thank You!</div>
                  ) : (
                    <>
                      <div className={theme.TitleText}>
                        Update Email Address
                      </div>
                      <div className={theme.SubTitle}>
                        Your email address on file is{' '}
                        <span className={theme.UserEmail}>
                          {userEmailAddress}
                        </span>
                        . Please enter a new email address below.
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={theme.Body}>
                {emailUpdateStatus === STATUSES.SUCCESS ? (
                  <>
                    <div className={theme.UpdateEmailSuccessText}>
                      Your new email address on file is:
                    </div>
                    <div className={theme.NewEmailAddress}>
                      {userEmailAddress}
                    </div>
                    <EmailUpdateSuccessCheckMarkIconCobranded
                      className={theme.EmailUpdateSuccessCheckMarkIcon}
                    />
                  </>
                ) : (
                  <>
                    <EmailField
                      theme={theme}
                      label="New Email"
                      required
                      onChange={(e) => this.handleEmailChange(e)}
                      onKeyDown={onEnterKey(this.handleUpdateEmail)}
                      value={this.state.newUserEmail}
                      name={'new email'}
                      debounce={750}
                    />
                    <PillButton
                      ariaLabel={'Confirm Email'}
                      className={theme.ConfirmEmailButton}
                      onClick={this.handleUpdateEmail}
                    >
                      Confirm Email
                    </PillButton>
                  </>
                )}
              </div>
            </>
          )}
        </CobrandedStyles>
      </SlideInModalContainer>
    );
  }
}

export default UpdateEmailModal;
