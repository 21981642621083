import CHONearbySalesPropertyCard from '@client/components/CHONearbySalesPropertyCard';
import { useHomeSalesPropertyData } from '@client/hooks/home-sales-data.hooks';
import theme from '@client/css-modules/HOHomeSalesMarkerPopUp.css';

type Props = { slug: string | null; isActiveListing: boolean };

export default function HOHomeSalesMarkerPopUp({
  slug,
  isActiveListing,
}: Props) {
  const { currentPropertyLocation, soldHomesMap, activeListingHomesMap } =
    useHomeSalesPropertyData();
  const homesMap = isActiveListing ? activeListingHomesMap : soldHomesMap;
  const propertyDetails = slug && homesMap ? homesMap[slug] : null;

  return slug && propertyDetails ? (
    <CHONearbySalesPropertyCard
      isActiveListing={isActiveListing}
      key="sold-property-card"
      slug={slug}
      propertyDetails={propertyDetails}
      activePropertyLocation={currentPropertyLocation}
      rel="nofollow"
      theme={theme}
    />
  ) : null;
}
