import LearnMoreAside from '@client/components/LearnMoreAside';
import Tooltip from '@client/components/generic/Tooltip';
import defaultTheme from '@client/css-modules/GenericLearnMoreTooltip.css';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

type GenericLearnMoreTooltipProps = {
  theme: Theme;
  initialShiftAmount?: number;
  maxWidth?: number;
  toolTipLabelStyle?: React.CSSProperties;
  tooltipPosition?: 'top' | 'bottom';
  title: string;
  content: string;
  dataHcName: string;
};

const GenericLearnMoreTooltip: React.FC<GenericLearnMoreTooltipProps> = ({
  theme,
  toolTipLabelStyle,
  initialShiftAmount,
  maxWidth,
  tooltipPosition,
  title,
  content,
  dataHcName,
}) => {
  return (
    <LearnMoreAside theme={theme} style={toolTipLabelStyle}>
      {() => (
        <Tooltip
          dataHcName={dataHcName}
          theme={theme}
          trigger={title}
          content={content}
          position={tooltipPosition}
          initialShiftAmount={initialShiftAmount}
          maxWidth={maxWidth}
        />
      )}
    </LearnMoreAside>
  );
};
const ThemedGenericLearnMoreTooltip = themr(
  'GenericLearnMoreTooltip',
  defaultTheme
)(GenericLearnMoreTooltip);
export default ThemedGenericLearnMoreTooltip;
