import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Theme } from '@friendsofreactjs/react-css-themr';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/EmailOptInModal.css';
import RouterLink from '@client/components/RouterLink';
import { View, Query } from '@client/routes/constants';
import {
  getCobrandId,
  getCustomizationData,
} from '@client/store/selectors/cobranding.selectors';
import {
  submitUserEmailUpdateFromOptIn,
  openEmailOptInModal,
  closeEmailOptInModal,
  clearUserEmailUpdateFromOptInError,
} from '@client/store/actions/email-opt-in.actions';
import {
  getIsEmailOptInModalAllowedToOpenAndNoOtherModalOpen,
  getIsDisplayingEmailOptInModal,
  getEmailOptInSubmitErrorMsg,
  getIsForceTermsAcceptanceEnabled,
} from '@client/store/selectors/email-opt-in.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import EmailField from '@client/components/generic/EmailField';
import FormSubmit from '@client/components/generic/FormSubmit';
import HeartIconInactive from '@client/inline-svgs/heart-inactive';
import HomeSearchIcon from '@client/inline-svgs/home-search-icon';
import LendcoIconEquity from '@client/inline-svgs/cobrand/lendco/lendco-icon-equity';
import LendcoIconSalesTrends from '@client/inline-svgs/cobrand/lendco/lendco-icon-sales-trends';
import {
  getIsLoggedIn,
  getUserTermsAcceptedDate,
} from '@client/store/selectors/auth.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { ACTIVE_MODAL_URL_KEY } from '@client/store/constants';
import {
  getCurrentQuery,
  getCurrentView,
} from '@src/redux-saga-router-plus/selectors';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { AuthStatusScreenReader } from './AuthStatusScreenReader';

interface BodyContentProps {
  theme: Theme;
  icon: JSX.Element;
  children: JSX.Element;
}

const BodyContent = ({ children, icon, theme }: BodyContentProps) => (
  <li className={theme.ElementWrapper}>
    <div className={theme.ElementIcon}>{icon}</div>
    <div className={theme.ElementText}>{children}</div>
  </li>
);

const EmailOptInModal = () => {
  const dispatch = useDispatch();
  const cobrandId = useSelector(getCobrandId);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const currentView = useSelector(getCurrentView);
  const isHiddenOnCurrentView =
    currentView === View.TERMS_OF_USE || currentView === View.PRIVACY_POLICY;
  // Use this to open and close the email opt-in modal
  const isDisplayingEmailOptInModalEnabled = useSelector(
    getIsDisplayingEmailOptInModal
  );
  const isDisplayingEmailOptInModal =
    isDisplayingEmailOptInModalEnabled && !isHiddenOnCurrentView;
  const submitErrorMsg = useSelector(getEmailOptInSubmitErrorMsg);
  const isForceTermsAcceptanceEnabled = useSelector(
    getIsForceTermsAcceptanceEnabled
  );
  const areTermsAccepted = useSelector(getUserTermsAcceptedDate);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { linkColor } = useCobrandStyles();
  const queryStr: Query = useSelector(getCurrentQuery);
  let isNeedingToShowAfterModalCloses = useRef(
    !!queryStr[ACTIVE_MODAL_URL_KEY]
  );

  /**
   * If a user logged in, 'should_display_email_opt_in_modal' flag is enabled,
   * and there is no user email address,
   * this will return true, otherwise it will return false
   */
  const modalIsAllowedToOpen = useSelector(
    getIsEmailOptInModalAllowedToOpenAndNoOtherModalOpen
  );

  const onSubmit = (e): void => {
    e.preventDefault();
    dispatch(submitUserEmailUpdateFromOptIn({ email: email }));
    dispatch(
      reportEvent(
        'click_email_modal_submission',
        PARENT_EVENTS.CLICK_EMAIL_MODAL
      )
    );
    /* Auto-closing of modal is handled in the auth saga after a non-error response is received */
  };

  const handleOnFocus = () => {
    dispatch(
      reportEvent('click_email_modal_intent', PARENT_EVENTS.CLICK_EMAIL_MODAL)
    );
  };

  const handleChange = (e) => {
    // When a user starts typing, clear the error message from the previous submission (if any)
    if (submitErrorMsg) {
      dispatch(clearUserEmailUpdateFromOptInError());
    }
    setEmail(e.target.value);
  };

  const handleValidEmail = () => {
    setIsValidEmail(true);
  };

  const handleInvalidEmail = () => {
    setIsValidEmail(false);
  };

  const handleDismiss = () => {
    dispatch(closeEmailOptInModal());
    dispatch(
      reportEvent('click_email_modal_close', PARENT_EVENTS.CLICK_EMAIL_MODAL)
    );
  };

  const { email_opt_in_modal_copy, should_show_privacy_policy_links } =
    useSelector(getCustomizationData);

  /* With SSO login, you can be redirected back to a modal with an action to be performed.
   * We want the action to display, then to show the email opt-in modal after it has been closed. */
  useEffect(() => {
    if (
      isNeedingToShowAfterModalCloses.current === true &&
      modalIsAllowedToOpen &&
      !queryStr[ACTIVE_MODAL_URL_KEY]
    ) {
      dispatch(openEmailOptInModal());
      isNeedingToShowAfterModalCloses.current = false;
    }
  }, [queryStr[ACTIVE_MODAL_URL_KEY]]);

  useEffect(() => {
    /**
     * If a user logged in, 'should_display_email_opt_in_modal' flag is enabled,
     * and there is no user email address,
     * modalIsAllowedToOpen will return true, otherwise it will return false.
     * Need to update the dom after user logged in so the email modal is displayed.
     */
    if (modalIsAllowedToOpen) {
      dispatch(openEmailOptInModal());
    }
  }, [isLoggedIn, dispatch]);

  return (
    <SmallModal
      className={theme.EmailOptInModal}
      dataHcName={`email-opt-in-modal-${cobrandId}`}
      isActive={isDisplayingEmailOptInModal}
      handleClose={handleDismiss}
      theme={theme}
    >
      <AuthStatusScreenReader />
      <div className={theme.EmailOptInModalContent}>
        <div
          className={theme.EmailOptInModalTitle}
          role="heading"
          aria-level={2}
        >
          {email_opt_in_modal_copy.title}
        </div>
        <div>
          <form>
            <div className={theme.FormInnerWrapper}>
              <EmailField
                theme={theme}
                label="Email"
                maxLength={50}
                dataHcName={'email-field'}
                name={'email'}
                value={email}
                onChange={handleChange}
                onFocus={handleOnFocus}
                onValid={handleValidEmail}
                onInvalid={handleInvalidEmail}
              />
              <FormSubmit
                theme={theme}
                disabled={!isValidEmail}
                label="Continue"
                type="submit"
                onSubmit={onSubmit}
              />
            </div>
            {typeof submitErrorMsg === 'string' && (
              <div className={theme.Error}>{submitErrorMsg}</div>
            )}
          </form>
        </div>
        <div className={theme.EmailOptInModalBodyWrapper}>
          <div className={theme.EmailOptInModalBodyHeader}>
            {email_opt_in_modal_copy.body}
          </div>
          <ul className={theme.EmailOptInModalBodyContentWrapper}>
            <BodyContent icon={<HeartIconInactive ariaHidden />} theme={theme}>
              <div>Saved properties &amp; searches </div>
            </BodyContent>

            <BodyContent icon={<HomeSearchIcon ariaHidden />} theme={theme}>
              <div>Updates on property listings</div>
            </BodyContent>

            <BodyContent icon={<LendcoIconEquity ariaHidden className={theme.LeverageIcon} />} theme={theme}>
              <div>Leverage &amp; improve your home value</div>
            </BodyContent>

            <BodyContent icon={<LendcoIconSalesTrends ariaHidden />} theme={theme}>
              <div>Get neighborhood &amp; market trends</div>
            </BodyContent>
          </ul>

          {!(isForceTermsAcceptanceEnabled && areTermsAccepted) && (
            <div className={theme.Footer}>
              <span>By clicking “Continue” you agree to ComeHome’s </span>
              <RouterLink
                target="_blank"
                style={{ color: linkColor }}
                data-ignore-intercept
                view={View.TERMS_OF_USE}
                ariaLabel="terms of use"
                className={theme.Link}
              >
                Terms of Use
              </RouterLink>
              {should_show_privacy_policy_links && (
                <>
                  <span>&nbsp;and&nbsp;</span>
                  <RouterLink
                    target="_blank"
                    style={{ color: linkColor }}
                    data-ignore-intercept
                    view={View.PRIVACY_POLICY}
                    ariaLabel="Privacy Policy"
                    className={theme.Link}
                  >
                    Privacy Policy
                  </RouterLink>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </SmallModal>
  );
};

export default EmailOptInModal;
