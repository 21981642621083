import React from 'react';

import theme from '@client/css-modules/YourTeam/MessageConfirmation.css';
import FailureIcon from '@client/assets/images/failure-icon.svg';
import SuccessIcon from '@client/assets/images/success-icon.svg';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type MessageProps = {
  dataHcName: string;
  onLinkClick?: () => void;
};

export const MessageFailure: React.FC<MessageProps> = ({
  dataHcName,
  onLinkClick,
}): JSX.Element => {
  const { linkColor } = useCobrandStyles();
  return (
    <div className={theme.Container} data-hc-name={dataHcName}>
      <img alt="Failure Icon" src={FailureIcon} />
      <p className={theme.Message}>Oops, something went wrong</p>
      {onLinkClick && (
        <button
          style={{ color: linkColor }}
          onClick={onLinkClick}
          className={theme.ActionLink}
          data-hc-name="your-team-email-try-again"
        >
          Please try again
        </button>
      )}
    </div>
  );
};

export const MessageSuccess: React.FC<MessageProps> = ({
  dataHcName,
  onLinkClick,
}): JSX.Element => {
  const { linkColor } = useCobrandStyles();
  return (
    <div className={theme.Container} data-hc-name={dataHcName}>
      <img alt="Success Icon" src={SuccessIcon} />
      <p className={theme.Message}>Thank you! I'll be in touch</p>
      {onLinkClick && (
        <button
          style={{ color: linkColor }}
          onClick={onLinkClick}
          className={theme.ActionLink}
          data-hc-name="your-team-email-back"
        >
          Back
        </button>
      )}
    </div>
  );
};
