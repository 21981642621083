import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import NoMLSModalHomeownerTooltip from './NoMLSModalHomeownerTooltip';
import NoMLSModalHomeownerTooltipComehome from './NoMLSModalHomeownerTooltipComehome';
import { COBRAND_IDS } from '@client/store/types/cobranding';
const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return null;
    case COBRAND_IDS.WWW:
      return NoMLSModalHomeownerTooltipComehome;
    default:
      return NoMLSModalHomeownerTooltip;
  }
};

const NoMLSModalHomeownerTooltipCobranded: React.FC<{}> = () => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component /> : null;
};

export default NoMLSModalHomeownerTooltipCobranded;
