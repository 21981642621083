import SmallModal from '@client/components/generic/SmallModal';
import GrantProgramFullContent from '@client/components/GrantProgram/GrantProgramFullContent';
import modalThemeOverride from '@client/css-modules/GrantProgramModal.css';
import { getGrantProgramConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  clearSelectedPropertyDetails,
  selectGrantAmountForTractId,
  selectTractIdForModalDisplay,
} from '@client/store/slices/grant-program.slice';
import { reportToSentry } from '@client/utils/error.utils';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Grant program learn more content triggered by grant program label cta in SRP
 */
export default function GrantProgramModal() {
  const dispatch = useDispatch();
  const selectedTractId = useSelector(selectTractIdForModalDisplay);
  const grantAmount = useSelector(selectGrantAmountForTractId(selectedTractId));
  const grantProgramConfig = useSelector(getGrantProgramConfig);
  const isSmallScreen = useSelector(getIsSmallSize);

  if (selectedTractId && !grantAmount) {
    reportToSentry(
      'GrantProgramModal: no grantAmount found for selectedTractId',
      { selectedTractId }
    );
  }
  return (
    <SmallModal
      theme={modalThemeOverride}
      isActive={!!selectedTractId && !!grantAmount}
      anchorToBottom={isSmallScreen}
      displayNormalCloseIcon={isSmallScreen}
      handleClose={() => dispatch(clearSelectedPropertyDetails())}
    >
      {grantAmount && (
        <GrantProgramFullContent
          dataEventName="click_grant_modal_cta"
          dataEventParentName="lender_cta"
          isPdpPage={false}
          isNarrowLayout
          isSmallScreen={isSmallScreen}
          useFullWidthCTA
          grantAmount={grantAmount}
          grantProgramConfigForView={grantProgramConfig.gp_search_results_modal}
        />
      )}
    </SmallModal>
  );
}
