import React, { Component } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/InnerNotification.css';
import CloseIcon from '@client/inline-svgs/close';

/**
 * A notification modal that will be centered, with no screen, within its parent container
 * Note: parent container must be positioned relatively
 */

type Props = {
  isActive: boolean;
  handleClose: () => void;
  autoDismissed?: boolean;
  autoDismissDuration?: number;
  showClose?: boolean;
  dataHcName?: string;
  theme: Theme;
  className?: string;
  children?: React.ReactNode;
};

class InnerNotification extends Component<Props> {
  autoDismissTimeout: number | null = null;

  componentDidUpdate(prevProps) {
    const { autoDismissed, isActive, autoDismissDuration, handleClose } =
      this.props;
    const isAutoDismissed =
      typeof autoDismissed === 'undefined' ? true : autoDismissed;

    if (isAutoDismissed && !prevProps.isActive && isActive) {
      this.autoDismissTimeout = window.setTimeout(() => {
        handleClose();
      }, autoDismissDuration || 3500);
    }
  }

  componentWillUnmount() {
    this.handleDismiss();
  }

  handleDismiss = () => {
    if (this.autoDismissTimeout) {
      window.clearTimeout(this.autoDismissTimeout);
    }
    this.props.handleClose();
  };

  render() {
    const { isActive, showClose, theme, children, className, dataHcName } =
      this.props;
    return (
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            exit={{ y: 20, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { duration: 0.2, delay: 0.3 },
            }}
            key="modal"
            className={theme.Wrapper}
            onClick={this.handleDismiss}
          >
            <div
              data-hc-name={dataHcName}
              className={classNames(theme.Dialog, {
                [className || '']: !!className,
              })}
            >
              {showClose && (
                <CloseIcon
                  className={theme.CloseIcon}
                  onClick={this.handleDismiss}
                />
              )}
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

export default themr('InnerNotification', defaultTheme)(InnerNotification);
