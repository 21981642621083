import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsNotificationActive } from '@client/store/selectors/modals.selectors';
import { clearActiveNotification } from '@client/store/actions/modals.actions';
import InnerNotification from '@client/components/generic/InnerNotification';
import GreenCheckIcon from '@client/inline-svgs/green-check';
import theme from '@client/css-modules/RequestATourSuccessNotification.css';
import RouterLink from '@client/components/RouterLink';
import { View } from '@client/routes/constants';
import { useAriaAnnouncer } from '@client/context/aria-announcer';

export default function RequestATourSuccessNotification() {
  const dispatch = useDispatch();
  const isActive = useSelector(
    getIsNotificationActive('request-a-tour-success')
  );

  const sendAriaMessage = useAriaAnnouncer();

  useEffect(() => {
      sendAriaMessage?.(isActive? "Your tour has been requested.": "")
  }, [isActive])

  return (
    <InnerNotification
      className={theme.NotificationDialog}
      theme={theme}
      autoDismissed={true}
      autoDismissDuration={5000}
      handleClose={() => dispatch(clearActiveNotification())}
      isActive={isActive}
    >
      <section className={theme.RequestATourSuccessNotification}>
        <h2 className={theme.SuccessHeading}>
          <GreenCheckIcon className={theme.GreenCheckIcon} /> What's next?
        </h2>
        <h3 className={theme.SuccessSubHeading}>
          An agent will contact you shortly. You can view this home in your{' '}
          <RouterLink
            view={View.WATCHLIST}
            ariaLabel="Navigate to your watchlist"
            className={theme.WatchListLink}
          >
            saved homes.
          </RouterLink>
        </h3>
      </section>
    </InnerNotification>
  );
}
