import React from 'react';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/MLSNotifyConfirmModal.css';
import RouterLink from '@client/components/RouterLink';
import { View } from '@client/routes/constants';
import {
  getShouldShowMLSRegistration,
  getSearchPlaceGeoJSONDescription,
} from '@client/store/selectors/search.selectors';
import {
  reportNavBuyClick,
  reportNavHomeOwnerClick,
  reportNavSignupClick,
} from '@client/store/actions/analytics.actions';
import { SearchDismissMLSRegistrationConfirm } from '@client/store/actions/search.actions';
import { useSelector, useDispatch } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { get } from 'lodash';
import PillButton from '@client/components/generic/PillButton';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

function MLSNotifyConfirmModal() {
  const dispatch = useDispatch();
  const city = useSelector(getSearchPlaceGeoJSONDescription);
  const shouldShowMLSRegistration = useSelector(getShouldShowMLSRegistration);
  const { linkColor } = useCobrandStyles();
  const handleDismiss = () => {
    dispatch(SearchDismissMLSRegistrationConfirm());
  };
  const handleRouteChange = (toView, params) => {
    switch (toView) {
      case View.SIGN_UP: {
        dispatch(reportNavSignupClick());
        break;
      }
      case View.HOMEOWNER_PROPERTY_DETAILS: {
        dispatch(reportNavHomeOwnerClick());
        break;
      }
      case View.SEARCH: {
        dispatch(reportNavBuyClick());
        break;
      }
    }

    const slug = params && get(params, 'slug');

    if (slug) {
      /* if there is only 1 claimed home, change the route to the Homeowner PDP */
      dispatch(routeChange({ view: toView, params: { slug: slug } }));
      dispatch(SearchDismissMLSRegistrationConfirm());
    } else {
      /* if there is no claimed home or there are more than 1 claimed home, change the route to the homeowner manage home page */
      dispatch(routeChange({ view: toView }));
      dispatch(SearchDismissMLSRegistrationConfirm());
    }
  };

  return (
    <SmallModal
      isActive={shouldShowMLSRegistration}
      handleClose={handleDismiss}
      className={theme.Modal}
      theme={theme}
    >
      <div className={theme.Header}>Thank You!</div>
      <div className={theme.Content}>
        <div className={theme.MessageBody}>
          We will let you know when listings are available
          {city !== '' ? ` in ${city}.` : '.'}
        </div>
        <div className={theme.ButtonWrapper}>
          <PillButton
            ariaLabel="return-to-search"
            className={theme.Button}
            theme={theme}
            onClick={handleDismiss}
          >
            Return to search
          </PillButton>{' '}
          <br />
        </div>
        <RouterLink
          className={theme.Homeowner}
          style={{ color: linkColor }}
          onClick={handleRouteChange}
          view={View.HOMEOWNER}
          data-ignore-intercept
          ariaLabel="terms of use"
        >
          Access homeowner dashboard
        </RouterLink>
        <br />
      </div>
    </SmallModal>
  );
}

export default MLSNotifyConfirmModal;
