import React, { Component } from 'react';
import classNames from 'classnames';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { get } from 'lodash';

import EmailFieldWithFormStyle from '@client/components/generic/EmailFieldWithFormStyle';
import TextInputWithFormStyle from '@client/components/generic/TextInputWithFormStyle';
import TextAreaWithFormStyle from '@client/components/generic/TextAreaWithFormStyle';
import AvmDefinition from '@client/components/AvmDefinition';
import FormError from '@client/components/generic/FormError';
import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/PropertyContactForm.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  formatPhoneInputForDisplay,
  dollarsWithPlaceholder,
  numbersWithPlaceholder,
  placeholderFormatter,
  pluralize,
} from '@client/utils/string.utils';
import {
  isValidPhoneNumber,
  isValidEmailAddress,
} from '@client/utils/validations.forms';
import CloseIcon from '@client/inline-svgs/close';
import InfoIcon from '@client/inline-svgs/info-icon';
import ImageNullState from '@client/inline-svgs/image-null-state';

import { User, UserContactInfo } from '@client/store/types/auth';
import {
  ReferralFormValues,
  ReferralPropertyData,
  BUYING_OR_SELLING,
  BuyingOrSellingOption,
} from '@client/store/types/property-contact-form';
import GenericLearnMoreTooltip from '@client/components/GenericLearnMoreTooltip';
import Checkbox from '@client/components/generic/Checkbox';
import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import { valueToNumberFormatter } from '@client/utils/formatter.utils';
import PillButton from '@client/components/generic/PillButton';
import RouterLink from '@client/components/RouterLink';
import { View } from '@client/routes/constants';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';

type Props = {
  dataEventName?: string;
  dataParentEventName?: string;
  dataEventDataJson?: Object;
  isShowingMobileCloseButton: boolean;
  lenderSpecificPageTitle: string | null;
  handleClose?: () => void;
  horizontal?: boolean;
  onSubmit: (
    values: ReferralFormValues,
    isBuyingOrSellingSelectedOptionStr: BuyingOrSellingOption | undefined,
    hasAgentSelectedOptionStr: string | undefined,
    hasPreApproveSelectedOptionStr: string | undefined,
    preferredSellingPrice?: string | number | null
  ) => void;
  updateUserProfile: (userInfo: Partial<User>) => void;
  currentUserData: UserContactInfo;
  avm?: number;
  isLoggedIn: boolean;
  isSmallScreen: boolean;
  shouldAdjustTopSpacing?: boolean;
  theme: Theme;
  selectedProperty?: ReferralPropertyData;
  formHeaderText?: string;
  formContentHeaderText?: string;
  shouldDisplayBuyingOrSellingSection?: boolean;
  shouldDisplayNextStepSection?: boolean;
  shouldDisplayWorkingWithAgentQuestion?: boolean;
  shouldDisplayPreApproveSection?: boolean;
  shouldDisplayPropertyDetailSection?: boolean;
  shouldDisplayTermOfUseAndPrivacySection?: boolean;
  submitBtnText?: string;
  shouldHideCommentsSection?: boolean;
};

type State = {
  values: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
    preferredSellingPrice?: number | string | null;
  };
  areAllFieldsValid: boolean;
  originalTitle: string;
  isBuyingOrSellingSelectedOptionStr: BuyingOrSellingOption | undefined;
  hasAgentSelectedOptionStr: string | undefined;
  hasPreApproveSelectedOptionStr: string | undefined;
  hasSubmitted: boolean;
};

export const BUYING_OR_SELLING_OPTIONS: BuyingOrSellingOption[] = [
  BUYING_OR_SELLING.BUYING,
  BUYING_OR_SELLING.SELLING,
];
export const FIND_AGENT_PROMPT_OPTIONS = ['Yes', 'No'];
const isValidMessageLength = (message) => message.length <= 255;
const PROPERTY_DETAIL_UNIT = {
  BED: 'Bed',
  BATH: 'Bath',
  SQUARE_FOOTAGE: 'sq. ft.',
};

export class PropertyContactForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      values: {
        firstName: props.currentUserData?.firstName ?? '',
        lastName: props.currentUserData?.lastName ?? '',
        email: props.currentUserData?.email ?? '',
        phone: props.currentUserData?.phone ?? '',
        message: '',
        preferredSellingPrice: props.avm || 0,
      },
      areAllFieldsValid: false,
      originalTitle: document.title,
      isBuyingOrSellingSelectedOptionStr: undefined,
      hasAgentSelectedOptionStr: undefined,
      hasPreApproveSelectedOptionStr: undefined,
      hasSubmitted: false,
    };
  }

  componentDidMount() {
    this.setState({
      areAllFieldsValid: this.getAreAllFieldsAreValid(),
    });
    document.title =
      this.props.lenderSpecificPageTitle || 'Find an Agent | ComeHome';
  }

  componentWillUnmount() {
    document.title = this.state.originalTitle;
  }

  getIsPreferredSellingPriceValid = () => {
    const { values } = this.state;
    const { preferredSellingPrice } = values;
    if (!preferredSellingPrice) {
      return false;
    }

    const preferredSellingPriceInNumberFormat = valueToNumberFormatter(
      preferredSellingPrice
    );
    return (
      !!preferredSellingPriceInNumberFormat &&
      preferredSellingPriceInNumberFormat !== 0
    );
  };

  getAreAllFieldsAreValid = () => {
    const {
      shouldDisplayBuyingOrSellingSection,
      shouldDisplayWorkingWithAgentQuestion,
      shouldDisplayPreApproveSection,
    } = this.props;
    const {
      values,
      isBuyingOrSellingSelectedOptionStr,
      hasAgentSelectedOptionStr,
      hasPreApproveSelectedOptionStr,
    } = this.state;
    const { firstName, lastName, email, phone, message } = values;
    const hasValues = !!firstName && !!lastName && !!email && !!phone;
    const isEmailValid = isValidEmailAddress(email);
    const isPhoneValid = isValidPhoneNumber(phone);
    const isMessageFieldValid = isValidMessageLength(message);
    const isPreferredSellingPriceValid = this.getIsPreferredSellingPriceValid();

    let validators = [
      hasValues,
      isEmailValid,
      isPhoneValid,
      isMessageFieldValid,
    ];

    if (shouldDisplayBuyingOrSellingSection) {
      validators = [...validators, !!isBuyingOrSellingSelectedOptionStr];
    }

    if (
      shouldDisplayPreApproveSection ||
      (shouldDisplayBuyingOrSellingSection &&
        isBuyingOrSellingSelectedOptionStr === BUYING_OR_SELLING.BUYING)
    ) {
      validators = [...validators, !!hasPreApproveSelectedOptionStr];
    }

    if (shouldDisplayWorkingWithAgentQuestion) {
      validators = [...validators, !!hasAgentSelectedOptionStr];
    }

    if (
      shouldDisplayBuyingOrSellingSection &&
      isBuyingOrSellingSelectedOptionStr === BUYING_OR_SELLING.SELLING
    ) {
      validators = [...validators, isPreferredSellingPriceValid];
    }
    return validators.every((validator) => !!validator);
  };

  updateFormField = (formField, value) => {
    this.setState(
      {
        values: {
          ...this.state.values,
          [formField]: value,
        },
      },
      () =>
        this.setState({
          areAllFieldsValid: this.getAreAllFieldsAreValid(),
        })
    );
  };

  formatUserKeyToUpdateUserProfile = (): Partial<User> => {
    const { currentUserData } = this.props;
    const { firstName, lastName, email, phone } = currentUserData;
    /* Only update user data if we do not have the information */
    return {
      first_name: firstName ? firstName : this.state.values.firstName,
      last_name: lastName ? lastName : this.state.values.lastName,
      email: email ? email : this.state.values.email,
      phone: phone ? phone : this.state.values.phone,
    };
  };

  formatPriceToNumber = () => {
    const { values } = this.state;
    const { preferredSellingPrice } = values;
    let preferredSellingPriceInNumberFormat;
    /* initial preferredSellingPrice is avm which is number
    then it will change to be a string once users enter the preferredSellingPrice input data*/
    if (typeof preferredSellingPrice === 'string') {
      preferredSellingPriceInNumberFormat = parseInt(
        preferredSellingPrice.replace(/\D/g, ''),
        10
      );
    } else if (typeof preferredSellingPrice === 'number') {
      preferredSellingPriceInNumberFormat = preferredSellingPrice;
    }

    return preferredSellingPriceInNumberFormat;
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    const { currentUserData, updateUserProfile, isLoggedIn, onSubmit } =
      this.props;
    const { firstName, lastName, email, phone } = currentUserData;
    const {
      values,
      isBuyingOrSellingSelectedOptionStr,
      hasAgentSelectedOptionStr,
      hasPreApproveSelectedOptionStr,
    } = this.state;
    const { preferredSellingPrice } = values;

    onSubmit(
      values,
      isBuyingOrSellingSelectedOptionStr,
      hasAgentSelectedOptionStr,
      hasPreApproveSelectedOptionStr,
      preferredSellingPrice
    );

    /* Only update user data if we do not have the information */
    if (isLoggedIn && (!firstName || !lastName || !email || !phone)) {
      updateUserProfile(this.formatUserKeyToUpdateUserProfile());
    }
    this.setState({
      hasSubmitted: true,
    });
  };

  handleSelectBuyingOrSelling = (option: BuyingOrSellingOption): void => {
    this.setState({ isBuyingOrSellingSelectedOptionStr: option }, () =>
      this.setState({
        areAllFieldsValid: this.getAreAllFieldsAreValid(),
      })
    );
  };

  handleSelectHasAgent = (option: string): void => {
    this.setState({ hasAgentSelectedOptionStr: option }, () =>
      this.setState({
        areAllFieldsValid: this.getAreAllFieldsAreValid(),
      })
    );
  };

  handleSelectHasPreApprove = (option: string): void => {
    this.setState({ hasPreApproveSelectedOptionStr: option }, () =>
      this.setState({
        areAllFieldsValid: this.getAreAllFieldsAreValid(),
      })
    );
  };

  getPreApproveSection = () => {
    const { theme, dataEventName, dataParentEventName, dataEventDataJson } =
      this.props;
    const { hasPreApproveSelectedOptionStr } = this.state;
    return (
      <div
        className={classNames(theme.FindAgentPromptContainer, theme.FullWidth)}
      >
        <div className={classNames(theme.FormElementWrapper, theme.FullWidth)}>
          <div className={theme.FindAgentPromptHeaderWithTooltip}>
            <div
              role="heading"
              aria-level={2}
              className={classNames(
                theme.FindAgentPromptHeader,
                theme.NormalHeaderText
              )}
            >
              Are you pre-approved for a loan?
            </div>
          </div>
          <div className={theme.FindAgentPrompt}>
            {FIND_AGENT_PROMPT_OPTIONS.map((option: string) => (
              <Checkbox
                key={option}
                theme={{
                  ...theme,
                  CheckboxInput: classNames(theme.CheckboxInput, {
                    /* overriding checkbox manual background color to match invision */
                    [theme.CheckBoxInputSelected]:
                      hasPreApproveSelectedOptionStr === option,
                  }),
                }}
                data-event-name={
                  dataEventName
                    ? option === 'Yes'
                      ? `${dataEventName}_pre_approved_yes`
                      : `${dataEventName}_pre_approved_no`
                    : undefined
                }
                data-parent-event-name={dataParentEventName}
                data-event-data-json={JSON.stringify({ dataEventDataJson })}
                onClick={() => this.handleSelectHasPreApprove(option)}
                onKeyDown={onEnterOrSpaceKey(() =>
                  this.handleSelectHasPreApprove(option)
                )}
                isChecked={hasPreApproveSelectedOptionStr === option}
                label={option}
                name={option}
              />
            ))}
          </div>
          {
            /* form required message will be refined in sprint of 4.3. putting this one in to explicitly state this is required */
            !hasPreApproveSelectedOptionStr && (
              <div className={theme.AnswerIsRequiredText}>Required</div>
            )
          }
        </div>
      </div>
    );
  };

  getBuyingOrSellingSection = () => {
    const { theme, dataEventName, dataParentEventName, dataEventDataJson } =
      this.props;
    const { isBuyingOrSellingSelectedOptionStr } = this.state;
    return (
      <div className={theme.FindAgentPromptContainer}>
        <div className={theme.FindAgentPromptHeaderWithTooltip}>
          <div
            role="heading"
            aria-level={2}
            className={theme.FindAgentPromptHeader}
          >
            Are you buying or selling?*
          </div>
        </div>
        <div className={theme.FindAgentPrompt}>
          {BUYING_OR_SELLING_OPTIONS.map((option: string) => (
            <Checkbox
              key={option}
              theme={{
                ...theme,
                CheckboxInput: classNames(theme.CheckboxInput, {
                  /* overriding checkbox manual background color to match invision */
                  [theme.CheckBoxInputSelected]:
                    isBuyingOrSellingSelectedOptionStr === option,
                }),
              }}
              data-event-name={
                dataEventName
                  ? option === BUYING_OR_SELLING.BUYING
                    ? `${dataEventName}_buying_button`
                    : `${dataEventName}_selling_button`
                  : undefined
              }
              data-parent-event-name={dataParentEventName}
              data-event-data-json={JSON.stringify({ dataEventDataJson })}
              onClick={() =>
                this.handleSelectBuyingOrSelling(
                  option as BuyingOrSellingOption
                )
              }
              onKeyDown={onEnterOrSpaceKey(() =>
                this.handleSelectBuyingOrSelling(
                  option as BuyingOrSellingOption
                )
              )}
              isChecked={isBuyingOrSellingSelectedOptionStr === option}
              label={option}
              name={option}
            />
          ))}
        </div>
        {
          /* form required message will be refined in sprint of 4.3. putting this one in to explicitly state this is required */
          !isBuyingOrSellingSelectedOptionStr && (
            <div className={theme.AnswerIsRequiredText}>Required</div>
          )
        }
      </div>
    );
  };

  getTermOfUseAndPrivacySection = (
    linkColor: string,
    shouldShowPrivacyPolicyLinks
  ) => {
    const { theme } = this.props;
    return (
      <div className={theme.TextAreaWrapper}>
        <div className={theme.TermsLink}>
          <span>By submitting this request, I agree to ComeHome&nbsp;</span>

          <RouterLink
            target="_blank"
            style={{ color: linkColor }}
            data-ignore-intercept
            view={View.TERMS_OF_USE}
            ariaLabel="terms of use"
            className={theme.Link}
          >
            Terms of Use
          </RouterLink>
          {shouldShowPrivacyPolicyLinks && (
            <>
              <span>&nbsp;and&nbsp;</span>
              <RouterLink
                target="_blank"
                style={{ color: linkColor }}
                data-ignore-intercept
                view={View.PRIVACY_POLICY}
                ariaLabel="Privacy Policy"
                className={theme.Link}
              >
                Privacy Policy
              </RouterLink>
            </>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      values,
      areAllFieldsValid,
      isBuyingOrSellingSelectedOptionStr,
      hasAgentSelectedOptionStr,
      hasSubmitted,
    } = this.state;

    const { firstName, lastName, email, phone } = values;

    const {
      dataEventName,
      dataParentEventName,
      dataEventDataJson,
      isShowingMobileCloseButton,
      isSmallScreen,
      handleClose,
      horizontal,
      shouldAdjustTopSpacing,
      theme,
      avm,
      selectedProperty,
      formHeaderText,
      formContentHeaderText,
      shouldDisplayBuyingOrSellingSection,
      shouldDisplayNextStepSection,
      shouldDisplayWorkingWithAgentQuestion,
      shouldDisplayPreApproveSection,
      shouldDisplayPropertyDetailSection,
      shouldDisplayTermOfUseAndPrivacySection,
      submitBtnText,
      shouldHideCommentsSection,
    } = this.props;

    const beds = selectedProperty?.beds;
    const baths = selectedProperty?.baths;
    const sqFt = selectedProperty?.sqFt;
    const address = selectedProperty?.address;
    const unit = selectedProperty?.unit;
    const state = selectedProperty?.state;
    const city = selectedProperty?.city;
    const photo = selectedProperty?.photo;
    const mlsLogoOverlay = selectedProperty?.mlsLogoOverlay;
    const preferredSellingPrice = get(values, 'preferredSellingPrice');
    const errorForWorkingWithAgentQuestion =
      'error-are-you-working-with-a-real-estate-agent';
    const errorForPreferredSellingPrice = 'error-preferred-selling-price';
    const renderPropertyDetails = (
      type,
      formattedValue,
      unitText,
      isShowingVerticalSeparator
    ) =>
      type && (
        <div className={theme.PropertyDetailsWrapper}>
          <div className={theme.PropertyDetailsItem}>
            {formattedValue}{' '}
            {unitText !== PROPERTY_DETAIL_UNIT.SQUARE_FOOTAGE
              ? pluralize(unitText, formattedValue)
              : unitText}
          </div>
          {isShowingVerticalSeparator && <VerticalSeparator theme={theme} />}
        </div>
      );

    return (
      <CobrandedDataComponent>
        {({
          customizationData: { should_show_privacy_policy_links },
        }: CobrandedDataComponentArgs) => (
          <CobrandedStyles>
            {({ findAgentPageLinkColor, linkColor }) => (
              <div className={theme.FindAgentForm}>
                {/* Hidden via CSS on small screens */}
                <button
                  type="button"
                  aria-hidden={isShowingMobileCloseButton ? 'true' : 'false'}
                  disabled={isShowingMobileCloseButton}
                  aria-label="close"
                  className={theme.CloseIcon}
                  onClick={() => handleClose?.()}
                  onKeyDown={handleClose && onEnterOrSpaceKey(handleClose)}
                  style={{
                    top: shouldAdjustTopSpacing ? 110 : 36,
                  }}
                >
                  <CloseIcon />
                </button>
                {shouldDisplayBuyingOrSellingSection &&
                  this.getBuyingOrSellingSection()}
                {shouldDisplayPropertyDetailSection &&
                  city &&
                  state &&
                  address && (
                    <div className={theme.TopImageModule}>
                      <div
                        role="heading"
                        aria-level={2}
                        className={theme.FormHeader}
                      >
                        {formHeaderText}
                      </div>
                      <div className={theme.ImageAndPropertyInfoContainer}>
                        {photo ? (
                          <div
                            className={theme.TopImageModulePhoto}
                            style={{ backgroundImage: `url('${photo}')` }}
                            data-hc-name="property-photo"
                          >
                            {mlsLogoOverlay && (
                              <div
                                className={theme.MlsLogoOverlay}
                                data-hc-name="mls-logo"
                                style={{
                                  backgroundImage: `url('${mlsLogoOverlay}')`,
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            className={theme.ImageNullStateIconContainer}
                            data-hc-name="property-photo-null"
                            aria-label="No property photo available"
                          >
                            <ImageNullState
                              className={theme.ImageNullStateIcon}
                            />
                          </div>
                        )}
                        <div className={theme.PropertyInfo}>
                          <div
                            className={theme.PropertyInfoAddress}
                            data-hc-name="property-address"
                          >
                            {address}
                            {unit && <span> {unit}</span>}
                          </div>
                          <div
                            className={theme.PropertyInfoBasic}
                            data-hc-name="property-info"
                          >
                            {renderPropertyDetails(
                              beds,
                              placeholderFormatter(beds),
                              PROPERTY_DETAIL_UNIT.BED,
                              true
                            )}
                            {renderPropertyDetails(
                              baths,
                              placeholderFormatter(baths),
                              PROPERTY_DETAIL_UNIT.BATH,
                              true
                            )}
                            {renderPropertyDetails(
                              sqFt,
                              numbersWithPlaceholder(sqFt),
                              PROPERTY_DETAIL_UNIT.SQUARE_FOOTAGE,
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {formContentHeaderText && (
                  <div
                    role="heading"
                    aria-level={2}
                    className={classNames(
                      theme.FormHeader,
                      theme.FormContentHeaderText
                    )}
                  >
                    {formContentHeaderText}
                  </div>
                )}
                <form>
                  <div
                    className={
                      horizontal
                        ? theme.FormElementContainerHorizontal
                        : theme.FormElementContainer
                    }
                  >
                    <div className={theme.FormElementWrapper}>
                      <TextInputWithFormStyle
                        type="text"
                        label="First Name*"
                        aria-label="First Name"
                        ariaDescribedby="error-first-name"
                        name="first-name"
                        value={firstName}
                        required
                        role="textbox"
                        error={
                          firstName ? undefined : (
                            <div className={theme.BottomFormError}>
                              <FormError
                                theme={theme}
                                value="Required"
                                ariaDescribedby="error-first-name"
                              />
                            </div>
                          )
                        }
                        data-event-name={
                          dataEventName && `${dataEventName}_first_name`
                        }
                        data-parent-event-name={dataParentEventName}
                        data-event-data-json={JSON.stringify({
                          dataEventDataJson,
                        })}
                        onChange={(e) =>
                          this.updateFormField('firstName', e.target.value)
                        }
                      />
                    </div>
                    <div className={theme.FormElementWrapper}>
                      <TextInputWithFormStyle
                        type="text"
                        label="Last Name*"
                        aria-label="Last Name"
                        ariaDescribedby="error-last-name"
                        name="last-name"
                        value={lastName}
                        required
                        role="textbox"
                        data-event-name={
                          dataEventName && `${dataEventName}_last_name`
                        }
                        data-parent-event-name={dataParentEventName}
                        data-event-data-json={JSON.stringify({
                          dataEventDataJson,
                        })}
                        onChange={(e) =>
                          this.updateFormField('lastName', e.target.value)
                        }
                        error={
                          lastName ? undefined : (
                            <div className={theme.BottomFormError}>
                              <FormError
                                theme={theme}
                                value="Required"
                                ariaDescribedby="error-last-name"
                              />
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={
                      horizontal
                        ? theme.FormElementContainerHorizontal
                        : theme.FormElementContainer
                    }
                  >
                    <div
                      className={classNames(
                        theme.FormElementWrapper,
                        theme.EmailWrapper
                      )}
                    >
                      <EmailFieldWithFormStyle
                        required
                        label="Email*"
                        ariaDescribedby="error-email"
                        data-event-name={
                          dataEventName && `${dataEventName}_email`
                        }
                        data-parent-event-name={dataParentEventName}
                        data-event-data-json={JSON.stringify({
                          dataEventDataJson,
                        })}
                        onChange={(e) =>
                          this.updateFormField('email', e.target.value)
                        }
                        value={email}
                        error={email ? undefined : 'Required'}
                      />
                    </div>
                    <div className={theme.FormElementWrapper}>
                      <TextInputWithFormStyle
                        required
                        type="tel"
                        label="Phone*"
                        ariaDescribedby="error-phone"
                        data-event-name={
                          dataEventName && `${dataEventName}_phone`
                        }
                        data-parent-event-name={dataParentEventName}
                        data-event-data-json={JSON.stringify({
                          dataEventDataJson,
                        })}
                        onChange={(e) =>
                          this.updateFormField(
                            'phone',
                            formatPhoneInputForDisplay(e.target.value)
                          )
                        }
                        value={formatPhoneInputForDisplay(phone)}
                        error={
                          phone && isValidPhoneNumber(phone) ? undefined : (
                            <div className={theme.BottomFormError}>
                              <FormError
                                theme={theme}
                                value="Required"
                                ariaDescribedby="error-phone"
                              />
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                  {shouldDisplayBuyingOrSellingSection &&
                    isBuyingOrSellingSelectedOptionStr ===
                    BUYING_OR_SELLING.SELLING && (
                      <div className={theme.PreferredSellingPriceWrapper}>
                        <TextInputWithFormStyle
                          type="text"
                          label="Your preferred selling price*"
                          aria-label="Preferred Selling Price"
                          ariaDescribedby={errorForPreferredSellingPrice}
                          name="preferred-selling-price"
                          value={dollarsWithPlaceholder(
                            preferredSellingPrice,
                            ''
                          )}
                          required
                          role="textbox"
                          data-event-name={
                            dataEventName && `${dataEventName}_preferred_price`
                          }
                          data-parent-event-name={dataParentEventName}
                          data-event-data-json={JSON.stringify({
                            dataEventDataJson,
                          })}
                          onChange={(e) =>
                            this.updateFormField(
                              'preferredSellingPrice',
                              e.target.value
                            )
                          }
                          error={
                            preferredSellingPrice &&
                              this.getIsPreferredSellingPriceValid() ? undefined : (
                              <div className={theme.BottomFormError}>
                                <FormError
                                  theme={theme}
                                  value="Required"
                                  ariaDescribedby={
                                    errorForPreferredSellingPrice
                                  }
                                />
                              </div>
                            )
                          }
                        />
                      </div>
                    )}
                  {avm && (
                    <div className={theme.TooltipWrapper}>
                      <AvmDefinition
                        theme={theme}
                        tooltipPosition={isSmallScreen ? 'top' : 'bottom'}
                        maxWidth={isSmallScreen ? undefined : 500}
                        toolTipLabelStyle={{
                          color: findAgentPageLinkColor,
                        }}
                      />
                    </div>
                  )}
                  {shouldDisplayWorkingWithAgentQuestion && (
                    <div
                      className={classNames(
                        theme.FindAgentPromptContainer,
                        theme.FullWidth
                      )}
                    >
                      <div
                        className={classNames(
                          theme.FormElementWrapper,
                          theme.FullWidth
                        )}
                      >
                        <div className={theme.FindAgentPromptHeaderWithTooltip}>
                          <div
                            role="heading"
                            aria-level={2}
                            className={theme.FindAgentPromptHeader}
                            aria-describedby={errorForWorkingWithAgentQuestion}
                          >
                            Are you working with a real estate agent?*
                          </div>
                          <GenericLearnMoreTooltip
                            dataHcName="working-with-agent-tooltip"
                            theme={theme}
                            tooltipPosition={'bottom'}
                            toolTipLabelStyle={{
                              color: findAgentPageLinkColor,
                            }}
                            title={"What's this?"}
                            content={
                              'If you are currently working with a real estate agent, this is not meant as a solicitation of your business'
                            }
                          />
                        </div>
                        <div className={theme.FindAgentPrompt}>
                          {FIND_AGENT_PROMPT_OPTIONS.map((option: string) => (
                            <Checkbox
                              key={option}
                              theme={{
                                ...theme,
                                CheckboxInput: classNames(theme.CheckboxInput, {
                                  /* overriding checkbox manual background color to match invision */
                                  [theme.CheckBoxInputSelected]:
                                    hasAgentSelectedOptionStr === option,
                                }),
                              }}
                              onClick={() => this.handleSelectHasAgent(option)}
                              onKeyDown={onEnterOrSpaceKey(() =>
                                this.handleSelectHasAgent(option)
                              )}
                              isChecked={hasAgentSelectedOptionStr === option}
                              label={option}
                              name={option}
                            />
                          ))}
                        </div>
                        {
                          /* form required message will be refined in sprint of 4.3. putting this one in to explicitly state this is required */
                          !hasAgentSelectedOptionStr && (
                            <div
                              className={theme.AnswerIsRequiredText}
                              id={errorForWorkingWithAgentQuestion}
                            >
                              Required
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )}
                  {(shouldDisplayPreApproveSection ||
                    (shouldDisplayBuyingOrSellingSection &&
                      isBuyingOrSellingSelectedOptionStr ===
                      BUYING_OR_SELLING.BUYING)) &&
                    this.getPreApproveSection()}
                  {!shouldHideCommentsSection && (
                    <div className={theme.TextAreaWrapper}>
                      <TextAreaWithFormStyle
                        label="Comments (optional)"
                        type="text"
                        data-event-name={
                          dataEventName && `${dataEventName}_comments`
                        }
                        data-parent-event-name={dataParentEventName}
                        data-event-data-json={JSON.stringify({
                          dataEventDataJson,
                        })}
                        onChange={(e) =>
                          this.updateFormField('message', e.target.value)
                        }
                        showAccessibilityBorder
                        rows={5}
                        value={values.message}
                        error={
                          isValidMessageLength(values.message)
                            ? undefined
                            : 'Please limit your message to 255 characters'
                        }
                      />
                    </div>
                  )}
                  {shouldDisplayNextStepSection && (
                    <div className={theme.BottomInfoModule}>
                      <InfoIcon className={theme.InfoIcon} />
                      <div className={theme.BottomInfoModuleTextContainer}>
                        <div
                          className={theme.BottomInfoModuleHeader}
                          role="heading"
                          aria-level={3}
                        >
                          What happens Next?
                        </div>
                        <div className={theme.BottomInfoModuleBody}>
                          We'll provide 2-4 agents that specialize in this type
                          of home. You choose which best meets your needs.
                        </div>
                      </div>
                    </div>
                  )}
                  {shouldDisplayTermOfUseAndPrivacySection &&
                    this.getTermOfUseAndPrivacySection(
                      linkColor,
                      should_show_privacy_policy_links
                    )}
                  {submitBtnText && (
                    <div className={theme.SubmitButtonWrapper}>
                      {isShowingMobileCloseButton ? (
                        <div className={theme.MobileSubmitButtonInnerWrapper}>
                          <PillButton
                            ariaLabel="cancel"
                            theme={theme}
                            className={theme.CancelButton}
                            onClick={() => handleClose?.()}
                            onKeyDown={
                              handleClose && onEnterOrSpaceKey(handleClose)
                            }
                            deemphasized
                          >
                            Cancel
                          </PillButton>

                          <PillButton
                            style={{
                              cursor: !areAllFieldsValid
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            className={classNames(theme.SubmitButton, {
                              [theme.SubmitButtonDisabled]: !areAllFieldsValid,
                            })}
                            disabled={!areAllFieldsValid}
                            ariaLabel="Send contact info"
                            onClick={this.handleSubmitForm}
                            onKeyDown={onEnterOrSpaceKey(this.handleSubmitForm)}
                            theme={theme}
                          >
                            {submitBtnText}
                          </PillButton>
                        </div>
                      ) : (
                        <PillButton
                          style={{
                            cursor: !areAllFieldsValid
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                          className={classNames(theme.SubmitButton, {
                            [theme.SubmitButtonDisabled]:
                              !areAllFieldsValid || hasSubmitted,
                          })}
                          disabled={!areAllFieldsValid || hasSubmitted}
                          ariaLabel="Send contact info"
                          onClick={this.handleSubmitForm}
                          onKeyDown={onEnterOrSpaceKey(this.handleSubmitForm)}
                          theme={theme}
                        >
                          {submitBtnText}
                        </PillButton>
                      )}
                    </div>
                  )}
                </form>
              </div>
            )}
          </CobrandedStyles>
        )}
      </CobrandedDataComponent>
    );
  }
}

const ThemedPropertyContactForm = themr(
  'PropertyContactForm ',
  defaultTheme
)(PropertyContactForm);
export default ThemedPropertyContactForm;
