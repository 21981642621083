import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import FormModal, {
  FieldValuesByUId,
} from '@client/components/generic/FormModal';
import { View } from '@client/routes/constants';
import { getCurrentUser } from '@client/store/selectors/auth.selectors';
import TextInput from '@client/components/generic/TextInput';
import EmailField from '@client/components/generic/EmailField';
import TextArea from '@client/components/generic/TextArea';
import { closeModal } from '@client/store/actions/modals.actions';
import RouterLink from '@client/components/RouterLink';
import { reportEvent } from '@client/store/actions/analytics.actions';
import theme from '@client/css-modules/ContactNewAgentModal.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import {
  submitFindNewAgentForm,
  FindNewAgentFormValues,
  submitAgentLeadReferralNetwork,
} from '@client/store/actions/find-agent.actions';
import {
  getActivePDPPropertyData,
  getActivePDPSlug,
} from '@client/store/selectors/router.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

export const ContactNewAgentModal: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const activePDPSlug = useSelector(getActivePDPSlug);
  const activePDPData = useSelector(getActivePDPPropertyData);
  const firstName = currentUser?.first_name;
  const lastName = currentUser?.last_name;
  const email = currentUser?.email;
  const phone = currentUser?.phone;

  const activeModalKey = useSelector(getActiveModalKey);
  const { linkColor } = useCobrandStyles();
  const isAgentReferralLeadNetworkEnabled = useSelector(
    getIsFeatureEnabled('agent_lead_referral_network')
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleContactAgent = (fieldValues: FieldValuesByUId) => {
    const { firstName, lastName, email, phone, message } = fieldValues;

    const values: FindNewAgentFormValues = {
      firstName,
      lastName,
      email,
      phone,
      message,
      buyingOrSelling: 'Buying',
      formName: 'Contact New Agent',
      hasAgent: false,
      ...(activePDPSlug && {
        entrySlug: activePDPSlug,
        propertyDetailSlugs: [activePDPSlug],
      }),
      ...(activePDPData && {
        selectedProperty: {
          address: activePDPData.address?.streetAddress,
          baths: activePDPData.livingSpace?.bathrooms?.summaryCount,
          beds: activePDPData.livingSpace?.bedrooms?.count,
          city: activePDPData.address?.city,
          state: activePDPData.address?.state,
          maxPrice: activePDPData.listPrice,
          unit: activePDPData.address?.unit,
          zip: activePDPData.address?.zipcode,
        },
      }),
    };
    if (isAgentReferralLeadNetworkEnabled) {
      dispatch(
        submitAgentLeadReferralNetwork({
          preferred_contact_type: 'email',
          phone: values.phone,
          email: values.email,
          message: values.message,
          slug: activePDPSlug ?? '',
        })
      );
    } else {
      dispatch(submitFindNewAgentForm(values));
    }
    dispatch(
      reportEvent(
        'click_contact_agent_pdp_railcard_form_submit',
        'realestate_agent_contacts'
      )
    );
  };

  const FormPageBottomContent = (linkColor: string) => (
    <div className={theme.TermsOfUseCopy}>
      By pressing Contact Agent, you agree to our{' '}
      <RouterLink
        style={{ color: linkColor }}
        view={View.TERMS_OF_USE}
        data-ignore-intercept
        ariaLabel="terms of use"
      >
        Terms of Use
      </RouterLink>
    </div>
  );

  const fields = [
    {
      uId: 'firstName',
      component: TextInput,
      label: 'First name*',
      required: true,
      initialValue: firstName || '',
      'data-hc-name': 'first-name-field',
    },
    {
      uId: 'lastName',
      component: TextInput,
      label: 'Last name*',
      required: true,
      initialValue: lastName || '',
      'data-hc-name': 'last-name-field',
    },
    {
      uId: 'email',
      component: EmailField,
      label: 'Email*',
      required: true,
      initialValue: email || '',
      'data-hc-name': 'email-field',
    },
    {
      uId: 'phone',
      component: TextInput,
      label: 'Phone*',
      required: true,
      initialValue: phone || '',
      'data-hc-name': 'phone-field',
    },
    {
      uId: 'message',
      component: TextArea,
      label: 'Comments',
      required: false,
      'data-hc-name': 'message-field',
    },
  ];

  return (
    <FormModal
      dataHcName={'contact-new-agent-form'}
      isActive={activeModalKey === 'contact-new-agent'}
      FormPageTopContent={
        <h2 className={theme.ContactNewAgentHeader}>Contact an Agent</h2>
      }
      FormPageBottomContent={FormPageBottomContent(linkColor)}
      fields={fields}
      handleSubmit={handleContactAgent}
      handleClose={handleCloseModal}
      ctaText="Contact Agent"
      modalAriaLabel={'Contact an Agent'}
      submitButtonAriaLabel={'Send contact info'}
      theme={theme}
      isBottomContentAboveCTA
    />
  );
};

export default ContactNewAgentModal;
