import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import SmallModal from '@client/components/generic/SmallModal';
import { closeBrokerageContactModal } from '@client/store/actions/brokerage-info.actions';
import {
  getShouldOpenBrokerageInfoSuccessModal,
  getShouldOpenBrokerageInfoErrorModal,
} from '@client/store/selectors/brokerage-info.selectors';
import { View } from '@client/routes/constants';
import RouterLink from '@client/components/RouterLink';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import theme from '@client/css-modules/BrokerageContactConfirmModal.css';
import { clearFeedbackSubmitStatus } from '@client/store/actions/feedback.actions';
import PillButton from '@client/components/generic/PillButton';
import CaratIconCobranded from '@client/components/CaratIcon/CaratIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

export const BrokerageContactConfirmModal: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { linkColor } = useCobrandStyles();
  const handleCloseModal = () => {
    /* 👇🏼 needed since BrokerageInfoPage listens to feedbackStatus state */
    dispatch(clearFeedbackSubmitStatus());
    dispatch(closeBrokerageContactModal());
  };
  const shouldOpenBrokerageInfoSuccessModal = useSelector(
    getShouldOpenBrokerageInfoSuccessModal
  );
  const shouldOpenBrokerageInfoErrorModal = useSelector(
    getShouldOpenBrokerageInfoErrorModal
  );

  return (
    <SmallModal
      className={theme.BrokerageContactConfirmModal}
      isActive={
        shouldOpenBrokerageInfoSuccessModal || shouldOpenBrokerageInfoErrorModal
      }
      handleClose={handleCloseModal}
      theme={theme}
    >
      <div className={theme.ModalContent}>
        <div className={theme.ModalText} data-hc-name="confirmation-modal-text">
          {shouldOpenBrokerageInfoSuccessModal &&
            'Thank you, your message is on the way. We’ll be in touch!'}
          {shouldOpenBrokerageInfoErrorModal &&
            'Sorry, something went wrong. Please try again.'}
        </div>
        <PillButton
          ariaLabel={
            shouldOpenBrokerageInfoSuccessModal
              ? 'brokerage info success modal'
              : shouldOpenBrokerageInfoErrorModal
              ? 'brokerage info error modal'
              : ''
          }
          theme={theme}
          onClick={handleCloseModal}
          onKeyDown={onEnterOrSpaceKey(handleCloseModal)}
        >
          <div className={theme.WhiteCheckIcon} />
        </PillButton>
        {!isLoggedIn && (
          <div className={theme.Auth}>
            Don't have an account?&nbsp;
            <RouterLink
              className={theme.SignUpLink}
              style={{ color: linkColor }}
              view={View.SIGN_UP}
              onClick={handleCloseModal}
              onKeyDown={onEnterOrSpaceKey(handleCloseModal)}
              ariaLabel="Sign up"
              data-hc-name="confirmation-sign-up-link"
            >
              Sign up&nbsp;
              <CaratIconCobranded className={theme.CaratIcon} />
            </RouterLink>
          </div>
        )}
      </div>
    </SmallModal>
  );
};

export default BrokerageContactConfirmModal;
