import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import { closeModal } from '@client/store/actions/modals.actions';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import React, { useEffect, useState } from 'react';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { View } from '@client/routes/constants';
import {
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
} from '@client/store/selectors/property-details.selectors';
import { ensureLoginThenAddToWatchList } from '@client/store/actions/watchlist.actions';
import PillButton from '@client/components/generic/PillButton';
import theme from '@client/css-modules/RelayStateActionConfirmationModal.css';
import { selectIsHomeVerified } from '@client/store/slices/homeowner-verification.slice';
import { useTriggerHomeVerificationFlow } from '@client/hooks/homeowner-verification.hooks';

type Config = {
  view: string;
  header: string;
  body: string;
  buttonText: string;
  modalAccessibilityLabel: string;
  buttonAccessibilityLabel: string;
};

const AddWatchlistConfig: Config = {
  view: View.PROPERTY_DETAILS,
  header: 'Add to saved homes?',
  body: 'Would you like to add this property to your list of saved homes?',
  buttonText: 'Confirm',
  modalAccessibilityLabel: 'add watchlist confirmation modal',
  buttonAccessibilityLabel:
    'Click confirm to add this property to your list of saved homes.',
};

const ClaimHomeConfig: Config = {
  view: View.HOMEOWNER_PROPERTY_DETAILS,
  header: 'Is this your home?',
  body: 'Would you like to add this home to your ComeHome profile? You may be asked some questions in order to verify ownership of the property.',
  buttonText: "I'm the Owner",
  modalAccessibilityLabel: 'claim home confirmation modal',
  buttonAccessibilityLabel: 'Click confirm to claim this home.',
};

export const RelayStateActionConfirmationModal: React.FC = () => {
  // Basic presentation delay to avoid showing a popup while the site is loading
  const [isDelaying, setIsDelaying] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelaying(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const dispatch = useDispatch();
  const triggerHomeVerificationFlow = useTriggerHomeVerificationFlow();

  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const slug = propertyDetails?.slug;

  const activeModalKey = useSelector(getActiveModalKey);
  const currentView = useSelector(getCurrentView);

  const isAddWatchlistModal = activeModalKey === 'confirm-add-watchlist-modal';
  const isClaimHomeModal = activeModalKey === 'confirm-claim-home-modal';
  const modalKeyMatches = isAddWatchlistModal || isClaimHomeModal;

  const isPropertyInWatchlist = useSelector(getPropertyIsInWatchList);
  const isPropertyClaimedHome = useSelector(selectIsHomeVerified);
  const isPropertyActionable = isAddWatchlistModal
    ? !isPropertyInWatchlist
    : !isPropertyClaimedHome;

  const config = isAddWatchlistModal ? AddWatchlistConfig : ClaimHomeConfig;
  const confirmAction = isAddWatchlistModal
    ? () => {
        !!slug &&
          dispatch(
            ensureLoginThenAddToWatchList({
              slug: propertyDetails.slug,
              street: propertyDetails.streetAddress,
              city: propertyDetails.city,
              state: propertyDetails.state,
              zip: propertyDetails.zipcode,
              unit: propertyDetails.unit,
              address_id: propertyDetails.hcAddressId,
            })
          );
      }
    : triggerHomeVerificationFlow;
  const handleClose = () => dispatch(closeModal());

  return (
    <div>
      {!isDelaying && (
        <SlideInModalContainer
          isActive={
            modalKeyMatches &&
            currentView === config.view &&
            isPropertyActionable
          }
          modalAriaLabel={config.modalAccessibilityLabel}
          handleClose={handleClose}
          className={theme.RelayStateActionConfirmationModal}
          theme={theme}
        >
          <div>
            <div className={theme.TextContent}>
              <h2>{config.header}</h2>
              <p>{config.body}</p>
            </div>

            <div className={theme.ButtonRow}>
              <PillButton
                theme={theme}
                onClick={() => {
                  confirmAction();
                  handleClose();
                }}
                ariaLabel={config.buttonAccessibilityLabel}
              >
                {config.buttonText}
              </PillButton>
            </div>
          </div>
        </SlideInModalContainer>
      )}
    </div>
  );
};
