import NoMLSModal from '@client/components/NoMLSModal/NoMLSModal';
import NoMLSModalChase from '@client/components/NoMLSModal/NoMLSModalChase';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return NoMLSModalChase;
    default:
      return NoMLSModal;
  }
};

const NoMLSModalCobranded: React.FC<Omit<{}, 'theme'>> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default NoMLSModalCobranded;
