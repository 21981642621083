export type ReferralPropertyData = {
  address?: string | null;
  unit?: string | null;
  city?: string | null;
  homeType?: string | null;
  minPrice?: number | null;
  zip?: string | null;
  state?: string | null;
  beds?: number | null;
  baths?: number | null;
  sqFt?: number | null;
  mlsLogoOverlay?: string | null;
  photo?: string | null;
  maxPrice?: number | null;
  avm?: number | null;
};

export const BUYING_OR_SELLING = {
  BUYING: 'Buying' as 'Buying',
  SELLING: 'Selling' as 'Selling',
};
export type BuyingOrSellingOption =
  typeof BUYING_OR_SELLING[keyof typeof BUYING_OR_SELLING];

export type ReferralFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  message?: string;
  phone: string;
  formName?: string;
  entrySlug?: string;
  preApproved?: boolean;
  propertyDetailSlugs?: Array<string>;
  selectedProperty?: ReferralPropertyData;
  hasAgent?: boolean;
  buyingOrSelling?: BuyingOrSellingOption;
  agentId?: string;
  incompleteAgentId?: string;
  notes?: string[];
  tourRequest?: boolean;
};
