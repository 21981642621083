import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getIsModalActive } from '@client/store/selectors/modals.selectors';
import SmallModal from '@client/components/generic/SmallModal';
import { closeModal } from '@client/store/actions/modals.actions';
import theme from '@client/css-modules/RecentUserActivity/RecentUserActivityFlyout.css';
import RecentPropertyWithGQLFetch from '@client/components/RecentUserActivity/RecentPropertyWithGQLFetch';
import {
  fetchRecentProperties,
  fetchRecentSearches,
  selectRecentProperties,
  selectRecentSearches,
  goToRecentSearch,
} from '@client/store/slices/recent-user-activity.slice';
import RecentNullState from '@client/components/RecentUserActivity/RecentNullState';
import SavedRecentSearchButton from '@client/components/RecentUserActivity/SavedRecentSearchButton';
import { fetchSavedSearchesIfNecessary } from '@client/store/actions/saved-search.actions';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import LazilyRenderedList from '../LazilyRenderedList';

const StyledMainHeader = styled.h1`
  font-weight: 900;
  font-size: 14px;
  text-align: center;
`;
const StyledSubHeader = styled.h2`
  font-weight: 900;
  font-size: 12px;
`;
const StyledTimeLabel = styled.h3`
  font-weight: 900;
  font-size: 10px;
  color: #767676;
  margin-top: 15px;
`;
const StyledSection = styled.section`
  margin: 30px 0;
`;
const StyledRecentSearch = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
`;
const StyledRecentSearchItem = styled.button`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px 0 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-weight: lighter;
  text-align: start;
  font-size: 12px;
`;
const StyledShowAllButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const StyledShowAllButton = styled.button`
  background: #e9e9e9;
  border-radius: 6px;
  width: 74px;
  height: 25px;
  margin: 15px auto;
  font-weight: 500;
  font-size: 10px;
  border: none;
  cursor: pointer;
`;
const StyledRecentPropertyItemWrapper = styled.div`
  margin: 20px 0;
`;

/* Inserts appropriate time label on first item of its time category */
function getTimeLabelComponent(
  timeStamp: string,
  timeLabelIdxRef: React.MutableRefObject<number | null>
) {
  let shouldCreateNewTimeLabel = false;
  const TIME_LABELS = ['Today', 'This week', 'This month', 'This year'];
  const diffDays = Math.round(
    Math.abs(
      (new Date().getTime() - new Date(timeStamp).getTime()) /
        (24 * 60 * 60 * 1000) /* oneDay */
    )
  );

  if (diffDays === 0 && timeLabelIdxRef.current !== 0) {
    shouldCreateNewTimeLabel = true;
    timeLabelIdxRef.current = 0;
  } else if (diffDays > 0 && diffDays < 8 && timeLabelIdxRef.current !== 1) {
    shouldCreateNewTimeLabel = true;
    timeLabelIdxRef.current = 1;
  } else if (diffDays > 7 && diffDays < 31 && timeLabelIdxRef.current !== 2) {
    shouldCreateNewTimeLabel = true;
    timeLabelIdxRef.current = 2;
  } else if (diffDays > 31 && timeLabelIdxRef.current !== 3) {
    shouldCreateNewTimeLabel = true;
    timeLabelIdxRef.current = 3;
  }

  return (
    shouldCreateNewTimeLabel &&
    /* isNumeric test */
    /^-?\d+$/.test(`${timeLabelIdxRef.current}`) && (
      <StyledTimeLabel
        data-hc-name="recent-property-time-label"
        aria-label="Recent Property Time Label"
      >
        {TIME_LABELS[timeLabelIdxRef.current!]}
      </StyledTimeLabel>
    )
  );
}

export default function RecentUserActivityCTAFlyout() {
  const dispatch = useDispatch();
  const [isShowingAllSearch, setIsShowingAllSearch] = useState(false);
  const isModalOpen = useSelector(getIsModalActive('recent-user-activity'));
  const isLoggedIn = useSelector(getIsLoggedIn);
  const recentSearches = useSelector(selectRecentSearches) || [];
  const recentProperties = useSelector(selectRecentProperties) || [];
  const recentSearchDisplayNum = isShowingAllSearch ? recentSearches.length : 3;
  const isNoData = recentSearches.length === 0 && recentProperties.length === 0;
  const timeLabelIdxRef = useRef<null | number>(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isModalOpen && isLoggedIn) {
      setIsShowingAllSearch(false);
      dispatch(fetchSavedSearchesIfNecessary());
      dispatch(fetchRecentSearches());
      dispatch(fetchRecentProperties());
    }
  }, [isModalOpen, isLoggedIn, dispatch]);

  return (
    <SmallModal
      theme={theme}
      isActive={isModalOpen}
      showHideDuration={0.0001}
      handleClose={() => dispatch(closeModal())}
    >
      <div
        ref={modalRef}
        data-hc-name="recent-user-activity-content"
        aria-label="Recent User Activity Content"
      >
        <StyledMainHeader>Activity</StyledMainHeader>
        {isNoData && <RecentNullState />}
        {recentSearches.length > 0 && (
          <StyledSection>
            <StyledSubHeader>Recent Searches</StyledSubHeader>
            {recentSearches
              .slice(0, recentSearchDisplayNum)
              .map((recentSearch, idx) => {
                const minBeds = recentSearch?.min_beds;
                const minBaths = recentSearch?.min_baths;
                const delimiter = minBeds || minBaths ? ' - ' : '';
                const recentSearchText = `${recentSearch?.name}${delimiter}${
                  minBeds ? `Min ${minBeds} Beds` : ''
                }${minBaths ? `, Min ${minBaths} Baths` : ''}`.replace(
                  '\n',
                  ''
                );
                return (
                  <StyledRecentSearch key={`recent-search-item-${idx}`}>
                    <StyledRecentSearchItem
                      title={recentSearchText}
                      data-hc-name="recent-search-item"
                      aria-label={`Recent Search #${
                        idx + 1
                      }: ${recentSearchText}`}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        dispatch(
                          goToRecentSearch({ searchId: recentSearch.search_id })
                        );
                        if (isModalOpen) {
                          dispatch(closeModal());
                        }
                        dispatch(
                          reportEvent(
                            'click_recent_activity_activity_page_click_search',
                            'click_recent_activity'
                          )
                        );
                      }}
                    >
                      {recentSearchText}
                    </StyledRecentSearchItem>
                    <SavedRecentSearchButton recentSearch={recentSearch} />
                  </StyledRecentSearch>
                );
              })}
            {!isShowingAllSearch && recentSearches.length > 3 && (
              <StyledShowAllButtonWrapper>
                <StyledShowAllButton
                  aria-label="Show all"
                  onClick={() => setIsShowingAllSearch(!isShowingAllSearch)}
                  data-parent-event-name="click_recent_activity"
                  data-event-name="click_recent_activity_activity_page_show_all"
                >
                  Show all
                </StyledShowAllButton>
              </StyledShowAllButtonWrapper>
            )}
          </StyledSection>
        )}
        {recentProperties.length > 0 && (
          <StyledSection>
            <StyledSubHeader>Recently Viewed</StyledSubHeader>
            <LazilyRenderedList
              llKeyField="data-llkey-field"
              dataHcName="recent-property-list"
              scrollableAncestorClassName={theme.Modal}
              shouldScrollToTopOnChildChange={false}
              shouldKeepItemsRendered
              preloadBuffer={55 * 3}
              itemDimensionsStyle={{
                width: '100%',
                minHeight: '55px',
              }}
            >
              {recentProperties.map((property, idx) => {
                if (idx === 0) {
                  /* ensures timeLabelIdxRef resets on the first property on multiple react renders */
                  timeLabelIdxRef.current = null;
                }
                return (
                  <div
                    key={property.address_id}
                    data-llkey-field={property.address_id}
                  >
                    {getTimeLabelComponent(property.updated, timeLabelIdxRef)}
                    <StyledRecentPropertyItemWrapper>
                      <RecentPropertyWithGQLFetch
                        addressId={property.address_id}
                        dataEventNameSaveClick="click_recent_activity_activity_page_save_property"
                        dataEventNamePropertyClick="click_recent_activity_activity_page_click_property"
                      />
                    </StyledRecentPropertyItemWrapper>
                  </div>
                );
              })}
            </LazilyRenderedList>
          </StyledSection>
        )}
      </div>
    </SmallModal>
  );
}
