import React from 'react';
import { Alert } from '@client/utils/reach-alert';
import { useDispatch, useSelector } from 'react-redux';

import InnerNotification from '@client/components/generic/InnerNotification';
import theme from '@client/css-modules/NotificationModalWithLink.css';
import { clearActiveNotification } from '@client/store/actions/modals.actions';
import { getIsNotificationActive } from '@client/store/selectors/modals.selectors';
import GreenCheckIcon from '@client/inline-svgs/green-check';

const NOTIFICATION_KEY = 'home-verified-notification';

export default function HomeVerifiedNotification() {
  const isModalActive = useSelector(getIsNotificationActive(NOTIFICATION_KEY));
  const dispatch = useDispatch();

  return (
    <InnerNotification
      isActive={isModalActive}
      theme={theme}
      autoDismissDuration={5000}
      handleClose={() => {
        dispatch(clearActiveNotification());
      }}
    >
      <div
        className={theme.NotificationModalWithLink}
        data-hc-name={NOTIFICATION_KEY}
      >
        <div className={theme.TitleContainer}>
          <div className={theme.IconWrapper}>
            <GreenCheckIcon className={theme.GreenCheckIcon} />
          </div>
        </div>
        <div className={theme.Message}>
          <Alert type="assertive">
            Welcome homeowner! You now have free access to all the features
            available on your dashboard.
          </Alert>
        </div>
      </div>
    </InnerNotification>
  );
}
