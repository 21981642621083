import { connect } from 'react-redux';

import ContactForm from '@client/components/PropertyContactForm';
import { updateUserProfile } from '@client/store/actions/auth.actions';
import {
  getUserContactInfo,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import { getLenderSpecificPageTitle } from '@client/store/selectors/router.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getIsUpdateEmailBannerOpen } from '@client/store/selectors/app-banner.selectors';

interface OwnProps {
  isShowingMobileCloseButton?: boolean;
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  let showMobileCloseBtn = false;
  if (ownProps.isShowingMobileCloseButton !== undefined) {
    showMobileCloseBtn = ownProps.isShowingMobileCloseButton;
  } else {
    showMobileCloseBtn = getIsSmallSize(state);
  }

  return {
    currentUserData: getUserContactInfo(state),
    lenderSpecificPageTitle: getLenderSpecificPageTitle(state),
    isLoggedIn: getIsLoggedIn(state),
    isShowingMobileCloseButton: showMobileCloseBtn,
    isSmallScreen: getIsSmallSize(state),
    shouldAdjustTopSpacing: getIsUpdateEmailBannerOpen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (userInfo) => {
      dispatch(updateUserProfile(userInfo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
