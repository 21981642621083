import { Alert } from '@client/utils/reach-alert';
import { useDispatch, useSelector } from 'react-redux';

import InnerNotification from '@client/components/generic/InnerNotification';
import AlertUserIcon from '@client/inline-svgs/alert-user';
import theme from '@client/css-modules/SavedSearchNotification.css';
import { getIsNotificationActive } from '@client/store/selectors/modals.selectors';
import { clearActiveNotification } from '@client/store/actions/modals.actions';
import {
  getCreatedSavedSearchErrorMessage,
  getSelectedSavedSearchDisplayName,
} from '@client/store/selectors/saved-search.selectors';
import GreenCheckMarkIconCobranded from '@client/components/GreenCheckMarkIcon/GreenCheckMarkIconCobranded';

const NOTIFICATION_KEY = 'saved-search-notification';

function SavedSearchNotification() {
  const isActive = useSelector(getIsNotificationActive(NOTIFICATION_KEY));
  const errorMessage = useSelector(getCreatedSavedSearchErrorMessage);
  const savedSearchDisplayName = useSelector(getSelectedSavedSearchDisplayName);
  const dispatch = useDispatch();
  function handleCloseModal() {
    dispatch(clearActiveNotification());
  }

  return (
    <div data-hc-name={NOTIFICATION_KEY}>
      <InnerNotification
        isActive={isActive}
        theme={theme}
        autoDismissDuration={5000}
        handleClose={handleCloseModal}
      >
        <div className={theme.IconWrapper}>
          {errorMessage ? (
            <AlertUserIcon />
          ) : (
            <GreenCheckMarkIconCobranded className={theme.GreenCheckIcon} />
          )}
        </div>
        <div className={theme.Body}>
          {savedSearchDisplayName && (
            <div className={theme.SearchTitle}>{savedSearchDisplayName}</div>
          )}
          <div className={theme.Message}>
            <Alert type="assertive">
              {errorMessage ??
                "Success! You'll be alerted when we find new homes nearby that meet your parameters."}
            </Alert>
          </div>
        </div>
      </InnerNotification>
    </div>
  );
}

export default SavedSearchNotification;
