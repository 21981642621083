import { useSelector } from 'react-redux';
import { getIsWellsFargoCobrand } from '@client/store/selectors/cobranding.selectors';
import TermsAndConditionsModal from '@client/components/TermsAndConditionsModal/TermsAndConditionsModal';
import loadable from '@loadable/component';
const TermsAndConditionsModalWells = loadable(
  () =>
    import(
      '@client/components/TermsAndConditionsModal/TermsAndConditionsModalWells'
    )
);

const getComponentForCobrand = (isWellsFargoCobrand: boolean) => {
  if (isWellsFargoCobrand) {
    return TermsAndConditionsModalWells;
  } else {
    return TermsAndConditionsModal;
  }
};

const TermsAndConditionsModalCobranded: React.FC = () => {
  const cobrandId = useSelector(getIsWellsFargoCobrand);
  const Component = getComponentForCobrand(cobrandId);
  return <Component />;
};

export default TermsAndConditionsModalCobranded;
