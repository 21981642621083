import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import theme from '@client/css-modules/InviteSearchAgreementModal.css';
import { closeModal } from '@client/store/actions/modals.actions';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import { authModalShow } from '@client/store/actions/auth.actions';
import { getSearchListProperties } from '@client/store/selectors/search.selectors';
import { optInBuyerEmails } from '@client/store/actions/invite.actions';
import { getLastSearchPlaceDesc } from '@client/store/selectors/invite.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const InviteSearchAgreementModal: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal());

  const activeModalKey = useSelector(getActiveModalKey);
  const sidebarProperties = useSelector(getSearchListProperties);
  const buyerLocation = useSelector(getLastSearchPlaceDesc);

  const {
    pillButtonPrimaryBackgroundColor,
    pillButtonSecondaryBackgroundColor,
    pillButtonPrimaryTextColor,
    pillButtonSecondaryTextColor,
  } = useCobrandStyles();

  const handleLoadResults = () => {
    dispatch(optInBuyerEmails());
    handleClose();
  };

  return (
    <SlideInModalContainer
      isFullScreen={false}
      hideCloseIcon={true}
      stickToBottomForSmallDevice={true}
      isActive={activeModalKey === 'invite-search-agreement-modal'}
      modalAriaLabel={'invite-search-agreement-modal'}
      handleClose={() => {}}
    >
      <div
        data-testid="invite-search-agreement-modal"
        className={theme.Wrapper}
      >
        <div className={theme.Title}>
          Looking for a home in {buyerLocation ? buyerLocation : 'the area'}?
        </div>
        <p className={theme.OptInDescription}>
          Click here to receive email alerts about listings in this area
        </p>
        <button
          data-testid="opt-in-and-load-results"
          style={{
            background: pillButtonPrimaryBackgroundColor,
            color: pillButtonPrimaryTextColor,
          }}
          onClick={handleLoadResults}
          className={theme.ModalButton}
        >
          Load {sidebarProperties.length} results
        </button>
        <p className={theme.EditSearch}>Edit this search?</p>
        <button
          data-testid="create-account"
          style={{
            background: pillButtonSecondaryBackgroundColor,
            color: pillButtonSecondaryTextColor,
          }}
          onClick={() =>
            dispatch(authModalShow({ afterAuthAction: closeModal() }))
          }
          className={theme.ModalButton}
        >
          Create an account
        </button>
        <div
          data-testid="explore-comehome"
          onClick={handleClose}
          className={theme.Explore}
        >
          Explore ComeHome
        </div>
      </div>
    </SlideInModalContainer>
  );
};

export default InviteSearchAgreementModal;
