import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { ensureLoggedInThenAction } from '@client/store/actions/auth.actions';
import { View } from '@client/routes/constants';
import {
  clearAfterVerificationViewCache,
  cacheAfterVerificationView,
  fetchVerificationData,
  selectVerificationData,
} from '@client/store/slices/homeowner-verification.slice';
import {
  getActivePropertyClaimedHomeInfo,
  getClaimedHomesStatus,
} from '@client/store/selectors/homeowner.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import {
  claimProperty,
  fetchHomeownerPropertyIfNecessary,
} from '@client/store/actions/homeowner.actions';
import { STATUSES } from '@client/store/constants';

/**
 * abstracted trigger logic to be re-used in HOAvmBanner cta, HOCardWithBackgroundImage cta, and saml flow
 */
export function useTriggerHomeVerificationFlow(afterVerificationView?: View) {
  const dispatch = useDispatch();
  const slugInParams = useSelector(getActiveSlugForHomeownerAndSubpages);

  return () => {
    dispatch(
      reportEvent('click_owner_verify_cta', PARENT_EVENTS.CLICK_OWNER_VERIFY, {
        slug: slugInParams,
      })
    );
    dispatch(
      ensureLoggedInThenAction(
        routeChange({
          view: View.VERIFICATION,
          params: { slug: slugInParams },
        })
      )
    );
    if (afterVerificationView && slugInParams) {
      dispatch(
        cacheAfterVerificationView({
          slug: slugInParams,
          afterVerificationView,
        })
      );
    }
  };
}

/**
 * abstracted redirecting logic to make HomeVerificationPage.tsx more readable
 */
export function useRedirectOnceVerifiedEffect() {
  const dispatch = useDispatch();
  const slugInParams = useSelector(getActiveSlugForHomeownerAndSubpages);
  const cachedVerificationData = useSelector(selectVerificationData);

  useEffect(() => {
    if (cachedVerificationData?.isVerified && slugInParams) {
      dispatch(
        routeChange({
          view:
            cachedVerificationData?.afterVerificationView ??
            View.HOMEOWNER_PROPERTY_DETAILS,
          params: { slug: slugInParams },
        })
      );
      dispatch(clearAfterVerificationViewCache({ slug: slugInParams }));
    }
  }, [cachedVerificationData?.isVerified]);
}

/**
 * abstracted verification data fetcher to make HomeVerificationPage.tsx more readable
 */
export function useFetchVerificationDataEffect() {
  const dispatch = useDispatch();
  const activeClaimedHomeData = useSelector(getActivePropertyClaimedHomeInfo);
  const slugInParams = useSelector(getActiveSlugForHomeownerAndSubpages);
  const cachedVerificationData = useSelector(selectVerificationData);

  useEffect(() => {
    if (slugInParams) {
      dispatch(fetchHomeownerPropertyIfNecessary(slugInParams));
    }
    if (
      activeClaimedHomeData &&
      (cachedVerificationData?.questionsLoadingStatus === STATUSES.INIT ||
        !cachedVerificationData)
    ) {
      dispatch(fetchVerificationData());
    }
    /* in case when /verification is hit directly and dynamically claiming a home */
  }, [activeClaimedHomeData]);
}

/**
 * abstracted auto-claim home logic on an attempt to verify unclaimed home in HomeVerificationPage.tsx
 */
export function useEnsureHomeIsClaimedEffect() {
  const dispatch = useDispatch();
  const slugInParams = useSelector(getActiveSlugForHomeownerAndSubpages);
  const activeClaimedHomeData = useSelector(getActivePropertyClaimedHomeInfo);
  const fetchClaimedHomesStatus = useSelector(getClaimedHomesStatus);

  useEffect(() => {
    if (
      slugInParams &&
      !activeClaimedHomeData &&
      fetchClaimedHomesStatus === STATUSES.SUCCESS
    ) {
      dispatch(claimProperty(slugInParams));
    }
  }, [fetchClaimedHomesStatus]);
}
