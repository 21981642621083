import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';

export const getToastState = (state: ReduxState) => state.toast;

export const getToastMessage = createSelector(
  getToastState,
  (toastState) => toastState.message
);
