import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';

function getBrokerageInfoState(state: ReduxState) {
  return state.brokerageInfo;
}

export const getShouldOpenBrokerageInfoSuccessModal = createSelector(
  getBrokerageInfoState,
  (brokerageInfoState) => brokerageInfoState.shouldOpenSuccessModal
);

export const getShouldOpenBrokerageInfoErrorModal = createSelector(
  getBrokerageInfoState,
  (brokerageInfoState) => brokerageInfoState.shouldOpenErrorModal
);
