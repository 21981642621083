import { useSelector } from 'react-redux';
import { getPropertyAgentInfo } from '@client/store/selectors/property-details.selectors';
import theme from '@client/css-modules/RequestATourAgentInfo.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import AccountIcon from '@client/inline-svgs/account-icon';
import { formatPhoneNumber } from '@client/utils/string.utils';

export default function RequestATourAgentInfo() {
  const agentInfo = useSelector(getPropertyAgentInfo);
  const { agentName, phone, officeName } = agentInfo;
  const { propertyListingPhoneColor } = useCobrandStyles();

  return (
    <div className={theme.RequestATourAgentInfo}>
      <AccountIcon className={theme.AccountIcon} />
      {agentName && <h4 className={theme.AgentName}>{agentName}</h4>}
      <div className={theme.AgentInfo}>
        {officeName && (
          <span className={theme.AgentInfoData}>{officeName}</span>
        )}
        {officeName && phone && <span className={theme.Divider}>•</span>}
        {phone && (
          <a
            href={`tel':${phone}`}
            style={{ color: propertyListingPhoneColor }}
            className={theme.AgentInfoData}
          >
            {formatPhoneNumber(phone)}
          </a>
        )}
      </div>
    </div>
  );
}
