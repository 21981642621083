import { SetStateAction, Dispatch, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import theme from '@client/css-modules/RequestATourForm.css';
import { useRequestATourState } from '@client/hooks/request-a-tour.hooks';
import PhoneInput from '@client/components/generic/PhoneInput';
import Checkbox from '@client/components/generic/Checkbox';
import PillButton from '@client/components/generic/PillButton';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import {
  isValidPhoneNumber,
  isValidEmailAddress,
} from '@client/utils/validations.forms';
import SunIcon from '@client/inline-svgs/sun-icon';
import SunsetIcon from '@client/inline-svgs/sunset-icon';
import { TourViewOptions } from '@client/store/types/request-a-tour';
import TextInput from '@client/components/generic/TextInput';
import { submitForm } from '@client/store/slices/request-a-tour.slice';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import {
  getPropertyAgentInfo,
  getPropertyDetailsNormalized,
} from '@client/store/selectors/property-details.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { submitAgentLeadReferralNetwork } from '@client/store/actions/find-agent.actions';
import FormError from '../generic/FormError';

type Props = {
  setCurrentView: Dispatch<SetStateAction<TourViewOptions>>;
  handleClose: () => void;
};

const RequestATourForm = ({ setCurrentView, handleClose }: Props) => {
  const dispatch = useDispatch();
  const { requestATourFormState, setRequestATourFormState } =
    useRequestATourState();
  const [isPhoneValid, setIsPhoneValid] = useState(isValidPhoneNumber(requestATourFormState.phone));
  const [isEmailValid, setIsEmailValid] = useState(isValidEmailAddress(requestATourFormState.email))
  const { pillButtonDeemphasizedBackgroundColor } = useCobrandStyles();
  const inactiveBtnStyles = {
    backgroundColor: 'transparent',
    color: pillButtonDeemphasizedBackgroundColor,
    border: `1px solid ${pillButtonDeemphasizedBackgroundColor}`,
  };

  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const isAgentReferralLeadNetworkEnabled = useSelector(
    getIsFeatureEnabled('agent_lead_referral_network')
  );
  const listingAgent = useSelector(getPropertyAgentInfo);
  const normalizedActiveProperty = useSelector(getPropertyDetailsNormalized);
  const listingAgentEmail = listingAgent?.email;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  function addInactiveStyle(stateKey, stateValue) {
    if (requestATourFormState[stateKey] !== stateValue) {
      return inactiveBtnStyles;
    } else {
      return { backgroundColor: pillButtonDeemphasizedBackgroundColor };
    }
  }

  const isPhoneOrEmailValid =
    (requestATourFormState.contactType === 'phone' &&
      isPhoneValid) ||
    (requestATourFormState.contactType === 'email' &&
      isEmailValid);
  /* Only show financing request checkbox if we have either an LO email or generic contact email to send to */
  const showFinancingContactCheckbox =
    !!requestATourFormState.financingContactEmail;

  if (!normalizedActiveProperty) {
    return null;
  }



  return (
    <div className={theme.RequestATourForm}>
      <form>
        <fieldset>
          <legend>Contact type*</legend>
          <PillButton
            deemphasized
            theme={theme}
            className={theme.OutlineButton}
            ariaLabel="Text or call"
            type="button"
            aria-pressed={requestATourFormState.contactType === 'phone'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                contactType: 'phone',
              })
            }
            style={addInactiveStyle('contactType', 'phone')}
          >
            Text or call
          </PillButton>
          <PillButton
            deemphasized
            ariaLabel="Email"
            theme={theme}
            className={theme.OutlineButton}
            type="button"
            aria-pressed={requestATourFormState.contactType === 'email'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                contactType: 'email',
              })
            }
            style={addInactiveStyle('contactType', 'email')}
          >
            Email
          </PillButton>
        </fieldset>
        <fieldset>
          {requestATourFormState.contactType === 'phone' ? (
            <PhoneInput
              theme={theme}
              label="Phone*"
              value={requestATourFormState.phone}
              onChange={(e) => {
                setIsPhoneValid(true)
                setRequestATourFormState({
                  ...requestATourFormState,
                  phone: e.target.value,
                });
              }}
              onBlur={() => {
                setIsPhoneValid(isValidPhoneNumber(requestATourFormState.phone))
              }}
              error={(
                <FormError value={requestATourFormState.phone.length === 0 ||
                  !isPhoneValid
                  ? 'Please provide a valid number'
                  : null}
                />
              )}
            />
          ) : (
            <TextInput
              theme={theme}
              label="Email*"
              value={requestATourFormState.email}
              onChange={(e) => {
                setIsEmailValid(true)
                setRequestATourFormState({
                  ...requestATourFormState,
                  email: e.target.value,
                });
              }}
              onBlur={() => {
                setIsEmailValid(isValidEmailAddress(requestATourFormState.email))
              }}
              error={(
                <FormError value={
                  !isEmailValid
                  ? 'Please provide a valid email address'
                  : null}
                />
              )}
            />
          )}
        </fieldset>
        <fieldset>
          <legend>Tour type</legend>
          <PillButton
            deemphasized
            ariaLabel="In-person"
            theme={theme}
            className={theme.OutlineButton}
            type="button"
            aria-pressed={requestATourFormState.tourType === 'in-person'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                tourType: 'in-person',
              })
            }
            style={addInactiveStyle('tourType', 'in-person')}
          >
            In-person
          </PillButton>
          <PillButton
            deemphasized
            ariaLabel="Video"
            theme={theme}
            className={theme.OutlineButton}
            type="button"
            aria-pressed={requestATourFormState.tourType === 'video'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                tourType: 'video',
              })
            }
            style={addInactiveStyle('tourType', 'video')}
          >
            Video
          </PillButton>
        </fieldset>
        <fieldset>
          <legend>Preferred time</legend>
          <PillButton
            deemphasized
            ariaLabel="Morning"
            theme={theme}
            className={theme.OutlineButton}
            type="button"
            aria-pressed={requestATourFormState.preferredTime === 'morning'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                preferredTime: 'morning',
              })
            }
            style={addInactiveStyle('preferredTime', 'morning')}
          >
            <SunIcon className={theme.SunsetIcon} /> Morning
          </PillButton>
          <PillButton
            deemphasized
            ariaLabel="Afternoon"
            theme={theme}
            className={theme.OutlineButton}
            type="button"
            aria-pressed={requestATourFormState.preferredTime === 'afternoon'}
            onClick={() =>
              setRequestATourFormState({
                ...requestATourFormState,
                preferredTime: 'afternoon',
              })
            }
            style={addInactiveStyle('preferredTime', 'afternoon')}
          >
            <SunsetIcon className={theme.SunIcon} /> Afternoon
          </PillButton>
        </fieldset>
        {showFinancingContactCheckbox && (
          <fieldset>
            <Checkbox
              data-event-name="click_request_a_tour_no_agent_financing_request"
              data-parent-event-name="click_request_a_tour"
              name="contactForFinancing"
              isChecked={requestATourFormState.contactForFinancing}
              label="Contact me about financing information"
              theme={theme}
              onClick={() =>
                setRequestATourFormState({
                  ...requestATourFormState,
                  contactForFinancing:
                    !requestATourFormState.contactForFinancing,
                })
              }
            />
          </fieldset>
        )}
        <fieldset
          style={!showFinancingContactCheckbox ? { paddingTop: '20px' } : {}}
        >
          <PillButton
            type="button"
            theme={theme}
            onClick={() => {
              setIsFormSubmitted(true);
              if (isAgentReferralLeadNetworkEnabled) {
                dispatch(
                  submitAgentLeadReferralNetwork({
                    preferred_contact_type: requestATourFormState.contactType,
                    phone: requestATourFormState.phone,
                    email: requestATourFormState.email,
                    tour_type: requestATourFormState.tourType,
                    time_of_day: requestATourFormState.preferredTime,
                    slug: normalizedActiveProperty?.slug,
                  })
                );
              } else {
                dispatch(submitForm(requestATourFormState));
              }
              dispatch(
                reportEvent(
                  'click_request_a_tour_no_agent_request_tour_button',
                  'click_request-a-tour',
                  {
                    city: normalizedActiveProperty?.city,
                    contact_for_financing:
                      requestATourFormState.contactForFinancing,
                    contact_type: requestATourFormState.contactType,
                    email: requestATourFormState.email,
                    financing_contact_email:
                      requestATourFormState.financingContactEmail,
                    phone: requestATourFormState.phone,
                    preferred_time: requestATourFormState.preferredTime,
                    tour_type: requestATourFormState.tourType,
                    slug: normalizedActiveProperty?.slug,
                    state: normalizedActiveProperty?.state,
                    street: normalizedActiveProperty?.streetAddress,
                    unit: normalizedActiveProperty?.unit,
                    zip: normalizedActiveProperty?.zipcode,
                    list_price: normalizedActiveProperty?.listPrice,
                  }
                )
              );
              if (listingAgentEmail || isReferralServicesEnabled) {
                handleClose();
                dispatch(setActiveNotification('request-a-tour-success'));
              } else {
                // If no email address and no referral services (which sends the lead to pipedrive instead of emailing the agent), show agent info
                setCurrentView('agentInfo');
              }
            }}
            ariaLabel="Request tour"
            disabled={!isPhoneOrEmailValid || isFormSubmitted}
          >
            Request tour
          </PillButton>
        </fieldset>
      </form>
    </div>
  );
};

export default RequestATourForm;
