import { MultiUnitDataObject } from '@client/store/types/maps';
import { NormalizedProperty } from '@client/store/types/property';
import {
  MAP_MARKER_OFF_MARKET_TEXT_COLOR,
  MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR,
  MARKER_IMAGE_IDS,
} from '@client/store/map-constants';
import {
  getMultiUnitClusterKey,
  buildMultiUnitClusterMarker,
  buildMultiUnitDataObject,
  buildPropertyMarker,
} from '@client/utils/maps.utils';
import { abbrNumberFormatter } from '@client/utils/string.utils';
import { filterNullValues } from '@client/utils/array.utils';
import { normalizePropertyData } from '@client/utils/property.utils';

export function getHomesDataMap(
  homesData: ReturnType<typeof normalizePropertyData>[]
) {
  const homesDataMap: {
    [slug: string]: ReturnType<typeof normalizePropertyData>;
  } = {};
  homesData.forEach((home) => {
    if (home.slug) {
      homesDataMap[home.slug] = home;
    }
  });

  return homesDataMap;
}

type CompsGroupedByLatLng = {
  [key: string]: NormalizedProperty[];
};

export function groupPropertiesByLatLng(
  properties: NormalizedProperty[]
): CompsGroupedByLatLng {
  const data: CompsGroupedByLatLng = {};
  properties.forEach((property) => {
    const { latitude, longitude } = property;
    const key = getMultiUnitClusterKey({ latitude, longitude });
    if (!data[key]) {
      data[key] = [];
    }
    data[key].push(property);
  });
  return data;
}

type ClusterMarker = {
  multiUnitClusterLocation: {
    latitude: number;
    longitude: number;
  };
  multiUnitCount: number;
  childAddressSlugs: string[];
};
type CompMarkerProperty = ClusterMarker | NormalizedProperty;

const isClusterMarker = (property): property is ClusterMarker =>
  !!(property as ClusterMarker).multiUnitClusterLocation;

export function pullMarkerFeaturesFromPropertyData(
  properties: NormalizedProperty[],
  isActiveListing?: boolean
) {
  const propertiesGroupedByLatLng = groupPropertiesByLatLng(properties);

  if (Object.keys(propertiesGroupedByLatLng).length === 0) {
    return [];
  }
  let propertiesIncludingClusters: CompMarkerProperty[] = [];
  /* Collect multi-unit properties in an object keyed by latLng */
  const propKeys = Object.keys(propertiesGroupedByLatLng);
  if (propKeys.length > 0) {
    propKeys.forEach((latLng) => {
      if (
        propertiesGroupedByLatLng[latLng].length > 1 &&
        propertiesGroupedByLatLng[latLng][0].latitude &&
        propertiesGroupedByLatLng[latLng][0].longitude
      ) {
        propertiesIncludingClusters.push({
          multiUnitClusterLocation: {
            latitude: propertiesGroupedByLatLng[latLng][0]?.latitude!,
            longitude: propertiesGroupedByLatLng[latLng][0]?.longitude!,
          },
          multiUnitCount: propertiesGroupedByLatLng[latLng]?.length,
          childAddressSlugs: propertiesGroupedByLatLng[latLng]
            .map((item) => item?.slug)
            .filter((item) => item),
        });
      } else {
        propertiesIncludingClusters.push(propertiesGroupedByLatLng[latLng][0]);
      }
    });
  }

  return Object.values(propertiesIncludingClusters)
    .map((item) => {
      const marker = isClusterMarker(item)
        ? buildMultiUnitClusterMarker({
            /* All comps are supposed to be off-market so hardcoding the multi-unit marker color */
            imageId: isActiveListing
              ? MARKER_IMAGE_IDS.ON_MARKET
              : MARKER_IMAGE_IDS.OFF_MARKET,
            labelColor: isActiveListing
              ? MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR
              : MAP_MARKER_OFF_MARKET_TEXT_COLOR,
            latitude: item?.multiUnitClusterLocation.latitude,
            longitude: item?.multiUnitClusterLocation.longitude,
            label: `${item?.multiUnitCount} Units`,
            propertyCount: item?.multiUnitCount,
            childAddressSlugs: item?.childAddressSlugs,
          })
        : buildPropertyMarker({
            addressSlug: item?.slug!,
            latitude: item?.latitude || null,
            longitude: item?.longitude || null,
            label: abbrNumberFormatter(
              isActiveListing ? item?.listPrice : item?.salePrice
            ),
            labelColor: isActiveListing
              ? MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR
              : MAP_MARKER_OFF_MARKET_TEXT_COLOR,
            imageId: isActiveListing
              ? MARKER_IMAGE_IDS.ON_MARKET
              : MARKER_IMAGE_IDS.OFF_MARKET,
            buildingId: null,
            normalizedPropertyData: item,
          });

      return marker
        ? {
            type: 'Feature' as 'Feature',
            properties: marker,
            geometry: {
              type: 'Point' as 'Point',
              coordinates: [marker.lng, marker.lat] as [number, number],
            },
          }
        : null;
    })
    .filter(filterNullValues);
}

const MULTI_UNIT_MAX_PROPERTY_COUNT = 100;

export function groupMultiUnitMarkersByLatLng(comps) {
  const propertiesGroupedByLatLng = groupPropertiesByLatLng(comps);
  let multiUnitMarkersByLatLng = {} as {
    [key: string]: MultiUnitDataObject[];
  };
  if (Object.keys(propertiesGroupedByLatLng).length > 0) {
    /* Collect multi-unit properties in an object keyed by latLng to set on state */
    Object.keys(propertiesGroupedByLatLng).forEach((latLng) => {
      /* If multiple properties exist at the lat/lng, build multi-unit marker */
      if (propertiesGroupedByLatLng[latLng].length > 1) {
        /* Add to collection for later property retrieval, limiting number to defined maximum */
        multiUnitMarkersByLatLng[latLng] = propertiesGroupedByLatLng[latLng]
          .slice(0, MULTI_UNIT_MAX_PROPERTY_COUNT)
          .map((item) => {
            return buildMultiUnitDataObject({
              addressSlug: item?.slug,
              latitude: item?.latitude,
              longitude: item?.longitude,
              normalizedPropertyData: item,
              shouldNotBeSetOnState: false,
            });
          })
          .filter((item): item is NonNullable<typeof item> => !!item);
      }
    });
  }

  return multiUnitMarkersByLatLng;
}
