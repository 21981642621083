import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  getIsLoggedIn,
  getUserEmailAddress,
  getUserFirstName,
  getUserLastName,
  getUserPhoneNumber,
} from '@client/store/selectors/auth.selectors';
import Checkmark from '@client/inline-svgs/checkmark';
import PillButton from '@client/components/generic/PillButton';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import {
  FormValuesTypes,
  contactLoanOfficer,
} from '@client/store/actions/loan-officer.actions';
import FormModal, {
  FieldValuesByUId,
} from '@client/components/generic/FormModal';
import TextArea from '@client/components/generic/TextArea';
import TextInput, { TextInputProps } from '@client/components/generic/TextInput';
import EmailField from '@client/components/generic/EmailField';
import {
  HOME_BUYER_ROUTES,
  HOMEOWNER_ROUTES,
  View,
} from '@client/routes/constants';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { closeModal } from '@client/store/actions/modals.actions';
import SignUpOrLoginLink from '@client/components/SignUpOrLoginLink';
import FormModalTopPhotoSection from '@client/components/generic/FormModalTopPhotoSection';
import {
  getContactLoanOfficerError,
  getLoanOfficerInfo,
} from '@client/store/selectors/loan-officer.selectors';
import { getGenericLOInfo } from '@client/store/selectors/cobranding.selectors';
import theme from '@client/css-modules/ContactLoanOfficerModal.css';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { getDataAttrEventNameForDOMEventTarget } from '@client/utils/dom.utils';
import { getPropertyAddress } from '@client/store/selectors/property-details.selectors';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { getPropertyAddress as getHomeownerPropertyAddress } from '@client/store/selectors/homeowner.selectors';
import { authModalShow } from '@client/store/actions/auth.actions';
import { AuthModalPage } from '@client/store/types/auth';
import { getIDSelectorByView } from '@client/utils/analytics.utils';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { getIsRequiredValidator, getIsNotRequiredValidator, phoneValidator, fieldLengthValidator } from '@client/services/validator';
import { stripOutNonNumber } from '@client/utils/string.utils';
import PhoneInput from '@client/components/generic/PhoneInput';
import ValidatorInput, { ValidatorProps } from '@client/components/generic/ValidatorInput';

const ValidatedTextInput = ValidatorInput<string>(TextInput);
const TextField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedTextInput
    dataHcName={'text-control'}
    validate={[getIsRequiredValidator()]}
    {...props}
  />
);

const ValidatedPhoneInput = ValidatorInput<string>(PhoneInput);
const PhoneField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedPhoneInput
    dataHcName={'phone-control'}
    validate={[getIsNotRequiredValidator(phoneValidator, stripOutNonNumber)]}
    {...props}
  />
);

const ValidatedTextAreaInput = ValidatorInput<string>(TextArea);
const TextAreaField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedTextAreaInput
    dataHcName={'textarea-control'}
    validate={[getIsNotRequiredValidator((value) =>fieldLengthValidator(value, 500, "message"))]}
    {...props}
  />
);

export const ContactLoanOfficerModal: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const loanOfficer = useSelector(getLoanOfficerInfo);
  const genericLOInfo = useSelector(getGenericLOInfo);
  const contactLoanOfficerError = useSelector(getContactLoanOfficerError);
  const userEmail = useSelector(getUserEmailAddress);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userPhone = useSelector(getUserPhoneNumber);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const currentView = useSelector(getCurrentView) as View;
  const activeModalKey = useSelector(getActiveModalKey);
  const isHomeownerView = HOMEOWNER_ROUTES.includes(currentView);
  const isHomebuyerView = HOME_BUYER_ROUTES.includes(currentView);
  const homeownerProperty = useSelector(getHomeownerPropertyAddress);
  const homebuyerProperty = useSelector(getPropertyAddress);
  const source = useSelector(getCurrentView);
  const sourceId = useSelector(getIDSelectorByView(source));
  const property = isHomeownerView ? homeownerProperty : homebuyerProperty;

  const showAuthModal = (key: AuthModalPage) => {
    dispatch(authModalShow({ startingPage: key }));
  };

  const handleSignUpClick = () => {
    if (isHomebuyerView) {
      dispatch(reportEvent('click_buyer_lo_modal_success_sign_up'));
    } else if (isHomeownerView) {
      dispatch(reportEvent('click_owner_lo_modal_success_sign_up'));
    }

    dispatch(closeModal());
    showAuthModal(AUTH_MODAL_PAGES.SIGN_UP);
  };
  const handleContactLoanOfficer = (fieldValues: FieldValuesByUId) => {
    dispatch(contactLoanOfficer(fieldValues as FormValuesTypes));
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const successText = loanOfficer ? "I'll be in touch!" : "We'll be in touch!";
  const loanOfficerFullName = loanOfficer?.fullName || null;

  /**
   * Report any click event where a `data-event-name` is defined on a DOM node or parent DOM node,
   * adding context about the current property. Since this is a modal, it is outside of the PDP click
   * handlers, so we need to do this manually.
   */
  const handleReportEvent = (eventName, parentEvent, options, topLevelItems) =>
    dispatch(reportEvent(eventName, parentEvent, options, topLevelItems));
  const handleDataAttrEventReporting = (
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    const { eventName, parentEventName, eventData } =
      getDataAttrEventNameForDOMEventTarget(e);
    const topLevelItems =
      source && sourceId ? { source, source_id: sourceId } : undefined;

    if (eventName && property) {
      const propertyAddress: AnalyticsEventAddress = {
        slug: property.slug,
        unit: property.unit ?? null,
        street: property.streetAddress,
        city: property.city,
        state: property.state,
        zip: property.zipcode,
        address_id: property.hcAddressId,
      };
      handleReportEvent(
        eventName,
        parentEventName,
        {
          ...propertyAddress,
          ...eventData,
        },
        topLevelItems
      );
    } else if (eventName) {
      handleReportEvent(eventName, parentEventName, eventData, topLevelItems);
    }
  };

  const FormPageTopContent = (
    <FormModalTopPhotoSection
      fullName={loanOfficerFullName}
      mortgageCompany={loanOfficer?.company ?? genericLOInfo?.name}
      photo={loanOfficer?.photoUrl}
      Heading={
        loanOfficer
          ? 'Leave your contact info. I will return your message soon.'
          : 'Leave your contact info. We will return your message soon.'
      }
    />
  );

  const SuccessPageContent = (linkColor: string) => (
    <>
      <FormModalTopPhotoSection
        fullName={loanOfficerFullName}
        mortgageCompany={loanOfficer?.company ?? genericLOInfo?.name}
        photo={loanOfficer?.photoUrl}
        Heading={
          loanOfficer?.photoUrl ? (
            <div>
              Thank you, <br />
              {successText}
            </div>
          ) : (
            `Thank you, ${successText}`
          )
        }
      />
      <PillButton
        ariaLabel="Close modal"
        onClick={handleCloseModal}
        onKeyDown={onEnterOrSpaceKey(handleCloseModal)}
        theme={theme}
      >
        <Checkmark className={theme.Checkmark} />
      </PillButton>
      {!isLoggedIn && (
        <SignUpOrLoginLink
          linkType="signup"
          linkQuestionText="Don’t have an account?"
          theme={theme}
          labelStyle={{
            color: linkColor,
            textDecoration: 'underline',
            textDecorationColor: linkColor,
          }}
          handleClick={handleSignUpClick}
        />
      )}
      <button
        role="link"
        className={theme.BackLink}
        style={{ color: `${linkColor}` }}
        onClick={handleCloseModal}
        onKeyDown={onEnterOrSpaceKey(handleCloseModal)}
      >
        Back to Property Page
      </button>
    </>
  );
  const ch_property_id = property?.slug || null;
  const handleReportClickOwnerLOModalCTA = () =>
    dispatch(
      reportEvent('click_owner_lo_modal_cta', PARENT_EVENTS.CLICK_LO_CONTACT, {
        ch_property_id,
      })
    );
  const handleReportClickBuyerLOModalCTA = () =>
    dispatch(
      reportEvent('click_buyer_lo_modal_cta', PARENT_EVENTS.CLICK_LO_CONTACT)
    );

  const { linkColor } = useCobrandStyles();

  return (
    <FormModal
      onClick={handleDataAttrEventReporting}
      dataHcName={'contact-loan-officer'}
      isActive={activeModalKey === 'contact-loan-officer'}
      FormPageTopContent={FormPageTopContent}
      fields={[
        {
          uId: 'firstName',
          component: TextField,
          label: 'First Name*',
          required: true,
          customErrorMessage: "Please enter your first name",
          initialValue: userFirstName,
          'data-event-name': isHomeownerView
            ? EVENTS.CLICK_OWNER_LO_MODAL_FIRST_NAME
            : 'click_buyer_lo_modal_first_name',
          'data-parent-event-name': PARENT_EVENTS.CLICK_LO_CONTACT,
          'data-hc-name': 'first-name-field', 

        },
        {
          uId: 'lastName',
          component: TextField,
          label: 'Last Name*',
          required: true,
          customErrorMessage: "Please enter your last name",
          initialValue: userLastName,
          'data-event-name': isHomeownerView
            ? EVENTS.CLICK_OWNER_LO_MODAL_LAST_NAME
            : 'click_buyer_lo_modal_last_name',
          'data-parent-event-name': PARENT_EVENTS.CLICK_LO_CONTACT,
          'data-hc-name': 'last-name-field',
        },
        {
          uId: 'email',
          component: EmailField,
          label: 'Email*',
          required: true,
          customErrorMessage: "Please enter a valid email address",
          initialValue: userEmail,
          'data-event-name': isHomeownerView
            ? EVENTS.CLICK_OWNER_LO_MODAL_EMAIL_ADDRESS
            : 'click_buyer_lo_modal_email_address',
          'data-parent-event-name': PARENT_EVENTS.CLICK_LO_CONTACT,
          'data-hc-name': 'email-field',
        },
        {
          uId: 'phone',
          component: PhoneField,
          label: 'Phone number*',
          required: true,
          customErrorMessage: "Please enter a valid phone number",
          initialValue: userPhone,
          'data-event-name': isHomeownerView
            ? EVENTS.CLICK_OWNER_LO_MODAL_PHONE_NUMBER
            : 'click_buyer_lo_modal_phone_number',
          'data-parent-event-name': PARENT_EVENTS.CLICK_LO_CONTACT,
          'data-hc-name': 'phone-field',
        },
        {
          uId: 'message',
          component: TextAreaField,
          label: 'Leave a message (optional)',
          required: false,
          'data-event-name': isHomeownerView
            ? EVENTS.CLICK_OWNER_LO_MODAL_MESSAGE
            : 'click_buyer_lo_modal_message',
          'data-parent-event-name': PARENT_EVENTS.CLICK_LO_CONTACT,
          'data-hc-name': 'message-field',
        },
      ]}
      SuccessPageContent={SuccessPageContent(linkColor)}
      handleSubmit={handleContactLoanOfficer}
      handleClose={handleCloseModal}
      modalAriaLabel={'Contact a Loan Officer'}
      submitButtonAriaLabel={'Send contact info'}
      APIErrorMessage={contactLoanOfficerError}
      analyticsEventsMisc={{
        submitFormButton: isHomebuyerView
          ? handleReportClickBuyerLOModalCTA
          : isHomeownerView
          ? handleReportClickOwnerLOModalCTA
          : undefined,
      }}
    />
  );
};

export default ContactLoanOfficerModal;
