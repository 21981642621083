import LearnMoreAside from '@client/components/LearnMoreAside';
import Tooltip from '@client/components/generic/Tooltip';
import AvmBreakdownToolTipText from '@client/components/AvmBreakdownToolTipText';
import { AvmDefinitionProps } from '@client/store/types/cobranded-components/avm-definition';
import defaultTheme from '@client/css-modules/AvmDefinition.css';
import { themr } from '@friendsofreactjs/react-css-themr';

const LearnMore = () => {
  return <div>Learn More</div>;
};

const AvmDefinition: React.FC<AvmDefinitionProps> = ({
  theme,
  toolTipLabelStyle,
  initialShiftAmount,
  maxWidth,
  tooltipPosition,
}) => {
  return (
    <div className={theme.HcAvmExplanation}>
      *Based on your estimated home value
      <LearnMoreAside theme={theme} style={toolTipLabelStyle}>
        {() => (
          <Tooltip
            dataHcName="avm-definition-tooltip"
            theme={theme}
            trigger={<LearnMore />}
            content={<AvmBreakdownToolTipText />}
            position={tooltipPosition}
            initialShiftAmount={initialShiftAmount}
            maxWidth={maxWidth}
            triggerAriaLabel={
              'Learn more about the ComeHome estimated market value'
            }
          />
        )}
      </LearnMoreAside>
    </div>
  );
};
const ThemedAvmDefinition = themr('AvmDefinition', defaultTheme)(AvmDefinition);
export default ThemedAvmDefinition;
