import { createSelector } from 'reselect';
import { UserProfileState } from '@client/store/reducers/auth.reducer';
import { ReduxState } from '@client/store/types/redux-state';
import { isErrorObj } from '@client/store/types/auth';

export function getUserProfileState(state: ReduxState): UserProfileState {
  return state.userProfile;
}

export const getUserProfileStatus = createSelector(
  getUserProfileState,
  (userProfileState) => userProfileState.status
);

export const getUserEmailUpdateStatus = createSelector(
  getUserProfileState,
  (userProfileState) => userProfileState.emailUpdateStatus
);

export const getUserEmailUpdateModalStatus = createSelector(
  getUserProfileState,
  (userProfileState) => userProfileState.emailUpdateModalIsOpen
);

export const getUserProfileError = createSelector(
  getUserProfileState,
  (userProfileState) =>
    isErrorObj(userProfileState.errorMessage)
      ? userProfileState.errorMessage.messageRaw
      : userProfileState.errorMessage
);
