import classNames from 'classnames';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';

import theme from '@client/css-modules/HOHomeSalesNullState.css';
import { getIsOneColumnSize } from '@client/store/selectors/match-media.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const HomeSalesNullState = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/inline-svgs/home-sales-null-state'
    )
);

export default function HOHomeSalesNullState() {
  const { homeownerModalHeaderBackgroundColor } = useCobrandStyles();
  const isOneColumnSize = useSelector(getIsOneColumnSize);

  return (
    <div className={theme.HOHomeSalesNullState}>
      <div
        className={classNames(theme.NullStateText, {
          [theme.NullStateTextOneColumnSize]: isOneColumnSize,
        })}
      >
        You live in a great neighborhood!
        <br />
        So great that no one is moving.
      </div>
      <HomeSalesNullState
        style={{
          color: homeownerModalHeaderBackgroundColor,
        }}
        className={classNames(theme.NullStateImage, {
          [theme.NullStateImageOneColumnSize]: isOneColumnSize,
        })}
      />
    </div>
  );
}
