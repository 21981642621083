import { useDispatch, useSelector } from 'react-redux';

import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getUserPartnerUserId } from '@client/store/selectors/auth.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import {
  addPartnerPropertyAndUserIdToUrl,
  getTargetView,
} from '@client/utils/routing.utils';
import { View, HOME_BUYER_ROUTES } from '@client/routes/constants';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';

const getIsInternalUrl = (ctaLinkFromParcon?: string) => {
  const targetView = getTargetView(ctaLinkFromParcon);
  if (targetView && HOME_BUYER_ROUTES.includes(targetView)) {
    if (
      (ctaLinkFromParcon && ctaLinkFromParcon.indexOf('.comehome.com') >= 0) ||
      (ctaLinkFromParcon && ctaLinkFromParcon.indexOf('.housecanary.net') >= 0)
    ) {
      return true;
    }
  }
  return false;
};

export function useFullLinkStr() {
  const { loCtaUrl } = useLoInfoProps();
  const customizationData = useSelector(getCustomizationData);
  const propertyId = useSelector(getActivePDPSlug);
  const partnerUserId = useSelector(getUserPartnerUserId);

  return loCtaUrl
    ? addPartnerPropertyAndUserIdToUrl(
        loCtaUrl,
        customizationData,
        propertyId,
        partnerUserId
      )
    : null;
}

export function useMainCTAOnClick(reportAnalytics: () => void) {
  const dispatch = useDispatch();
  const { loCtaUrl } = useLoInfoProps();
  const handleRouteChange = (targetView: View) => {
    dispatch(routeChange({ view: targetView }));
  };
  const fullLinkStr = useFullLinkStr();

  return (): void => {
    if (loCtaUrl !== null) {
      const targetView = getTargetView(loCtaUrl);
      const isInternalUrl = getIsInternalUrl(loCtaUrl);
      reportAnalytics();

      if (targetView && isInternalUrl) {
        handleRouteChange(targetView);
      } else if (loCtaUrl) {
        fullLinkStr && window.open(fullLinkStr, '_blank');
      }
    }
  };
}
