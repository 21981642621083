import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentRoute } from '@src/redux-saga-router-plus/selectors';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { LngLatBounds } from 'mapbox-gl';

import { reportEvent } from '@client/store/actions/analytics.actions';
import { openModal } from '@client/store/actions/modals.actions';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import { getIsOneColumnSize } from '@client/store/selectors/match-media.selectors';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';
import { LayerMetric } from '@client/store/map-constants';

export function useHomeSaleClickHandlers() {
  const dispatch = useDispatch();
  const currentRoute = useSelector(getCurrentRoute);

  function handleClickSeeAll() {
    dispatch(reportEvent('click_homeowner_home_sales_see_all'));
    dispatch(openModal('homeowner-home-sales-see-all'));
  }
  function handleClickMapView() {
    dispatch(reportEvent('click_homeowner_home_sales_map_view'));
    dispatch(openModal('homeowner-home-sales-map-view'));
  }
  function handleClickBack() {
    dispatch(reportEvent('click_owner_sales_card_back'));
    /* wipe out query to exit since toggling between see-all and map-view will set prev query */
    dispatch(
      routeChange({
        ...currentRoute,
        view: currentRoute.view!,
        query: {},
      })
    );
  }

  return { handleClickSeeAll, handleClickMapView, handleClickBack };
}

export function useHomeSalesModalStatus() {
  const activeModalKey = useSelector(getActiveModalKey);
  const isOneColumnSize = useSelector(getIsOneColumnSize);
  const isShowingSeeAll = activeModalKey === 'homeowner-home-sales-see-all';
  const isShowingMapView = activeModalKey === 'homeowner-home-sales-map-view';
  const isModalActive =
    (isShowingSeeAll || isShowingMapView) && isOneColumnSize;

  return { isModalActive, isShowingSeeAll, isShowingMapView };
}

export function useHomeSalesMapLayerState() {
  const [
    isShowingMapBottomLayerGroupsList,
    handleToggleMapBottomLayerGroupsList,
  ] = useState(false);
  const dispatch = useDispatch();

  function handleGetMapLayerLegendBreaks(
    bounds: LngLatBounds,
    zoom: number,
    metric?: LayerMetric
  ) {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(fetchMapLegendBreaks({ southWest, northEast, zoom, metric }));
  }

  return {
    handleGetMapLayerLegendBreaks,
    handleToggleMapBottomLayerGroupsList,
    isShowingMapBottomLayerGroupsList,
  };
}

type ListingStatus = 'home-sold' | 'active-listing';

export function useListingStatusSelector() {
  const [selectedListingStatus, setSelectedListingStatus] =
    useState<ListingStatus>('home-sold');

  function onListingStatusChange(status: ListingStatus) {
    setSelectedListingStatus(status);
  }

  return { selectedListingStatus, onListingStatusChange };
}
