import React from 'react';

import ComeHomeLogoPoweredByWhite from '@client/assets/images/come-home-logo-powered-by-white.svg';
import theme from '@client/css-modules/BrokerageModal.css';
import CobrandedStyles from '@client/components/CobrandedStyles';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import HC_CONSTANTS from '@client/app.config';
const { HC_BROKERAGE_INFO_URL } = HC_CONSTANTS;

type Props = {
  handleClose: () => void;
  isActive: boolean;
  cobrandName: string;
};

const BrokerageModal: React.FC<Props> = ({ handleClose, isActive }) => {
  return (
    <CobrandedStyles>
      {({ propertyBrokerageLinkColor }) => (
        <SlideInModalContainer
          className={theme.BrokerageModal}
          theme={theme}
          isActive={isActive}
          modalAriaLabel="brokerage modal"
          handleClose={handleClose}
        >
          <div>
            <div className={theme.Header}>
              <img
                alt="comehome logo"
                className={theme.ComeHomeLogo}
                src={ComeHomeLogoPoweredByWhite}
              />
            </div>
            <div className={theme.TextContent}>
              <p className={theme.TopContent}>
                HouseCanary, Inc. is a Licensed Real Estate Brokerage in KS, NM,
                SC and under the Trade Name ComeHome in AL, AK, AZ, CA, CO, CT,
                DC, DE, FL, GA, HI, IA, ID, IL, IN, KY, LA, MA, MD, ME, MO, MN,
                MS, MT, NC, ND, NE, NH, NJ, NV, NY, OH, OK, OR, PA, RI, SD, TN,
                TX, VA, VT, WA, WI, WV, WY.
              </p>
              <p className={theme.BottomContent}>
                Trade Name ComeHome Real Estate in MI and UT.
              </p>
              <p className={theme.BottomContent}>
                Trade Name ComeHome by HouseCanary in AR.
              </p>

              <p className={theme.BottomContent}>
                HouseCanary, Inc.,
                <a
                  className={theme.Link}
                  style={{
                    color: propertyBrokerageLinkColor ?? '#0B6EFD',
                  }}
                  target="_blank"
                  href={HC_BROKERAGE_INFO_URL}
                >
                  brokerage information
                </a>
              </p>
              <p className={theme.BottomContent}>
                If you are currently working with a real estate agent, this is
                not meant as a solicitation of your business.
              </p>
              <p className={theme.BottomContent}>
                AVM Disclosure: This represents an estimate sale price for this
                property. It is not the same as the opinion of value in an
                appraisal developed by a licensed appraiser under the Uniform
                Standards of Professional Appraisal Practice.
              </p>
            </div>
          </div>
        </SlideInModalContainer>
      )}
    </CobrandedStyles>
  );
};

export default BrokerageModal;
