import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserEmailAddress } from '@client/store/selectors/auth.selectors';
import { getConfirmUserIsShowingResendSuccessModal } from '@client/store/selectors/confirm-user.selectors';
import { closeResendConfirmEmailSuccessModal } from '@client/store/actions/auth.actions';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import theme from '@client/css-modules/ResendConfirmEmailSuccessModal.css';
import EmailIconCobranded from '@client/components/EmailIcon/EmailIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import EmailUpdateSuccessCheckMarkIconCobranded from '@client/components/EmailUpdateSuccessCheckMarkIcon/EmailUpdateSuccessCheckMarkIconCobranded';

const ResendConfirmEmailSuccessModal: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(getConfirmUserIsShowingResendSuccessModal);
  const userEmailAddress = useSelector(getUserEmailAddress);
  const handleModalClose = (
    e: React.MouseEvent | React.KeyboardEvent
  ): void => {
    dispatch(closeResendConfirmEmailSuccessModal());
  };
  const { dismissableBannerBackground, dismissableBannerTextColor } =
    useCobrandStyles();

  return (
    <SlideInModalContainer
      className={theme.ResendConfirmEmailSuccessModal}
      dataHcName={'resend-confirm-email-success-modal'}
      theme={theme}
      modalAriaLabel={'Update email address'}
      isActive={isActive}
      handleClose={handleModalClose}
    >
      <>
        <div
          className={theme.Header}
          style={{
            background: dismissableBannerBackground,
            color: dismissableBannerTextColor,
          }}
        >
          <div className={theme.Title}>
            <EmailIconCobranded className={theme.EmailIcon} />
            <div className={theme.TitleText}>Sent confirmation</div>
          </div>
        </div>
        <div className={theme.Body}>
          <div className={theme.UpdateEmailSuccessText}>
            We've resent the confirmation to {userEmailAddress}.
          </div>
          <div className={theme.UpdateEmailSuccessText}>Check your inbox.</div>
          <EmailUpdateSuccessCheckMarkIconCobranded
            className={theme.EmailUpdateSuccessCheckMarkIcon}
          />
        </div>
      </>
    </SlideInModalContainer>
  );
};

export default ResendConfirmEmailSuccessModal;
