import { gql, useQuery } from 'urql';
import { useSelector } from 'react-redux';

import {
  getHomeownerPropertyLocation,
  getActivePropertyZipCode,
} from '@client/store/selectors/homeowner.selectors';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import {
  normalizePropertyData,
  isPropertyDataValid,
} from '@client/utils/property.utils';
import {
  getIsSpatialSearchDetailWithAddress,
  SpatialSearchDetailWithAddressRequired,
} from '@client/store/types/search';
import { WithAddressAndGeoRequired } from '@client/store/types/property';
import { getIsOneColumnSize } from '@client/store/selectors/match-media.selectors';
import {
  getHomesDataMap,
  groupMultiUnitMarkersByLatLng,
  pullMarkerFeaturesFromPropertyData,
} from '@client/utils/home-sales.utils';
import {
  HomeownerPlaceSearchQuery,
  HomeownerPlaceSearchQueryVariables,
} from '@client/hooks/__generated__/homeowner-place-search-query';
import {
  HomeownerSpatialSearchQuery,
  HomeownerSpatialSearchQueryVariables,
} from '@client/hooks/__generated__/homeowner-spatial-search-query';
import {
  HomeownerCompsQuery,
  HomeownerCompsQueryVariables,
} from '@client/hooks/__generated__/homeowner-comps-query';

export const homeownerCompsQuery = gql<
  HomeownerCompsQuery,
  HomeownerCompsQueryVariables
>`
  query HomeownerCompsQuery($slug: String) {
    propertyLookup(id: { slug: $slug }) {
      comps(limit: 8, minScore: 60, maxAgeDays: 182, includeActive: false) {
        address {
          slug
          fullAddress
          hcAddressId
          streetAddress
          unit
          city
          state
          zipcode
        }
        avm(minQuality: MEDIUM, qualityMethod: CONSUMER) {
          priceMean
        }
        bestPhotos(limit: 1) {
          id
          storageUrl
          prediction
          confidence
          representation {
            httpUrl
            height
            width
          }
        }
        geoLocation {
          latitude
          longitude
        }
        mls {
          regulations {
            logoOverlay
            photosLogoOverlay
          }
        }
        paymentEstimate {
          total
        }
        propertyType
        summary {
          beds
          baths
          hcBuildingId
          listPrice
          mlsState
          saleDate
          salePrice
          score {
            default {
              similarity
            }
          }
          sqft
        }
      }
    }
  }
`;

export const placeSearchQuery = gql<
  HomeownerPlaceSearchQuery,
  HomeownerPlaceSearchQueryVariables
>`
  query HomeownerPlaceSearchQuery($zipCode: String) {
    placeSearch(
      id: { term: $zipCode, placeType: "REGIONS", addressLookupType: "LOOKUP" }
    ) {
      places {
        placeId
      }
    }
  }
`;

export const homeownerSpatialSearchQuery = gql<
  HomeownerSpatialSearchQuery,
  HomeownerSpatialSearchQueryVariables
>`
  query HomeownerSpatialSearchQuery($placeId: String) {
    properties: propertySpatialSearch(
      id: { place: { placeId: $placeId } }
      limit: 40
      minGeoPrecision: PARCEL
      propertyType: ["SFR", "TOWNHOUSE", "CONDO", "COOP"]
      sort: { field: SALE_DATE, order: DESCENDING }
      mlsState: ["ACTIVE"]
    ) {
      ... on SpatialSearchDetailResults {
        suppressedCount
        cursor
        atEnd
        hits {
          property {
            address {
              slug
              fullAddress
              hcAddressId
              streetAddress
              unit
              city
              state
              zipcode
            }
            paymentEstimate {
              total
            }
            mls {
              regulations {
                logoOverlay
                photosLogoOverlay
              }
            }
          }
          summary {
            avm {
              priceMean
            }
            beds
            baths {
              summaryCount
            }
            sqft
            salePrice
            listPrice
            saleDate
            propertyType
            geoLocation {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

export function useHomeSalesPropertyData() {
  const slug = useSelector(getActiveSlugForHomeownerAndSubpages);
  const currentPropertyLocation = useSelector(getHomeownerPropertyLocation);
  const currentZip = useSelector(getActivePropertyZipCode);

  const [compSalesResult] = useQuery({
    query: homeownerCompsQuery,
    variables: {
      slug,
    },
    pause: !slug,
  });

  const [placeSearchResult] = useQuery({
    query: placeSearchQuery,
    variables: {
      zipCode: currentZip,
    },
    pause: !currentZip,
  });
  const placeId = placeSearchResult?.data?.placeSearch?.places?.[0]?.placeId;

  const [activeListingsResult] = useQuery({
    query: homeownerSpatialSearchQuery,
    variables: {
      placeId,
    },
    pause: !placeId,
  });

  const comps = compSalesResult?.data?.propertyLookup?.comps ?? [];
  const validComps = comps.filter(
    (comp) => isPropertyDataValid(comp) && comp.summary
  ) as WithAddressAndGeoRequired<typeof comps[0]>[];
  const soldHomes = validComps.map((comp) => {
    return normalizePropertyData(comp, comp.summary!);
  });
  const soldHomesMap = getHomesDataMap(soldHomes) || {};
  const mostSimilarCompSlug = validComps.sort(
    (prop) => prop?.summary?.score?.default?.similarity || 0
  )?.[0]?.address?.slug;

  const mostSimilarComp = mostSimilarCompSlug
    ? soldHomesMap[mostSimilarCompSlug]
    : null;

  const activeListingsData = activeListingsResult?.data?.properties?.hits ?? [];
  const activeListingHomes = activeListingsData
    .filter((listing) => getIsSpatialSearchDetailWithAddress(listing))
    .map((listing) => {
      const verifiedListing = listing as SpatialSearchDetailWithAddressRequired;
      const propertyWithGeo = {
        ...verifiedListing.property,
        geoLocation: verifiedListing.summary.geoLocation,
      };
      return normalizePropertyData(propertyWithGeo, verifiedListing.summary);
    });

  return {
    activeListingHomes,
    activeListingHomesMap: getHomesDataMap(activeListingHomes),
    bestMatchedSoldHome: mostSimilarComp,
    soldHomes,
    soldHomesMap,
    currentPropertyLocation,
    isFetchComplete:
      !!(
        !compSalesResult.fetching &&
        compSalesResult.data &&
        !activeListingsResult.fetching &&
        activeListingsResult.data
      ) || !!(compSalesResult.error && activeListingsResult.error),
  };
}

export function useHomeSalesMapData(isActiveListing?: boolean) {
  const {
    soldHomes,
    activeListingHomes,
    isFetchComplete,
    bestMatchedSoldHome,
  } = useHomeSalesPropertyData();

  const homesList = isActiveListing ? activeListingHomes : soldHomes;
  const markerFeatures = pullMarkerFeaturesFromPropertyData(
    homesList,
    isActiveListing
  );
  const bestMatchedCompMarkerFeature = bestMatchedSoldHome
    ? pullMarkerFeaturesFromPropertyData([bestMatchedSoldHome])[0]
    : null;

  return {
    mapGeoRequestAccessToken: useSelector(getAccessToken),
    multiUnitMarkersByLatLng: groupMultiUnitMarkersByLatLng(homesList),
    shouldShowBigMapLayout: !useSelector(getIsOneColumnSize),
    markerFeatures,
    isFetchComplete,
    bestMatchedCompMarkerFeature,
  };
}
