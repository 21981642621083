import NullStateImage from '@client/assets/images/recent-user-activity-null-state.svg';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { View } from '@client/routes/constants';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledSection = styled.section`
  margin-top: 40%;
  text-align: center;
`;
const StyledHeader = styled.h3`
  font-weight: 800;
  font-size: 16px;
`;
const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  padding: 0 15px;
`;
const StyledButton = styled.button<{ $color: string }>`
  color: ${(props) => props.$color};
  font-weight: 400;
  font-size: 14px;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0px;
`;

export default function RecentNullState() {
  const dispatch = useDispatch();
  const { linkColor } = useCobrandStyles();
  return (
    <StyledSection
      aria-label="Recent user activity no content"
      data-hc-name="recent-user-activity-null-state"
    >
      <img alt="Recent user activity no content" src={NullStateImage} />
      <StyledHeader>No Recent Activity</StyledHeader>
      <StyledDescription>
        Searches and viewed homes will appear in your activity feed.
      </StyledDescription>
      <StyledButton
        $color={linkColor}
        aria-label="Start your first search"
        data-hc-name="start-your-first-search"
        data-parent-event-name="click_recent_activity"
        data-event-name="click_recent_activity_activity_page_null_state_start_search"
        onClick={() => dispatch(routeChange({ view: View.SEARCH }))}
      >
        Start your first search.
      </StyledButton>
    </StyledSection>
  );
}
