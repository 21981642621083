import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ConfirmModalCobranded from '@client/components/ConfirmModal/ConfirmModalCobranded';
import CircleButton from '@client/components/generic/CircleButton';
import HeartIconActiveCobranded from '@client/components/HeartIcon/HeartIconActiveCobranded';
import HeartIconInactiveCobranded from '@client/components/HeartIcon/HeartIconInactiveCobranded';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';
import theme from '@client/css-modules/WatchListActionButton.css';
import { getSavedSearches } from '@client/store/selectors/saved-search.selectors';
import { deleteSavedSearch } from '@client/store/actions/saved-search.actions';
import { postRecentSearchSave } from '@client/store/slices/recent-user-activity.slice';

const Div = styled.div`
  width: 35px;
  height: unset;
  transform: scale(0.6);
`;

type Props = { recentSearch: SavedSearchAPIFields };
export default function SavedRecentSearchButton({ recentSearch }: Props) {
  const dispatch = useDispatch();
  /* save-search API call can be delayed so toggling save state instantly in the UI for perceived performance */
  const [isInstantSave, setIsInstantSave] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const savedSearches = useSelector(getSavedSearches);
  const savedSearchMatch = savedSearches.find(
    (savedSearch) =>
      savedSearch.filter.place_id === recentSearch.place_id &&
      savedSearch.filter.min_beds === recentSearch.min_beds &&
      savedSearch.filter.min_baths === recentSearch.min_baths
  );
  const isSaved = !!savedSearchMatch || isInstantSave;

  return (
    <Div
      data-hc-name="save-search-button"
      data-parent-event-name="click_recent_activity"
      data-event-name="click_recent_activity_activity_page_save_search"
      className={theme.WatchListActionButton}
    >
      <CircleButton
        theme={theme}
        isActive={isSaved}
        ariaLabel={`${isSaved ? 'Remove from' : 'Add to'} your saved search`}
        onClick={() => {
          if (isSaved) {
            setIsConfirmModalOpen(true);
          } else {
            setIsInstantSave(true);
            dispatch(
              postRecentSearchSave({ searchId: recentSearch.search_id })
            );
          }
        }}
      >
        {isSaved ? (
          <HeartIconActiveCobranded className={theme.HeartIcon} />
        ) : (
          <HeartIconInactiveCobranded className={theme.HeartIcon} />
        )}
      </CircleButton>
      <ConfirmModalCobranded
        title="Remove Saved Search"
        body="Are you sure you want to remove this saved search?"
        isActive={isConfirmModalOpen}
        handleConfirm={() => {
          setIsInstantSave(false);
          if (savedSearchMatch) {
            dispatch(deleteSavedSearch(savedSearchMatch.searchId));
          }
          setIsConfirmModalOpen(false);
        }}
        handleClose={() => {
          setIsConfirmModalOpen(false);
        }}
      />
    </Div>
  );
}
