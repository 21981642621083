import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getUserEmailAddress } from '@client/store/selectors/auth.selectors';
import {
  getUserEmailUpdateStatus,
  getUserEmailUpdateModalStatus,
} from '@client/store/selectors/user-profile.selectors';
import {
  updateUserEmail,
  updateEmailModalHide,
} from '@client/store/actions/auth.actions';
import UpdateEmailModal from '@client/components/UpdateEmailModal';

const mapStateToProps = (state) => {
  return {
    isUpdateEmailModalOpen: getUserEmailUpdateModalStatus(state),
    userEmailAddress: getUserEmailAddress(state),
    emailUpdateStatus: getUserEmailUpdateStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleUpdateEmailModalClose: () => dispatch(updateEmailModalHide()),
    handleUpdateEmailAddress: (userInfo) => dispatch(updateUserEmail(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmailModal);
