import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserEmailAddress,
  getUserFirstName,
  getUserLastName,
} from '@client/store/selectors/auth.selectors';
import { sharePropertySubmit } from '@client/store/actions/share-property.actions';
import { getPropertyDetailsNormalized } from '@client/store/selectors/property-details.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import TextInput from '@client/components/generic/TextInput';
import { isValidEmailAddress } from '@client/utils/validations.forms';
import CopyableField from '@client/components/generic/CopyableField';
import { getPDPUrlForSlug } from '@client/utils/property.utils';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { CobrandId, COBRAND_IDS } from '@client/store/types/cobranding';
import PillButton from '@client/components/generic/PillButton';
import { toastShow } from '@client/store/actions/toast.actions';

import theme from '@client/css-modules/RequestATourShare.css';
import { useAriaAnnouncer } from '@client/context/aria-announcer';
import FormError from '../generic/FormError';

type Props = {
  handleClose: () => void;
};

export default function RequestATourShare({ handleClose }: Props) {
  const dispatch = useDispatch();
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const usersEmail = useSelector(getUserEmailAddress);
  const cobrandId = useSelector(getCobrandId) as CobrandId;
  const loId = useSelector(getLoanOfficerId);
  const shareableUrl =
    propertyDetails?.slug && cobrandId !== COBRAND_IDS.WWW
      ? getPDPUrlForSlug(propertyDetails.slug!, cobrandId)
      : getPDPUrlForSlug(propertyDetails!.slug!);
  const shareableUrlWithLoId = loId
    ? `${shareableUrl}?loid=${loId}`
    : shareableUrl;

  const [agentEmail, setAgentEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true)
  const ariaMessenger = useAriaAnnouncer();

  const onSubmit = () => {
    if (propertyDetails) {
      const usersName = `${userFirstName}${userFirstName && userLastName ? ' ' : ''
        }${userLastName}`;
      dispatch(
        sharePropertySubmit({
          emails: [agentEmail],
          addressSlug: propertyDetails.slug,
          usersName,
          usersEmail: usersEmail!,
          street: propertyDetails.streetAddress,
          city: propertyDetails.city,
          state: propertyDetails.state,
          zip: propertyDetails.zipcode,
          unit: propertyDetails.unit,
          address_id: propertyDetails.hcAddressId,
          // TODO: Get clarification on what message should be
          message: 'I would like to schedule a tour of this property.',
          loId,
        })
      );
    }
    ariaMessenger?.("These property details have been emailed to your agent.")
    handleClose();
  };

  return (
    <form className={theme.RequestATourShare}>
      <div>
        <TextInput
          theme={theme}
          label="Agent email"
          value={agentEmail}
          onChange={(e) => {
            setIsEmailValid(true)
            setAgentEmail(e.target.value);
          }}
          onBlur={() => setIsEmailValid(isValidEmailAddress(agentEmail))}
          error={(
            <FormError value={!isEmailValid ? 'Please provide a valid email address'
              : null}
            />
          )}
        />
      </div>
      <div>
        <CopyableField
          theme={theme}
          value={shareableUrlWithLoId}
          isLoading={false}
          afterCopy={() => dispatch(toastShow('Share URL copied to clipboard'))}
        />
      </div>
      <div>
        <PillButton
          data-event-name="click_request_a_tour_have_agent_share_property_button"
          data-parent-event-name="click_request_a_tour"
          type="button"
          theme={theme}
          onClick={onSubmit}
          ariaLabel="Send"
          disabled={!agentEmail || !isEmailValid}
        >
          Send
        </PillButton>
      </div>
    </form>
  );
}
