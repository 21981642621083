import { throttle } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import SlideInModal from '@client/components/generic/SlideInModal';
import theme from '@client/css-modules/SessionKeepAlive.css';
import { logOutThenRedirect } from '@client/store/actions/auth.actions';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import {
  getIsInsideNativeApp,
  getSessionKeepAliveUrl,
  getSessionLogoutUrl,
} from '@client/store/selectors/cobranding.selectors';

const MILLISECONDS_UNTIL_SESSION_EXPIRED_MODAL =
  340 * 1000; /* 5 mins 40 seconds */
const MILLISECONDS_UNTIL_SESSION_EXPIRED = 400 * 1000; /* 6 mins 40 seconds */
const SESSION_KEEP_ALIVE_THROTTLE_MILLISECONDS = 60 * 1000; /* 1 minute */

type State = {
  keepAliveCount: number;
  isModalActive: boolean;
};

type TimeoutId = 'displaySessionModal' | 'endSession';
type Timeouts = Array<{ id: TimeoutId; length: number }>;

type EventReceivedByWebWorker = {
  data: {
    timeouts: Timeouts;
  };
};

type EventSentByWebWorker = {
  data: {
    timeoutId: TimeoutId;
  };
};

type WebWorkerPostMessage = (data: any) => void;

/* JS code to be assigned to the worker.
 * Upon receiving a message from the main window, the worker will start several timeouts, sending a
 * message back when one of the timeouts completes.  Using web workers ensures that the timeouts continue
 * even when the tab/window is inactive */
const workerJavascript = function () {
  let activeTimeoutIds: NodeJS.Timeout[] = [];

  const startTimeouts = (timeouts: Timeouts) => {
    activeTimeoutIds = [];
    timeouts.forEach((timeout) => {
      activeTimeoutIds.push(
        setTimeout(() => {
          (postMessage as WebWorkerPostMessage)({ timeoutId: timeout.id });
        }, timeout.length)
      );
    });
  };

  onmessage = function (e: EventReceivedByWebWorker) {
    /* If no timeouts are currently running, initialize and start the timeouts */
    if (activeTimeoutIds.length === 0) {
      startTimeouts(e.data.timeouts);
      /* Restart the timeouts */
    } else {
      activeTimeoutIds.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
      startTimeouts(e.data.timeouts);
    }
  };
};

type Props = {
  isEnabledForAuthState: boolean;
  handleLogoutAndRedirect: (url: string) => void;
  sessionKeepAliveUrl: string | null;
  sessionLogoutUrl: string | null;
  isInsideNativeApp: boolean;
  /* Caution: ONLY use for unit tests */
  __forceModalToBeActive?: boolean;
};

/* REDUX STATE WRAPPERS */
const mapStateToProps = (state) => ({
  isEnabledForAuthState: getIsLoggedIn(state),
  sessionKeepAliveUrl: getSessionKeepAliveUrl(state),
  sessionLogoutUrl: getSessionLogoutUrl(state),
  isInsideNativeApp: getIsInsideNativeApp(state),
});

const mapDispatchToProps = {
  handleLogoutAndRedirect: logOutThenRedirect,
};

/**
 * A component that handles keeping the user's remote session with the Cobrand alive and displaying
 * a modal to notify the user of the remote session's expiry
 */
class SessionKeepAlive extends Component<Props, State> {
  state: State = {
    /* Value updated solely for the purpose of causing the `<img>` below to be re-requested when
     * the user moves the mouse or uses the keyboard, causing the remote session to be kept alive */
    keepAliveCount: 0,
    isModalActive: false,
  };

  displaySessionExpiredModalTimeout: number = 0;
  endSessionTimeout: number = 0;
  webWorkerBlob = new Blob([`(${workerJavascript.toString()})()`], {
    type: 'application/javascript',
  });
  webWorker = new Worker(URL.createObjectURL(this.webWorkerBlob));
  timerStartTime = 0;

  componentDidMount() {
    /* Normal case - start keep alive timers when loading app already logged in via SAML */
    if (this.getShouldInstrumentKeepAlive()) {
      this.bindKeepAliveEventsAndStartTimers();
    }
  }

  componentDidUpdate(prevProps: Props) {
    /* Testing/QA case - start keep alive after logging in to test account on our site */
    if (
      !prevProps.isEnabledForAuthState &&
      this.getShouldInstrumentKeepAlive()
    ) {
      this.bindKeepAliveEventsAndStartTimers();
    }
  }

  componentWillUnmount() {
    if (this.getShouldInstrumentKeepAlive()) {
      window.removeEventListener('mousemove', this.throttledUpdateKeepAlive);
      window.removeEventListener('keydown', this.throttledUpdateKeepAlive);
      window.removeEventListener('touchend', this.throttledUpdateKeepAlive);
      document.removeEventListener('visibilitychange', this.onVisibilityChange);
      window.removeEventListener('pageshow', this.onPageShow);

      window.clearTimeout(this.displaySessionExpiredModalTimeout);
      window.clearTimeout(this.endSessionTimeout);
      this.throttledUpdateKeepAlive.cancel();
    }
  }

  bindKeepAliveEventsAndStartTimers = () => {
    window.addEventListener('mousemove', this.throttledUpdateKeepAlive);
    window.addEventListener('keydown', this.throttledUpdateKeepAlive);
    window.addEventListener('touchend', this.throttledUpdateKeepAlive);
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    window.addEventListener('pageshow', this.onPageShow);

    this.restartTimeouts();
  };

  /* Handle the case of returning to the page after reopening a laptop.
   * This will also fire after switching back to an inactive tab, but will be a no-op in that case. */
  onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.performPointInTimeCheck();
    }
  };

  /* Handle the case of returning to the page on a mobile device after waking the screen.
   * This will also fire soon after the component inits on initial render, but will be a no-op. */
  onPageShow = () => {
    this.performPointInTimeCheck();
  };

  /* Perform a point-in-time check to see if we should either display the modal or redirect based
   * on time elapsed since the timers were started/reset.  This is needed since even web workers are
   * stopped when a computer or mobile device is locked. */
  performPointInTimeCheck = () => {
    const idleTime = Date.now() - this.timerStartTime;
    const { isModalActive } = this.state;
    console.log('Session keepalive: returning from inactive state', {
      idleFor: idleTime,
      untilModal: MILLISECONDS_UNTIL_SESSION_EXPIRED_MODAL,
      untilRedirect: MILLISECONDS_UNTIL_SESSION_EXPIRED,
    });
    if (idleTime > MILLISECONDS_UNTIL_SESSION_EXPIRED) {
      this.redirectToExternalSignedOutPage();
    } else if (
      idleTime > MILLISECONDS_UNTIL_SESSION_EXPIRED_MODAL &&
      !isModalActive
    ) {
      this.handleShowModal();
    }
  };

  /**
   * The session keep alive functionality should only take effect when not inside of the native app
   * and when the user is logged in (which they'll always be when coming from the Cobrand's experience)
   */
  getShouldInstrumentKeepAlive = (): boolean => {
    const {
      isEnabledForAuthState,
      sessionKeepAliveUrl,
      sessionLogoutUrl,
      isInsideNativeApp,
    } = this.props;
    return (
      isEnabledForAuthState &&
      !isInsideNativeApp &&
      !!sessionKeepAliveUrl &&
      !!sessionLogoutUrl
    );
  };

  /**
   * Restart the internal session timeouts and re-request the <img> causing the session to be extended externally.
   * This method is bound to several user-interaction events: keypress, mousemove, touchend
   */
  keepAlive = (e: Event | null, isModalButtonPress?: boolean): void => {
    const { isModalActive } = this.state;
    /* Before the modal displays, any mousemove or button press should restart the timers and call the session
     * extend endpoint.  After the modal is displayed, only pressing the "Stay Signed In" button should do this */
    if (!isModalActive || isModalButtonPress) {
      console.log(
        `Session keepalive: restarting timers n=${this.state.keepAliveCount}`
      );
      this.restartTimeouts();
      this.setState({ keepAliveCount: this.state.keepAliveCount + 1 });
    }
  };

  throttledUpdateKeepAlive = throttle(
    this.keepAlive,
    SESSION_KEEP_ALIVE_THROTTLE_MILLISECONDS,
    {
      leading: true,
      trailing: false,
    }
  );

  /**
   * Create timeouts for both the modal displaying and the session ending
   */
  restartTimeouts = (): void => {
    /* Post a message to the new worker, starting the timeouts within it */
    this.webWorker.postMessage({
      timeouts: [
        {
          id: 'displaySessionModal',
          length: MILLISECONDS_UNTIL_SESSION_EXPIRED_MODAL,
        },
        { id: 'endSession', length: MILLISECONDS_UNTIL_SESSION_EXPIRED },
      ],
    });

    /* The worker is configured to send a message back to main thread when either of the timeouts expire.
     * Receive this message and act on it */
    this.webWorker.onmessage = (e: EventSentByWebWorker) => {
      if (e.data.timeoutId === 'displaySessionModal') {
        this.handleShowModal();
      } else if (e.data.timeoutId === 'endSession') {
        this.redirectToExternalSignedOutPage();
      }
    };
    this.timerStartTime = Date.now();
  };

  redirectToExternalSignedOutPage = (): void => {
    const { sessionLogoutUrl, handleLogoutAndRedirect } = this.props;
    /* Checking again here for safety (in case the native app sets the JS bridge late) */
    if (this.getShouldInstrumentKeepAlive() && sessionLogoutUrl) {
      handleLogoutAndRedirect(sessionLogoutUrl);
    }
  };

  handleShowModal = () => {
    /* Checking again here for safety (in case the native app sets the JS bridge late) */
    if (this.getShouldInstrumentKeepAlive()) {
      this.setState({ isModalActive: true });
    }
  };

  handleModalSignOut = (): void => {
    this.redirectToExternalSignedOutPage();
  };

  handleModalStaySignedIn = (): void => {
    this.keepAlive(null, true);
    this.setState({ isModalActive: false });
  };

  render() {
    const { sessionKeepAliveUrl, __forceModalToBeActive } = this.props;
    const { keepAliveCount, isModalActive } = this.state;

    if (!this.getShouldInstrumentKeepAlive()) {
      return null;
    }

    return (
      <div className={theme.SessionKeepAlive}>
        {keepAliveCount > 0 && (
          <img
            src={`${sessionKeepAliveUrl}?n=${keepAliveCount}`}
            alt=""
            data-testid="keep-alive-img"
          />
        )}
        <SlideInModal
          theme={theme}
          isFullScreen={true}
          isActive={isModalActive || !!__forceModalToBeActive}
          handleClose={this.handleModalStaySignedIn}
          modalAriaLabel={'Your session is about to end'}
        >
          <h2>Your session is about to end</h2>
          <p>
            You've been inactive for a while. For your security we'll
            automatically sign you out in approximately 1 minute. Choose "Stay
            signed in" to continue or "Sign out" if you're done.
          </p>
          <div className={theme.ButtonRow}>
            <PillButton
              theme={theme}
              ariaLabel={'Sign out'}
              style={{ backgroundColor: '#d6d6d6' }}
              deemphasized
              onClick={this.handleModalSignOut}
            >
              Sign out
            </PillButton>
            <PillButton
              theme={theme}
              ariaLabel={'Stay signed in'}
              onClick={this.handleModalStaySignedIn}
            >
              Stay signed in
            </PillButton>
          </div>
        </SlideInModal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionKeepAlive);
