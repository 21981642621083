import classNames from 'classnames';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/HOListingStatusSelector.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  selectedListingStatus: 'home-sold' | 'active-listing';
  onListingStatusChange: (status: 'home-sold' | 'active-listing') => void;
  theme: Theme;
  shouldShowCourtesyText?: boolean;
};

function HOListingStatusSelector({
  onListingStatusChange,
  selectedListingStatus,
  shouldShowCourtesyText,
  theme,
}: Props) {
  const { homeownerModalHeaderBackgroundColor } = useCobrandStyles();
  const isSelectedActiveListing = selectedListingStatus === 'active-listing';
  const isSelectedHomeSold = selectedListingStatus === 'home-sold';

  return (
    <div className={theme.ListingStatusSelector}>
      <button
        aria-label="home sold selector"
        data-hc-name="home-sold-select-button"
        data-event-name="click_home_sold_tab"
        className={classNames(theme.SelectButton, {
          [theme.ActiveSelectButton]: isSelectedHomeSold,
        })}
        style={{
          ...(isSelectedHomeSold && {
            color: homeownerModalHeaderBackgroundColor,
            borderColor: homeownerModalHeaderBackgroundColor,
          }),
        }}
        onClick={() => onListingStatusChange('home-sold')}
      >
        Homes Sold
      </button>
      {!shouldShowCourtesyText && <div className={theme.Divider} />}
      <button
        aria-label="active listing selector"
        data-hc-name="active-listing-select-button"
        data-event-name="click_active_listing_tab"
        className={classNames(theme.SelectButton, {
          [theme.ActiveSelectButton]: isSelectedActiveListing,
        })}
        style={{
          ...(isSelectedActiveListing && {
            color: homeownerModalHeaderBackgroundColor,
            borderColor: homeownerModalHeaderBackgroundColor,
          }),
        }}
        onClick={() => onListingStatusChange('active-listing')}
      >
        Active Listings
      </button>
      {shouldShowCourtesyText && (
        <div className={theme.CourtesyText}>Courtesy of HouseCanary LLC.</div>
      )}
    </div>
  );
}

const ThemedHOListingStatusSelector = themr(
  'HOListingStatusSelector',
  defaultTheme
)(HOListingStatusSelector);
export default ThemedHOListingStatusSelector;
