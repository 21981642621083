import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import CaratIconCobranded from '@client/components/CaratIcon/CaratIconCobranded';
import RouterLink from '@client/components/RouterLink';

import { LO_DIRECT_ROUTES, View } from '@client/routes/constants';

import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import ComeHomeLogoHouseLeft from '@client/inline-svgs/come-home-logo-house-left';
import ComeHomeDirectLogoBlue from '@client/inline-svgs/comehome-direct-logo-blue';
import { SVGIconComponentType } from '@client/inline-svgs/types';
import {
  getIsLoggedIn,
  getUserSpecialUserType,
} from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

const StyledSection = styled.section`
  width: 100%;
  padding: 19px 26px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const NavItemStyles = css`
  width: 100%;
  padding: 26px 13px 25px 21px;
  border: 0;
  text-align: left;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  margin-top: 19px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 350;
  letter-spacing: -0.178px;
  cursor: pointer;
`;
const StyledRouterLink = styled(RouterLink)`
  ${NavItemStyles}
`;
const StyledCloseButton = styled.button`
  ${NavItemStyles}
`;
const StyledButtonDiv = styled.div`
  width: 100%;
  margin-top: 6px;
  box-sizing: border-box;
`;
const SVGStyles = css`
  height: 20px;
  margin-bottom: 5px;
  & svg {
    height: 20px;
  }
`;
const StyledCHLogo = styled(ComeHomeLogoHouseLeft)`
  ${SVGStyles}
`;
const StyledCHDirectLogo = styled(ComeHomeDirectLogoBlue)`
  ${SVGStyles}
`;
const StyledCaratIconCobranded = styled(CaratIconCobranded)`
  margin-right: 10px;
  transform: scale(0.66);
  & svg {
    transform: scale(0.66);
  }
`;

type NavButtonProps = {
  Logo: SVGIconComponentType;
  text: string;
  view?: View;
  dataHcName: string;
  target?: string;
  onClick?: () => void;
};

function NavButton({
  Logo,
  text,
  view,
  target,
  onClick,
  dataHcName,
}: NavButtonProps) {
  if (view) {
    return (
      <StyledRouterLink
        view={view}
        ariaLabel={`Navigate to ${text}`}
        target={target}
        onClick={onClick}
        data-hc-name={dataHcName}
      >
        <div>
          <div>
            <Logo style={{ height: 20 }} />
          </div>
          <div>{text}</div>
        </div>
        <div>
          <StyledCaratIconCobranded />
        </div>
      </StyledRouterLink>
    );
  } else {
    return (
      <StyledCloseButton
        aria-label="Close the modal"
        onClick={onClick}
        data-hc-name={dataHcName}
      >
        <div>
          <div>
            <Logo style={{ height: 20 }} />
          </div>
          <div>{text}</div>
        </div>
        <div>
          <StyledCaratIconCobranded />
        </div>
      </StyledCloseButton>
    );
  }
}

const LODirectLoginNavModal = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoggedInPreviously = useRef(isLoggedIn);
  const userType = useSelector(getUserSpecialUserType);
  const isLODirectEnabled = useSelector(getIsFeatureEnabled('lo_direct'));
  const isLODirectUser = userType === 'lo' && isLODirectEnabled;
  const currentView = useSelector(getCurrentView);
  const [isActive, setIsActive] = useState(false);

  const handleClose = () => {
    setIsActive(false);
  };

  /* Display the modal after an LO Direct user logs in from a non-LO Direct page */
  useEffect(() => {
    if (
      !isLoggedInPreviously.current &&
      isLoggedIn &&
      isLODirectUser &&
      !isActive &&
      currentView &&
      !LO_DIRECT_ROUTES.includes(currentView)
    ) {
      isLoggedInPreviously.current = true;
      setIsActive(true);
    }
  }, [isLoggedIn, isActive, isLODirectUser, currentView]);

  /* Reset the isLoggedInPreviously ref when the user logs out to allow the above useEffect to
   * function properly on the next login */
  useEffect(() => {
    if (isLoggedInPreviously.current && !isLoggedIn) {
      isLoggedInPreviously.current = false;
    }
  }, [isLoggedIn]);

  return (
    <SlideInModalContainer
      dataHcName={'lo-direct-login-nav-modal'}
      modalAriaLabel={`Select which account you are trying to access`}
      isActive={isActive}
      handleClose={handleClose}
    >
      <StyledSection>
        <div>Select which account you are trying to access.</div>
        <StyledButtonDiv>
          <NavButton
            Logo={StyledCHLogo}
            text="Home Search & Valuation"
            dataHcName="select-comehome-link"
            onClick={() => {
              setIsActive(false);
            }}
          />
          <NavButton
            target="_blank"
            Logo={StyledCHDirectLogo}
            text="Loan Officer Dashboard"
            view={View.LO_DIRECT_CLIENTS}
            dataHcName="select-chd-link"
            onClick={() => {
              setIsActive(false);
            }}
          />
        </StyledButtonDiv>
      </StyledSection>
    </SlideInModalContainer>
  );
};

export default LODirectLoginNavModal;
