import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { get } from 'lodash';

import { getIsXLargeSize } from '@client/store/selectors/match-media.selectors';
import { getIsShowingNoMLSModal } from '@client/store/selectors/search.selectors';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/NoMLSModal.css';
import { View } from '@client/routes/constants';
import RouterLink from '@client/components/RouterLink';
import TextInput from '@client/components/generic/TextInput';
import Arrow from '@client/inline-svgs/arrow';
import {
  reportNavBuyClick,
  reportNavHomeOwnerClick,
  reportNavSignupClick,
} from '@client/store/actions/analytics.actions';
import { registerEmail } from '@client/store/actions/mls-notify.actions';
import { searchDismissMlsCoverageModal } from '@client/store/actions/search.actions';
import NoMLSModalHomeownerTooltipCobranded from '@client/components/NoMLSModalHomeownerTooltip/NoMLSModalHomeownerTooltipCobranded';
import NoMLSModalSearchTooltipCobranded from '@client/components/NoMLSModalSearchTooltip/NoMLSModalSearchTooltipCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const NoMLSModal: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(getIsShowingNoMLSModal);
  const isDesktopSize = useSelector(getIsXLargeSize);
  const [emailAddress, setEmailAddress] = useState('');
  const [hoverSearch, setHoverSearch] = useState(false);
  const [hoverHomeOwner, setHoverHomeOwner] = useState(false);
  const [hoverEmail, setHoverEmail] = useState(false);
  const handleInput = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleCloseModal = () => {
    dispatch(searchDismissMlsCoverageModal());
  };
  const handleSubmit = (e) => {
    dispatch(
      registerEmail({
        email: emailAddress,
      })
    );
    handleCloseModal();
  };
  const handleSearchMouseEnter = () => {
    if (isDesktopSize) {
      setHoverSearch(true);
    }
  };
  const handleSearchMouseLeave = () => {
    if (isDesktopSize) {
      setHoverSearch(false);
    }
  };
  const handleHomeownerMouseEnter = () => {
    if (isDesktopSize) {
      setHoverHomeOwner(true);
    }
  };
  const handleHomeownerMouseLeave = () => {
    if (isDesktopSize) {
      setHoverHomeOwner(false);
    }
  };
  const handleGetNotifiedMouseEnter = () => {
    if (isDesktopSize) {
      setHoverEmail(true);
    }
  };
  const handleGetNotifiedMouseLeave = () => {
    if (isDesktopSize) {
      setHoverEmail(false);
    }
  };
  const handleHomeownerClick = (toView, params) => {
    switch (toView) {
      case View.SIGN_UP: {
        dispatch(reportNavSignupClick());
        break;
      }
      case View.HOMEOWNER: {
        dispatch(reportNavHomeOwnerClick());
        break;
      }
      case View.HOMEOWNER_PROPERTY_DETAILS: {
        dispatch(reportNavHomeOwnerClick());
        break;
      }
      case View.SEARCH: {
        dispatch(reportNavBuyClick());
        break;
      }
    }

    const slug = params && get(params, 'slug');

    if (slug) {
      /* if there is only 1 claimed home, change the route to the Homeowner PDP */
      dispatch(routeChange({ view: toView, params: { slug: slug } }));
    } else {
      /* if there is no claimed home or there are more than 1 claimed home, change the route to the homeowner manage home page */
      dispatch(routeChange({ view: toView }));
    }
    handleCloseModal();
    dispatch(searchDismissMlsCoverageModal());
  };

  const { linkColor, mlsRegistrationBorderColor, mlsModalCTAColor } =
    useCobrandStyles();

  return (
    <SmallModal
      isActive={isActive}
      handleClose={handleCloseModal}
      className={theme['modal-container']}
      theme={theme}
    >
      <div className={theme.Header}>
        We don't have complete coverage here yet.
      </div>
      <div className={theme.InnerContent}>
        <div
          className={theme.TextGrid}
          style={hoverSearch ? { backgroundColor: '#E0E0E0' } : {}}
          onMouseEnter={handleSearchMouseEnter}
          onMouseLeave={handleSearchMouseLeave}
        >
          <div
            className={theme.NumberBlock}
            style={hoverSearch ? { backgroundColor: '#F9F9F9' } : {}}
          >
            1
          </div>
          <div className={theme.ContentLine}>
            {' '}
            Search for a&nbsp;
            <button
              className={theme.DismissButton}
              aria-label="Dismiss no mls modal"
              style={{ color: linkColor }}
              onClick={handleCloseModal}
            >
              new city or ZIP
            </button>
          </div>
        </div>
        <div
          className={theme.TextGrid}
          style={hoverHomeOwner ? { backgroundColor: '#E0E0E0' } : {}}
          onMouseEnter={handleHomeownerMouseEnter}
          onMouseLeave={handleHomeownerMouseLeave}
        >
          <div
            className={theme.NumberBlock}
            style={hoverHomeOwner ? { backgroundColor: '#F9F9F9' } : {}}
          >
            2
          </div>
          <div className={theme.ContentLine}>
            Already own a home? Access your free&nbsp;
            <RouterLink
              style={{ color: linkColor }}
              onClick={handleHomeownerClick}
              data-ignore-intercept
              view={View.HOMEOWNER}
              ariaLabel="terms of use"
            >
              homeowner dashboard
            </RouterLink>
            .<br />
          </div>
        </div>
        <div
          className={theme.TextGrid}
          style={hoverEmail ? { backgroundColor: '#E0E0E0' } : {}}
          onMouseEnter={handleGetNotifiedMouseEnter}
          onMouseLeave={handleGetNotifiedMouseLeave}
        >
          <div
            className={theme.NumberBlock}
            style={hoverEmail ? { backgroundColor: '#F9F9F9' } : {}}
          >
            3
          </div>
          <div className={theme.ContentLine}>
            {' '}
            Get notified when active listings are available for this search
            area.
          </div>
        </div>
        <div className={theme.SearchBar}>
          <TextInput
            label=""
            placeholder="Enter your email"
            onChange={handleInput}
            theme={theme}
            style={
              hoverEmail
                ? {
                    border: `2px solid ${mlsRegistrationBorderColor}`,
                  }
                : {}
            }
          />
          <button
            onClick={handleSubmit}
            className={theme.circle}
            disabled={emailAddress === ''}
            style={{ backgroundColor: mlsModalCTAColor }}
          >
            <Arrow className={theme.ArrowIcon} />
          </button>
        </div>
        <div className={theme.Dismiss}>
          <button
            className={theme.DismissButton}
            aria-label="Dismiss no mls modal"
            style={{ color: linkColor }}
            onClick={handleCloseModal}
          >
            Dismiss
          </button>
        </div>
      </div>
      {hoverHomeOwner && <NoMLSModalHomeownerTooltipCobranded />}
      {hoverSearch && <NoMLSModalSearchTooltipCobranded />}
    </SmallModal>
  );
};

export default NoMLSModal;
