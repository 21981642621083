import classNames from 'classnames';
import React from 'react';

import theme from '@client/css-modules/HOHomeSalesModal.css';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import CHONearbySalesPropertyCard from '@client/components/CHONearbySalesPropertyCard';
import HOHomeSalesMapView from '@client/components/HOHomeSalesMapView';
import {
  useHomeSaleClickHandlers,
  useHomeSalesModalStatus,
  useListingStatusSelector,
} from '@client/hooks/home-sales-modal.hooks';
import { useHomeSalesPropertyData } from '@client/hooks/home-sales-data.hooks';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import Arrow from '@client/inline-svgs/arrow';
import SeeAllIcon from '@client/inline-svgs/ho-see-all-icon';
import MapViewIcon from '@client/inline-svgs/ho-map-view-icon';
import HOListingStatusSelector from '@client/components/HOListingStatusSelector';
import HOHomeSalesNullState from '@client/components/HOHomeSalesNullState';

export default function HOHomeSalesModal() {
  const { isModalActive, isShowingSeeAll, isShowingMapView } =
    useHomeSalesModalStatus();
  const {
    soldHomes,
    activeListingHomes,
    currentPropertyLocation,
    isFetchComplete,
  } = useHomeSalesPropertyData();
  const { handleClickSeeAll, handleClickMapView, handleClickBack } =
    useHomeSaleClickHandlers();
  const { homeownerModalHeaderBackgroundColor, homeownerModalHeaderTextColor } =
    useCobrandStyles();
  const { selectedListingStatus, onListingStatusChange } =
    useListingStatusSelector();

  const nearbyHomes =
    selectedListingStatus === 'home-sold' ? soldHomes : activeListingHomes;
  const shouldShowNullState =
    isFetchComplete && (!nearbyHomes || !nearbyHomes.length);

  return (
    <SlideInModalContainer
      isFullScreen
      theme={theme}
      handleClose={handleClickBack}
      modalAriaLabel="Your neighborhood"
      dataHcName="your-neighborhood-modal"
      isActive={isModalActive}
    >
      <>
        <div
          className={theme.ModalHeader}
          style={{
            background: homeownerModalHeaderBackgroundColor,
            color: homeownerModalHeaderTextColor,
          }}
        >
          <button
            type="button"
            className={theme.CloseIconButton}
            data-hc-name="ho-home-sales-back-button"
            aria-label="Back"
            onClick={handleClickBack}
            onKeyDown={onEnterOrSpaceKey(handleClickBack)}>
            <Arrow />
          </button>
          <h2
            className={theme.ModalTitle}
            data-hc-name="header"
          >
            Your neighborhood
          </h2>
          <div className={theme.IconContainer}>
            <button
              className={classNames(theme.SeeAllButton, {
                [theme.InActiveButton]: !isShowingSeeAll,
              })}
              data-hc-name="ho-home-sales-see-all"
              aria-label="See all"
              type="button"
              onClick={handleClickSeeAll}
              onKeyDown={onEnterOrSpaceKey(handleClickSeeAll)}>
              <SeeAllIcon />
            </button>
            <button
              className={classNames(theme.MapViewButton, {
                [theme.InActiveButton]: !isShowingMapView,
              })}
              data-hc-name="ho-home-sales-map-view"
              aria-label="Map view"
              type="button"
              onClick={handleClickMapView}
              onKeyDown={onEnterOrSpaceKey(handleClickMapView)}
            >
              <MapViewIcon />
            </button>
          </div>
        </div>
        {isShowingSeeAll && (
          <>
            <HOListingStatusSelector
              theme={theme}
              selectedListingStatus={selectedListingStatus}
              onListingStatusChange={onListingStatusChange}
            />
            {!shouldShowNullState && (
              <hr className={theme.Divider} style={{ margin: 0 }} />
            )}
            {shouldShowNullState && <HOHomeSalesNullState />}
            {nearbyHomes.map((comp, i) => (
              <React.Fragment key={`${comp.slug}-${i}`}>
                <CHONearbySalesPropertyCard
                  isActiveListing={selectedListingStatus === 'active-listing'}
                  slug={comp.slug}
                  propertyDetails={comp}
                  activePropertyLocation={currentPropertyLocation}
                  rel="nofollow"
                  theme={theme}
                />
                <hr className={theme.Divider} />
              </React.Fragment>
            ))}
          </>
        )}
        {isShowingMapView && (
          <div className={theme.SearchMap}>
            <HOHomeSalesMapView
              selectedListingStatus={selectedListingStatus}
              onListingStatusChange={onListingStatusChange}
            />
          </div>
        )}
      </>
    </SlideInModalContainer >
  );
}
