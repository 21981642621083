import { useState, SetStateAction, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/RequestATourModal.css';
import { useRequestATourState } from '@client/hooks/request-a-tour.hooks';
import PillButton from '@client/components/generic/PillButton';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import RequestATourForm from '@client/components/RequestATour/RequestATourForm';
import {
  TourViewOptions,
  TourHeadingsAndSubHeadings,
  TourViews,
} from '@client/store/types/request-a-tour';
import { getUserHasFirstnameLastnameAndEmail } from '@client/store/selectors/auth.selectors';
import RequestATourUserForm from '@client/components/RequestATour/RequestATourUserForm';
import { updateUserProfile } from '@client/store/actions/auth.actions';
import { UserApiInfo } from '@client/components/IncompleteUserInfoForm';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import RequestATourAgentInfo from '@client/components/RequestATour/RequestATourAgentInfo';
import RequestATourShare from '@client/components/RequestATour/RequestATourShare';
import { useAriaAnnouncer } from '@client/context/aria-announcer';

const RequestATourModalWrapper: React.FC<{
  heading?: string | JSX.Element | null;
  subHeading?: string | JSX.Element | null;
  children?: React.ReactNode;
}> = ({ heading, subHeading, children }) => (
  <section className={theme.RequestATourModalWrapper}>
    <h2 className={theme.Heading}>{heading || 'Tour this home'}</h2>
    {subHeading && <h3 className={theme.SubHeading}>{subHeading}</h3>}
    <div>{children}</div>
  </section>
);

function RequestATourModal() {
  const dispatch = useDispatch();
  const isUserInfoComplete = useSelector(getUserHasFirstnameLastnameAndEmail);
  const initialView = isUserInfoComplete ? 'agentSelection' : 'ssoInterstitial';
  const [currentView, setCurrentView] = useState<TourViewOptions>(initialView);
  const {
    isRequestATourModalOpen,
    closeRequestATourModal,
    requestATourFormState,
    setRequestATourFormState,
    resetRequestATourFormState,
  } = useRequestATourState();

  /* Mobile view has different styling */
  const isAnchoredToBottom = useSelector(getIsSmallSize);
  const ariaMessenger = useAriaAnnouncer();

  const headings: TourHeadingsAndSubHeadings = {
    agentInfo: <span>Sorry, we were unable to contact the listing agent.</span>,
  };

  const subHeadings: TourHeadingsAndSubHeadings = {
    agentSelection: null,
    shareProperty:
      'Please contact your agent directly to tour this home. As a courtesy, you can share this property with your agent.',
    requestTour: 'ComeHome will connect you with an agent.',
    ssoInterstitial:
      'To be paired with an agent please complete the following required fields.',
    agentInfo: 'Please contact the listing agent directly.',
  };

  function handleClose() {
    closeRequestATourModal();
    resetInternalState();
    setTimeout(() => {
      ariaMessenger?.("")
    }, 200)
    
  }

  const tourModalViews: TourViews = {
    agentSelection: <AgentSelection setCurrentView={setCurrentView} />,
    shareProperty: <RequestATourShare handleClose={handleClose} />,
    requestTour: (
      <RequestATourForm
        setCurrentView={setCurrentView}
        handleClose={handleClose}
      />
    ),
    ssoInterstitial: (
      <RequestATourUserForm
        handleClick={(userFormInfo: UserApiInfo) => {
          dispatch(updateUserProfile(userFormInfo));
          setRequestATourFormState({
            ...requestATourFormState,
            ...userFormInfo,
          });
        }}
        setCurrentView={setCurrentView}
      />
    ),
    agentInfo: <RequestATourAgentInfo />,
  };

  const resetInternalState = () => {
    setCurrentView(initialView);
    resetRequestATourFormState();
  };

  return (
    <SmallModal
      isActive={isRequestATourModalOpen}
      className={theme.RequestATourModal}
      handleClose={handleClose}
      theme={theme}
      anchorToBottom={isAnchoredToBottom}
    >
      <RequestATourModalWrapper
        heading={headings[currentView]}
        subHeading={subHeadings[currentView]}
      >
        {tourModalViews[currentView]}
      </RequestATourModalWrapper>
    </SmallModal>
  );
}

function AgentSelection({
  setCurrentView,
}: {
  setCurrentView: Dispatch<SetStateAction<TourViewOptions>>;
}) {
  const { pillButtonDeemphasizedBackgroundColor } = useCobrandStyles();
  const buttonStyles = {
    border: `1px solid ${pillButtonDeemphasizedBackgroundColor}`,
    color: pillButtonDeemphasizedBackgroundColor,
    background: '#fff',
  };

  /* Use to apply cobranded focus styles: */
  const [isNoAgentBeingTouched, setIsNoAgentBeingTouched] = useState(false);
  const [isHasAgentBeingTouched, setIsHasAgentBeingTouched] = useState(false);

  return (
    <div className={theme.AgentSelection}>
      <div className={theme.AgentButtonTopRow}>
        <PillButton
          data-event-name="click_request_a_tour_no_agent_button"
          data-parent-event-name="click_request_a_tour"
          className={theme.AgentButton}
          deemphasized
          onFocus={() => setIsHasAgentBeingTouched(true)}
          onBlur={() => setIsHasAgentBeingTouched(false)}
          onClick={() => {
            setCurrentView('requestTour');
          }}
          style={isHasAgentBeingTouched ? {} : buttonStyles}
        >
          I don't have an agent
        </PillButton>
      </div>
      <div>
        <PillButton
          data-event-name="click_request_a_tour_have_agent_button"
          data-parent-event-name="click_request_a_tour"
          className={theme.AgentButton}
          deemphasized
          onFocus={() => setIsNoAgentBeingTouched(true)}
          onBlur={() => setIsNoAgentBeingTouched(false)}
          onClick={() => setCurrentView('shareProperty')}
          style={isNoAgentBeingTouched ? {} : buttonStyles}
        >
          I have an agent
        </PillButton>
      </div>
    </div>
  );
}

export default RequestATourModal;
