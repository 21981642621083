import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExternalLink from '@client/components/generic/ExternalLink';
import PillButton from '@client/components/generic/PillButton';
import TextArea from '@client/components/generic/TextArea';
import GenericLOInfo from '@client/components/GenericLOInfo';
import GreenCheckMarkIconCobranded from '@client/components/GreenCheckMarkIcon/GreenCheckMarkIconCobranded';
import LOContactInfo from '@client/components/LOContactInfo';
import {
  MessageFailure,
  MessageSuccess,
} from '@client/components/YourTeam/MessageConfirmation';
import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';
import YourTeamCTAs from '@client/components/YourTeam/YourTeamCTAs';
import YourTeamMainCTAButton from '@client/components/YourTeam/YourTeamMainCTAButton';
import theme from '@client/css-modules/YourTeam/LOCard.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  authModalShow,
  ensureLoggedInThenAction,
} from '@client/store/actions/auth.actions';
import { STATUSES } from '@client/store/constants';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import {
  getCustomizationData,
  getIsWellsFargoCobrand,
  getYourTeam,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getLoanOfficerDatabaseId,
  getLoanOfficerInfo,
  getLoanOfficerIsLODirectUser,
} from '@client/store/selectors/loan-officer.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import {
  clearisMessageToLoDirectSubmitted,
  postContactLoanOfficer,
  resetContactLoanOfficerMessageState,
  selectIsMessageToLoDirectSubmitted,
  selectLoanOfficerMessageStatus,
  setYourTeamModalType,
  submitLODirectLeadMessage,
} from '@client/store/slices/your-team.slice';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

const LO_DIRECT_LEAD_MESSAGE_MAX_LENGTH = 1000;

function LOCardContentWithNoLODataWellsFargo() {
  const yourTeam = useSelector(getYourTeam);
  const reportYourTeamEvent = useReportYourTeamEvent();
  const noLOUrl = yourTeam?.lo_no_lo_url || null;
  const outboundCtaUrl = useOutboundCtaUrl(noLOUrl);

  function secondaryClick() {
    reportYourTeamEvent('click_your_team_lender_no_lo_contact_button', {});

    if (outboundCtaUrl) {
      window.open(outboundCtaUrl);
    }
  }

  return (
    <div className={theme.LOCardContentWithNoLODataContainer}>
      <GenericLOInfo
        theme={theme}
        shouldUseDotSeparator
        shouldHidePhoneCallButtonOnMobile
        shouldDisplayNmlsIdOnSecondLine
        shouldDisplaySubTitleSameLineWithTitle
      />

      {yourTeam?.lo_no_lo_text && yourTeam?.lo_no_lo_url ? (
        <div
          className={classNames(
            theme.ButtonWrapper,
            theme.ButtonWrapperForSingleCTA
          )}
        >
          <PillButton
            theme={theme}
            className={theme.BottomButton}
            customBackgroundColor="#D71E28"
            customTextColor="#FFFFFF"
            ariaLabel={`${yourTeam.lo_no_lo_text} link`}
            onKeyDown={onEnterOrSpaceKey(secondaryClick)}
            onClick={secondaryClick}
          >
            {yourTeam.lo_no_lo_text}
          </PillButton>
        </div>
      ) : null}
    </div>
  );
}

function LOCardContentWithNoLOData() {
  const yourTeam = useSelector(getYourTeam);
  const reportYourTeamEvent = useReportYourTeamEvent();
  const noLOUrl = yourTeam?.lo_no_lo_url || null;
  const outboundCtaUrl = useOutboundCtaUrl(noLOUrl);

  function secondaryClick() {
    reportYourTeamEvent('click_your_team_lender_no_lo_contact_button', {});

    if (outboundCtaUrl) {
      window.open(outboundCtaUrl);
    }
  }

  return (
    <div className={theme.LOCardContentWithNoLODataContainer}>
      <GenericLOInfo
        theme={theme}
        shouldUseDotSeparator
        shouldHidePhoneCallButtonOnMobile
      />

      <YourTeamMainCTAButton
        eventName="click_your_team_no_lo_lender_cta"
        theme={theme}
      />
      {yourTeam?.lo_no_lo_text && yourTeam?.lo_no_lo_url ? (
        <div className={theme.ButtonWrapper}>
          <PillButton
            theme={theme}
            className={theme.BottomButton}
            customBackgroundColor="#f0f0f0"
            customTextColor="#4a4a4a"
            ariaLabel={`${yourTeam.lo_no_lo_text} link`}
            onKeyDown={onEnterOrSpaceKey(secondaryClick)}
            onClick={secondaryClick}
          >
            {yourTeam.lo_no_lo_text}
          </PillButton>
        </div>
      ) : null}
    </div>
  );
}

function LOCardContentWithLOData() {
  const dispatch = useDispatch();
  const yourTeam = useSelector(getYourTeam);
  const loanOfficerId = useSelector(getLoanOfficerDatabaseId);
  const loanOfficerMessageStatus = useSelector(selectLoanOfficerMessageStatus);

  return (
    <div className={theme.LOCardContentWithLODataContainer}>
      {loanOfficerMessageStatus === STATUSES.INIT ||
      loanOfficerMessageStatus === STATUSES.LOADING ? (
        <>
          <LOContactInfo
            dataHcName="your-team-lo-data"
            theme={theme}
            shouldUseDotSeparator
            shouldHidePhoneCallButtonOnMobile
            shouldUseNewLayout
          />
          <YourTeamCTAs
            dataHcName="your-team-lo-button-options"
            ctaTextArr={[
              { text: yourTeam?.lo_cta_1 ?? '' },
              { text: yourTeam?.lo_cta_2 ?? '' },
              { text: yourTeam?.lo_cta_3 ?? '' },
              { text: yourTeam?.lo_cta_4 ?? '' },
              { text: yourTeam?.lo_cta_5 ?? '' },
            ]}
            useLOTabAnalyticsEventNames
            onSubmit={(message: string) => {
              if (loanOfficerId) {
                dispatch(
                  /* This is a catch-all for safety. User should be prompted to login when clicking
                   * one of the message buttons in <YourTeamCTAs>, so should always be logged in at
                   * submit time */
                  ensureLoggedInThenAction(
                    postContactLoanOfficer({ lo_id: loanOfficerId, message })
                  )
                );
              }
            }}
          />
        </>
      ) : null}

      {loanOfficerMessageStatus === STATUSES.ERROR ? (
        <MessageFailure
          dataHcName="your-team-lo-email-failure"
          onLinkClick={() => dispatch(resetContactLoanOfficerMessageState())}
        />
      ) : loanOfficerMessageStatus === STATUSES.SUCCESS ? (
        <MessageSuccess
          dataHcName="your-team-lo-email-success"
          onLinkClick={() => dispatch(resetContactLoanOfficerMessageState())}
        />
      ) : null}
    </div>
  );
}

function LOCardContentWithLODataWellsFargo() {
  const { loData } = useLoInfoProps();
  const yourTeam = useSelector(getYourTeam);
  const { your_team_default_phone_number } = useSelector(getCustomizationData);
  const ctaText = loData?.website ? 'Visit My Site' : yourTeam?.lo_no_lo_text;

  function handleCTAClick() {
    window.open(
      loData?.website ? loData.website : yourTeam?.lo_no_lo_url,
      '_blank'
    );
  }

  return (
    <div className={theme.LOCardContentWithLODataContainer}>
      <LOContactInfo
        dataHcName="your-team-lo-data"
        defaultPhoneNumber={your_team_default_phone_number}
        theme={theme}
        shouldUseDotSeparator
        shouldHidePhoneCallButtonOnMobile
        shouldUseNewLayout
        shouldDisplayNmlsIdOnSecondLine
      />

      <div className={theme.ButtonWrapper}>
        <PillButton
          theme={theme}
          className={theme.BottomButton}
          customBackgroundColor="#D71E28"
          customTextColor="#FFFFFF"
          ariaLabel={ctaText || ''}
          onKeyDown={onEnterOrSpaceKey(handleCTAClick)}
          onClick={handleCTAClick}
          data-event-name="click_your_team_lo_lender_cta"
        >
          {ctaText}
        </PillButton>
      </div>
    </div>
  );
}

function LOCardContentWithLODirectLeadSubmission() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loanOfficerMessageStatus = useSelector(selectLoanOfficerMessageStatus);
  const loanOfficerInfo = useSelector(getLoanOfficerInfo);
  const isLODirectEnabled = useSelector(getIsFeatureEnabled('lo_direct'));
  const activeHomeownerOrPDPSlug = useSelector(getActiveHomeownerOrPDPSlug);
  const currentView = useSelector(getCurrentView);
  const { linkColor } = useCobrandStyles();
  const [message, setMessage] = useState('');
  const isMessageToLoDirectSubmitted = useSelector(
    selectIsMessageToLoDirectSubmitted
  );

  useEffect(() => {
    return () => {
      dispatch(clearisMessageToLoDirectSubmitted());
    };
  }, [dispatch]);

  const isValid = () =>
    message.length > 0 && message.length <= LO_DIRECT_LEAD_MESSAGE_MAX_LENGTH;
  const submitLoDirectLeadMessageAction = submitLODirectLeadMessage({
    message,
    ui_context: {
      page:
        currentView === View.HOMEOWNER_PROPERTY_DETAILS ||
        currentView === View.PROPERTY_DETAILS
          ? currentView
          : null,
      slug: activeHomeownerOrPDPSlug,
    },
  });
  const handleSubmit = () => {
    dispatch(
      /* This is a catch-all for safety. User should be prompted to login when clicking
       * the message textarea, so should always be logged in at submit time */
      ensureLoggedInThenAction(submitLoDirectLeadMessageAction, {
        startingPage: 'login',
        headingText: {
          login: 'Submit your message',
          'sign-up': 'Submit your message',
        },
        subHeadingText: {
          login: 'Please log in to your account',
          'sign-up': 'Please sign up',
        },
      })
    );
  };
  return (
    <div className={theme.LOCardContentWithLODirectLeadSubmission}>
      {loanOfficerMessageStatus === STATUSES.INIT ||
      loanOfficerMessageStatus === STATUSES.LOADING ? (
        <>
          <LOContactInfo
            dataHcName="your-team-lo-data"
            theme={theme}
            shouldUseDotSeparator
            shouldHidePhoneCallButtonOnMobile
            shouldUseNewLayout
          />
          {isMessageToLoDirectSubmitted ? (
            <div className={theme.SubmittedMessage}>
              <GreenCheckMarkIconCobranded />
              <h2>Thank you! I'll be in touch</h2>
            </div>
          ) : (
            <div className={theme.FormWrapper}>
              {loanOfficerInfo?.customCtaUrl &&
                loanOfficerInfo?.customCtaUrl && (
                  <>
                    <h2>Understand your financing options</h2>
                    <ExternalLink
                      className={theme.LOCustomLink}
                      style={{ color: linkColor }}
                      href={loanOfficerInfo.customCtaUrl}
                      dataEventName="lodirect_yourteam_url"
                      dataParentEventName="click_your_team"
                      dataHcName="lo-direct-custom-link"
                      showIcon
                    >
                      {loanOfficerInfo.customCtaButtonText}
                    </ExternalLink>
                  </>
                )}
              {isLODirectEnabled && (
                <>
                  <TextArea
                    data-hc-name="lo-direct-lead-submission-message-field"
                    label="Message*"
                    type="text"
                    theme={theme}
                    onFocus={() => {
                      dispatch(
                        reportEvent(
                          'lodirect_yourteam_message_box',
                          'click_your_team'
                        )
                      );
                      /* Prompt for authentication before user starts to type message to avoid user losing
                       * message content after successful SSO authentication */
                      if (!isLoggedIn) {
                        dispatch(
                          authModalShow({
                            startingPage: 'login',
                            headingText: {
                              login: 'Submit your message',
                              'sign-up': 'Submit your message',
                            },
                            subHeadingText: {
                              login: 'Please log in to your account',
                              'sign-up': 'Please sign up',
                            },
                            /* Reopen Your Team modal after authentication (potentially SSO authentication)*/
                            afterAuthAction: setYourTeamModalType({
                              modalType: 'lo',
                            }),
                          })
                        );
                      }
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                    error={
                      message.length > LO_DIRECT_LEAD_MESSAGE_MAX_LENGTH
                        ? `Please limit your message to ${LO_DIRECT_LEAD_MESSAGE_MAX_LENGTH} characters`
                        : undefined
                    }
                    tabIndex={0}
                    rows={4}
                    value={message}
                  />
                  <PillButton
                    theme={theme}
                    className={theme.BottomButton}
                    dataHcName="lo-direct-lead-submission-submit-button"
                    data-hc-event-name="lodirect_yourteam_message_submit"
                    data-parent-event-name="click_your_team"
                    onKeyDown={handleSubmit}
                    ariaLabel={'Open another page'}
                    onClick={handleSubmit}
                    disabled={!isValid()}
                  >
                    Submit
                  </PillButton>
                </>
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default function LOCard() {
  const isCobrandWellsFargo = useSelector(getIsWellsFargoCobrand);
  const loanOfficerIsLODirectUser = useSelector(getLoanOfficerIsLODirectUser);
  const loData = useLoInfoProps().loData;
  return (
    <>
      {loData ? (
        isCobrandWellsFargo ? (
          <LOCardContentWithLODataWellsFargo />
        ) : loanOfficerIsLODirectUser ? (
          <LOCardContentWithLODirectLeadSubmission />
        ) : (
          <LOCardContentWithLOData />
        )
      ) : isCobrandWellsFargo ? (
        <LOCardContentWithNoLODataWellsFargo />
      ) : (
        <LOCardContentWithNoLOData />
      )}
    </>
  );
}
