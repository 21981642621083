import React from 'react';
import theme from '@client/css-modules/HoverTooltip.css';

const SearchToolTip = () => {
  return (
    <>
      <div
        className={theme.SearchTooltipPortalContentWrapper}
        style={{
          top: '150px',
          left: '412px',
        }}
      >
        <div className={theme.SearchTooltipArrow}>{'▼'}</div>
        <div className={theme.TooltipContent} style={{ marginLeft: '0px' }}>
          Search
        </div>
      </div>
    </>
  );
};

export default SearchToolTip;
