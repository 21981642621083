import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { STATUSES } from '@client/store/constants';
import { isErrorObj } from '@client/store/types/auth';

export function getConfirmUserState(state: ReduxState) {
  return state.confirmUser;
}

export const getConfirmUserToken = createSelector(
  getConfirmUserState,
  (confirmUserState) => confirmUserState.token
);

export const getIsConfirmUserTokenExpired = createSelector(
  getConfirmUserState,
  (confirmUserState) => confirmUserState.confirmUserStatus === STATUSES.EXPIRED
);

export const getConfirmUserError = createSelector(
  getConfirmUserState,
  (confirmUserState) =>
    isErrorObj(confirmUserState.confirmUserError)
      ? confirmUserState.confirmUserError.messageRaw
      : confirmUserState.confirmUserError
);

export const getConfirmUserInProgress = createSelector(
  getConfirmUserState,
  (confirmUserState) => confirmUserState.confirmUserStatus === STATUSES.UPDATING
);

export const getConfirmUserIsShowingResendSuccessModal = createSelector(
  getConfirmUserState,
  (confirmUserState) => confirmUserState.isShowingResendSuccessModal
);

export const getConfirmUserPageHeading = createSelector(
  getConfirmUserState,
  (confirmUserState) => {
    const confirmUserStatus = confirmUserState.confirmUserStatus;
    if (
      confirmUserStatus === STATUSES.ERROR ||
      confirmUserStatus === STATUSES.EXPIRED
    ) {
      return "We're sorry, something went wrong";
    } else if (confirmUserStatus === STATUSES.SUCCESS) {
      return 'Account confirmed';
    } else {
      return 'Account confirmation';
    }
  }
);
