import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { MapMouseEvent } from 'mapbox-gl';
import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import { motion } from 'framer-motion';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import {
  BoundsArray,
  MultiUnitMapMarker,
  PropertyMapMarker,
} from '@client/store/types/maps';
import Maps from '@client/components/Maps';
import defaultTheme from '@client/css-modules/HOHomeSalesMapView.css';
import { LayerMetric } from '@client/store/map-constants';
import {
  computeBoundsArrayFromLatLngPoints,
  getMapboxGLBoundsForBounds,
  getMultiUnitClusterKey,
  padBoundsByPercentage,
} from '@client/utils/maps.utils';
import MultiUnitSelectModal from '@client/components/MultiUnitSelectModal';
import HeatMapsIcon from '@client/inline-svgs/heat-maps-icon';
import FlatButton from '@client/components/generic/FlatButton';
import HOHomeSalesPropertyCard from '@client/components/HOHomeSalesPropertyCard';
import { useHomeSalesMapData } from '@client/hooks/home-sales-data.hooks';
import { useHomeSalesMapLayerState } from '@client/hooks/home-sales-modal.hooks';
import HOHomeSalesMarkerPopUp from '@client/components/HOHomeSalesMarkerPopUp';
import { View } from '@client/routes/constants';
import HOListingStatusSelector from '@client/components/HOListingStatusSelector';
import { getHomeownerPropertyLocation } from '@client/store/selectors/homeowner.selectors';
import { lngLatToLatLng } from '@hc/hcmaps-mapboxgl/lib/utils';
import HOHomeSalesNullState from '@client/components/HOHomeSalesNullState';

type PropertyKey = null | string;
type ListingStatus = 'home-sold' | 'active-listing';
type Props = {
  isActiveTab?: boolean;
  theme: Theme;
  selectedListingStatus: ListingStatus;
  onListingStatusChange: (status: ListingStatus) => void;
};

const HOHomeSalesMapView: React.FC<Props> = ({
  isActiveTab,
  theme,
  selectedListingStatus,
  onListingStatusChange,
}) => {
  const dispatch = useDispatch();
  let mapApi = useRef<null | Api>(null);

  const [isMapLayerActive, setIsMapLayerActive] = useState(false);
  const [activePropertySlug, setActivePropertySlug] =
    useState<PropertyKey>(null);
  const [activeMultiUnitKey, setActiveMultiUnitKey] =
    useState<PropertyKey>(null);
  const [isShowingMarkers, setIsShowingMarkers] = useState(true);

  const isShowingPropertyModal = activePropertySlug || activeMultiUnitKey;
  const {
    bestMatchedCompMarkerFeature,
    /* Required for heatmap */
    mapGeoRequestAccessToken,
    multiUnitMarkersByLatLng,
    shouldShowBigMapLayout,
    markerFeatures,
    isFetchComplete,
  } = useHomeSalesMapData(selectedListingStatus === 'active-listing');
  const {
    handleGetMapLayerLegendBreaks,
    handleToggleMapBottomLayerGroupsList,
    isShowingMapBottomLayerGroupsList,
  } = useHomeSalesMapLayerState();
  const homeLocation = useSelector(getHomeownerPropertyLocation);

  const autoActiveMarkerFeature = shouldShowBigMapLayout
    ? bestMatchedCompMarkerFeature || markerFeatures[0]
    : null;
  const setMapLocation = autoActiveMarkerFeature
    ? [
        autoActiveMarkerFeature.geometry.coordinates[1],
        autoActiveMarkerFeature.geometry.coordinates[0],
      ]
    : homeLocation
    ? [homeLocation.latitude, homeLocation.longitude]
    : null;
  const shouldShowNullState =
    isFetchComplete && (!markerFeatures || !markerFeatures.length);

  function getBoundsContainingAllMarkerFeatures(
    boundsPaddingPercentage: number = 0.5
  ) {
    const markerFeaturePositions = markerFeatures.map((feature) =>
      lngLatToLatLng(feature.geometry.coordinates)
    );
    return computeBoundsArrayFromLatLngPoints(
      markerFeaturePositions,
      boundsPaddingPercentage
    );
  }

  function handleMapBackgroundLoaded(api: Api) {
    /* NEED reference to map api upon handleOpenMapBottomGroupsControl */
    mapApi.current = api;
    const map = api.getMap();

    if (map) {
      /* If we have an auto-active marker feature we need to set the max map bounds to contain all
       * of the markers (many of which won't be currently visible). If no active marker feature, the
       * `fitMarkersInMap...` method takes care of this by setting the max map bounds to the current
       * bounds after all markers are fit in the map (on `zoomend`) */
      if (autoActiveMarkerFeature) {
        setMapMaxBounds(getBoundsContainingAllMarkerFeatures(1.0));
      } else {
        fitMarkersInMapViewportAndSetMaxBounds();
      }
    }
  }

  function handleToggleMarkers(_, shouldShow: boolean) {
    setIsShowingMarkers(shouldShow);
  }

  function closePropertyModals() {
    setActivePropertySlug(null);
    setActiveMultiUnitKey(null);
  }

  function openPropertyCard(slug: string) {
    closePropertyModals();
    setActivePropertySlug(slug);
  }

  function openMultiUnitModal(multiUnitClusterKey: string) {
    closePropertyModals();
    setActiveMultiUnitKey(multiUnitClusterKey);
  }

  /* on bigger map layout, clicking on multi-unit property goes straight to pdp */
  function goToMultiUnitProperty(featureProps: PropertyMapMarker) {
    closePropertyModals();
    dispatch(
      routeChange({
        view: View.PROPERTY_DETAILS,
        params: { slug: featureProps.addressSlug },
      })
    );
  }

  /* on smaller map layout, clicking on multi-unit property opens up property card */
  function openMultiUnitPropertyCard(featureProps: PropertyMapMarker) {
    openPropertyCard(featureProps.addressSlug);
  }

  function handleMapClick() {
    handleCloseMapBottomGroupsList();
    closePropertyModals();
  }

  function handleMoveEnd(
    api: Api,
    _,
    { activeLayerMetric }: { activeLayerMetric: LayerMetric | null }
  ) {
    const map = api.getMap();
    if (map && activeLayerMetric) {
      handleGetMapLayerLegendBreaks(
        map.getBounds(),
        map.getZoom(),
        activeLayerMetric
      );
    }
  }

  function handleMarkerFeatureClick(
    e: MapMouseEvent,
    api: Api,
    featureProps: PropertyMapMarker
  ) {
    closePropertyModals();
    setActivePropertySlug(featureProps.addressSlug);
  }

  function handleMultiUnitMarkerClick(
    e: MapMouseEvent,
    api: Api,
    featureProps: MultiUnitMapMarker
  ) {
    const { lat, lng } = featureProps;
    closePropertyModals();
    openMultiUnitModal(
      getMultiUnitClusterKey({ latitude: lat, longitude: lng })
    );
  }

  function handleActiveLayerChange(metric: LayerMetric | null) {
    setIsMapLayerActive(!!metric);
  }

  function handleOpenMapBottomGroupsControl() {
    handleToggleMapBottomLayerGroupsList(true);

    const map = mapApi.current?.getMap();
    if (map) {
      handleGetMapLayerLegendBreaks(map.getBounds(), map.getZoom());
    }
  }

  function handleCloseMapBottomGroupsList() {
    if (isShowingMapBottomLayerGroupsList) {
      handleToggleMapBottomLayerGroupsList(false);
    }
  }

  function setMapMaxBounds(bounds: BoundsArray) {
    if (mapApi.current) {
      const map = mapApi.current.getMap();
      if (map) {
        const paddedBounds = padBoundsByPercentage(
          {
            northEast: {
              lat: bounds[0][0],
              lng: bounds[0][1],
            },
            southWest: {
              lat: bounds[1][0],
              lng: bounds[1][1],
            },
          },
          0.5
        );
        const mapboxBounds = getMapboxGLBoundsForBounds([
          [paddedBounds.southWest.lat, paddedBounds.southWest.lng],
          [paddedBounds.northEast.lat, paddedBounds.northEast.lng],
        ]);
        map.setMaxBounds(mapboxBounds);
      }
    }
  }

  /* Set the max bounds of the map to be a bit larger than the current bounds */
  const setMapMaxBoundsOnZoomEnd = useCallback(() => {
    if (mapApi.current) {
      const map = mapApi.current.getMap();
      if (map) {
        const bounds = map.getBounds();
        setMapMaxBounds([
          [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
          [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
        ]);
      }
    }
  }, []);

  /* Unset max bounds, allowing map to move to fit new marker set */
  function unsetMapMaxBounds() {
    if (mapApi.current) {
      const map = mapApi.current.getMap();
      if (map) {
        map.setMaxBounds(undefined);
      }
    }
  }

  /* Fit map markers in the map viewport (with padding) */
  function fitMarkersInMapViewportAndSetMaxBounds() {
    if (mapApi.current) {
      const api = mapApi.current;
      const map = api.getMap();

      if (map) {
        unsetMapMaxBounds();
        map.off('zoomend', setMapMaxBoundsOnZoomEnd);
        map.once('zoomend', setMapMaxBoundsOnZoomEnd);

        /* Set bounds, which moves the map */
        const boundsToFit = getBoundsContainingAllMarkerFeatures();
        if (map.isMoving()) {
          map.once('moveend', () => {
            api.setMapPositionToBounds(boundsToFit);
          });
        } else {
          api.setMapPositionToBounds(boundsToFit);
        }
      }
    }
  }

  useEffect(() => {
    /* Resize the map canvas when the map tab becomes active.  This is needed for the case when browser
     * resizing occurs when the map tab is inactive.  In this case the resize breaks the map canvas size
     * since the tab div (map parent) has the `hidden` attribute */
    if (mapApi.current && isActiveTab) {
      const map = mapApi.current.getMap();
      if (map) {
        map.resize();
      }
    }
  }, [isActiveTab]);

  /* Fit map markers in the map viewport (with padding) when changing between Active & Sold listings */
  useEffect(() => {
    if (markerFeatures.length > 0) {
      fitMarkersInMapViewportAndSetMaxBounds();
    }
  }, [selectedListingStatus]);

  /* display null state when no homes returned to avoid a memory leak by attempting to render the map without the proper requirements */
  if (shouldShowNullState) {
    return (
      <section
        data-hc-name={'main-section'}
        className={classNames(theme.HOHomeSalesMapView, {
          [theme.MapViewNullState]: shouldShowNullState,
        })}
      >
        <HOListingStatusSelector
          theme={theme}
          shouldShowCourtesyText={isActiveTab}
          selectedListingStatus={selectedListingStatus}
          onListingStatusChange={onListingStatusChange}
        />
        <HOHomeSalesNullState />
      </section>
    );
  }

  return (
    <section data-hc-name={'main-section'} className={theme.HOHomeSalesMapView}>
      <HOListingStatusSelector
        theme={theme}
        shouldShowCourtesyText={isActiveTab}
        selectedListingStatus={selectedListingStatus}
        onListingStatusChange={onListingStatusChange}
      />
      <div className={theme.MainContent}>
        <div className={theme.MapSection} data-hc-name={'map-section'}>
          {!!setMapLocation && isFetchComplete && (
            <Maps
              markerFeaturesChangedTrigger={markerFeatures}
              showPropertiesDefaultOn
              handleToggleMarkers={handleToggleMarkers}
              handleMapBackgroundLoaded={handleMapBackgroundLoaded}
              fitMarkersOnMarkerChange={false}
              handleActiveLayerChange={handleActiveLayerChange}
              handleMapClick={handleMapClick}
              handleMarkerFeatureClick={handleMarkerFeatureClick}
              handleMultiUnitMarkerClick={handleMultiUnitMarkerClick}
              handleMoveEnd={handleMoveEnd}
              multiUnitMarkersByLatLng={multiUnitMarkersByLatLng}
              markerFeatures={isShowingMarkers ? markerFeatures : null}
              autoOpenPopupForMarkerFeature={autoActiveMarkerFeature}
              fitBoundsOptions={{ duration: 1000 }}
              geoRequestAccessToken={mapGeoRequestAccessToken}
              useFloatingLayerGroupsControl={shouldShowBigMapLayout}
              showZoomControls={shouldShowBigMapLayout}
              isShowingBottomLayerGroupsControl={
                isShowingMapBottomLayerGroupsList && !isShowingPropertyModal
              }
              handleCloseBottomGroupsControl={handleCloseMapBottomGroupsList}
              allowShowingBottomControl={
                !isShowingPropertyModal || shouldShowBigMapLayout
              }
              allowScrollZoom={!shouldShowBigMapLayout}
              setMapLocation={setMapLocation}
              /* This is handled above since we want to add padding to the bounds and since the
               * functionality enabled by this prop interferes with the auto-opening popup when
               * in 2 or 3 column layouts */
              shouldFitMarkersInMap={false}
              zoomWhenPositioningToPoint={15}
              hasMapLayersControl
              isShowingMarkers={isShowingMarkers}
              MarkerPopup={
                shouldShowBigMapLayout ? (
                  <HOHomeSalesMarkerPopUp
                    isActiveListing={selectedListingStatus === 'active-listing'}
                    slug={activePropertySlug}
                  />
                ) : null
              }
              theme={theme}
            />
          )}
          <MultiUnitSelectModal
            watchListItems={[]}
            handleReportWatchClick={() => {}}
            handleReportUnwatchClick={() => {}}
            handleReportUnwatchConfirmClick={() => {}}
            isActive={!!activeMultiUnitKey}
            properties={
              activeMultiUnitKey
                ? multiUnitMarkersByLatLng[activeMultiUnitKey]
                : null
            }
            onSelectProperty={
              shouldShowBigMapLayout
                ? goToMultiUnitProperty
                : openMultiUnitPropertyCard
            }
            handleCloseModal={closePropertyModals}
          />
        </div>
      </div>
      {!isShowingPropertyModal && !shouldShowBigMapLayout && (
        <div
          className={classNames(theme.MobileButtonsPositioner, {
            [theme.positionedHigher]: isMapLayerActive,
          })}
        >
          <motion.div
            initial={{ x: 51, y: 0 }}
            animate={{
              x: 0,
              y: 0,
              transition: {
                delay: 0.3,
                duration: 0.3,
                ease: 'backInOut',
              },
            }}
            className={theme.MobileButtons}
          >
            <FlatButton
              onClick={handleOpenMapBottomGroupsControl}
              theme={theme}
              className={theme.MobileButton}
              label="Heatmaps"
              aria-label="Heatmaps"
              icon={<HeatMapsIcon className={theme.MobileButtonIcon} />}
            />
          </motion.div>
        </div>
      )}
      {!shouldShowBigMapLayout && (
        <HOHomeSalesPropertyCard
          isActiveListing={selectedListingStatus === 'active-listing'}
          slug={activePropertySlug}
        />
      )}
    </section>
  );
};

const ThemedHOHomeSalesMapView = themr(
  'HOHomeSalesMapView',
  defaultTheme
)(HOHomeSalesMapView);
export default ThemedHOHomeSalesMapView;
