import { useState, SetStateAction, Dispatch } from 'react';
import IncompleteUserInfoForm from '@client/components/IncompleteUserInfoForm';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/RequestATourUserForm.css';
import PillButton from '@client/components/generic/PillButton';
import { TourViewOptions } from '@client/store/types/request-a-tour';

type Props = {
  theme: Theme;
  setCurrentView: Dispatch<SetStateAction<TourViewOptions>>;
  handleClick: (userData) => void;
};

export const RequestATourUserForm = ({
  theme,
  setCurrentView,
  handleClick,
}: Props) => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  return (
    <div className={theme.RequestATourUserForm}>
      <IncompleteUserInfoForm
        theme={theme}
        eventNamePrefix="request-tour"
        parentEventName="request-tour"
        onChange={(userFormData) => setUserData(userFormData)}
      />
      <PillButton
        className={theme.SubmitButton}
        theme={theme}
        ariaLabel="Submit"
        onClick={() => {
          handleClick(userData);
          setCurrentView('agentSelection');
        }}
      >
        Done
      </PillButton>
    </div>
  );
};

const ThemedRequestATourUserForm = themr(
  'RequestATourUserForm',
  defaultTheme
)(RequestATourUserForm);
export default ThemedRequestATourUserForm;
