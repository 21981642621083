import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Alert } from '@client/utils/reach-alert';
import { useSelector, useDispatch } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { getToastState } from '@client/store/selectors/toast.selectors';
import { toastHide } from '@client/store/actions/toast.actions';
import defaultTheme from '@client/css-modules/Toast.css';

type ToastProps = {
  theme: Theme;
};

/**
 * NOTE: Please follow best practices for accessibility outlined in
 * https://medium.com/@sheribyrnehaber/designing-toast-messages-for-accessibility-fb610ac364be
 */
const Toast: React.FC<ToastProps> = ({ theme }) => {
  const dispatch = useDispatch();
  const toastState = useSelector(getToastState);
  const { autoHideDuration, open, message, messageId } = toastState;
  let timer: number;

  const handleTimeout = () => {
    timer = window.setTimeout(() => {
      dispatch(toastHide());
    }, autoHideDuration);
  };

  if (open) {
    handleTimeout();
  }

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return open ? (
    <div
      data-hc-name="toast"
      className={classNames([theme.toast], {
        [theme.active]: open,
      })}
      key={messageId || 0}
    >
      <span className={theme.label}>
        <Alert tabIndex={0} type="assertive">
          {message}
        </Alert>
      </span>
    </div>
  ) : null;
};

const ThemedToast = themr('Toast', defaultTheme)(Toast);

export default ThemedToast;
